import { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, getGridStringOperators } from '@mui/x-data-grid';  
import Box from "@mui/material/Box"; 
import {format} from 'date-fns'
import fetch from "node-fetch";
import * as endpoints from '../../../endpoints.js';

const renderDate = (date) => {
 if (!date) {
   return '';
 } 
 return format(new Date(date), 'Pp');
}

const filterOperators = getGridStringOperators().filter(({ value }) => 
  ['equals', 'contains'].includes(value),
);  
 
function CustomToolbar() {
  return (
    <GridToolbarContainer> 
      <GridToolbarFilterButton /> 
      <Box sx={{ flexGrow: 1 }} /> 
    </GridToolbarContainer>
  );
} 

const columns = [   
    {
        field: "group_id",
        headerName: "Group ID",
        minWidth: 50,
        flex: 1,
        sortable: true,
        filterOperators,
        renderCell: (params) => (
          <div  className='id-typography'> {params.value} </div>
        )
    },
    {
        field: "group_name",
        headerName: "Group Name",
        minWidth: 50,
        flex: 1,
        sortable: true,
        filterOperators,
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "platform",
        headerName: "Platform",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        filterable: false,
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "last_active",
        headerName: "Last Active",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        type: "date", 
        renderCell: (params) => (
          <div> {renderDate(params.value)} </div>
        )
    },
    {
        field: "participant_count",
        headerName: "# Participants",
        minWidth: 50,
        flex: 1, 
        sortable: true, 
        filterable: false,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "message_count",
        headerName: "# Messages",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        filterable: false,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "keyword_hit_count",
        headerName: "# Keyword Hits",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        filterable: false,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "hashtag_count",
        headerName: "# Hashtags",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        filterable: false,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "url_count",
        headerName: "# URLs",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        filterable: false,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    }, 
];
 
export default function OverviewOfGroups(props) {   
  const [rows, setRows] = useState([]);
  const handleClick = (params, event) => { 
    props.callback({group_id: params.row.group_id, group_name: params.row.group_name});
    event.preventDefault(); 
  } 
  
  useEffect(() => {  
    let filters = props.filters;
    let selected = props.selected;
    let selected_commsGuid = [];
    let selected_commsName = [];
    selected.forEach((group) => { 
      selected_commsGuid.push({
        match_phrase: {
          commsGuid: group.commsGuid
        }
      });
      selected_commsName.push({
        match_phrase: {
          commsName: group.commsName
        }
      }) 
    })
    let temp = [];
    
    if (selected.length > 0) {
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsGuid  
        }
      })
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsName
        }
      })
    }
    
    const content_filters = filters.concat(temp);   
    const getGroupStatistics = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
            es_query: {
              _source: [],
              index: "messages.*",
              aggs: {
                2: {
                  terms: {
                    field: "commsGuid",
                    order: {
                      1: "desc"
                    }
                  },
                  aggs: {
                    1: {
                      cardinality: {
                        field: "unitId"
                      }
                    },
                    3: {
                      terms: {
                        field: "commsName",
                        order: {
                          1: "desc"
                        },
                        missing: "__missing__",
                        size: 10
                      },
                      aggs: {
                        1: {
                          cardinality: {
                            field: "unitId"
                          }
                        },
                        8: {
                          terms: {
                            field: "application",
                            order: {
                              1: "desc"
                            },
                            size: 20
                          },
                          aggs: {
                            1: {
                              cardinality: {
                                field: "unitId"
                              }
                            },
                            2: {
                              cardinality: {
                                field: "senderSelectorAccountId"
                              }
                            },
                            3: {
                              max: {
                                field: "numberOfCommsMembers"
                              }
                            },
                            4: {
                              sum: {
                                field: "numberOfHashtags"
                              }
                            },
                            5: {
                              sum: {
                                field: "numberOfUrlLinks"
                              }
                            },
                            6: {
                              max: {
                                field: "messageDatetime"
                              }
                            },
                            7: {
                              max: {
                                field: "numberOfKeywordsHit"
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              size: 0,
              stored_fields: [
                "*"
              ],
              script_fields: {},
              query: {
                bool: {
                  must: [],
                  filter: content_filters,
                  should: [],
                  must_not: []
                }
              }
            }
          })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });
      let temp_arr = []; 
      res.json().then(body => {
        body.aggregations["2"].buckets.forEach((x, index) => { 
          let data = x["3"].buckets[0]["8"].buckets[0];
          let uid = x.key;
          let name = x["3"].buckets[0].key;
          let application = x["3"].buckets[0]["8"].buckets[0].key;  
          let temp = {};
          temp.id = index;
          temp.message_count = data["1"].value;
          temp.sender_count = data["2"].value;
          temp.participant_count = data["3"].value ? data["3"].value : "-" ;
          temp.hashtag_count = data["4"].value;
          temp.url_count = data["5"].value;
          temp.last_active = new Date(data["6"].value);
          temp.keyword_hit_count = data["7"].value;
          temp.group_id = uid;
          temp.group_name = name;
          temp.platform = application;  
          temp_arr.push(temp);
        })
        setRows(temp_arr);  
      });
    }
    
    if (selected.length > 0) { getGroupStatistics() }; 
  }, [props.filters])
   
  return (
    <> 
      <div style={{ height: '100%', width: "300"}}> 
          <DataGrid    
            autoHeight 
            disableColumnResize 
            disableColumnMenu
            slots={{ toolbar: CustomToolbar }}
            rows={rows} 
            columns={columns}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                 outline: "none !important",
              }, 
              ".MuiButton-root": {
                color: "#605958"
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "rgba(218,218,218,0.2)",
                cursor: "pointer"
              }
            }}
            onRowClick={handleClick}
          />   
      </div>  
    </>
  );
}  