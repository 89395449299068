import { useState, useEffect } from 'react';  
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Box from '@mui/material/Box'; 
import Divider from '@mui/material/Divider'; 
import Grid from '@mui/material/Grid'; 
import IconButton from "@mui/material/IconButton"; 
import Popover from '@mui/material/Popover'; 
import TextField from '@mui/material/TextField';  
import BookmarkPopover from './bookmarkPopover.js';
import fetch from "node-fetch";
import { v4 as uuid } from "uuid";
import * as endpoints from '../../../endpoints.js';

const SearchModal = (props) => {
  const [search, setSearch] = useState(''); 
  const [bookmarks, setBookmarks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [key, setKey] = useState()

  useEffect(() => {    
    const getBookmarks = async () => {
        const res = await fetch(endpoints.GET_BOOKMARK, { 
            headers: {
                authorizationToken: endpoints.AUTHORIZATION_TOKEN
            },
            method: "POST",
            body: JSON.stringify({
            	action_type: "get_records_by_creator_and_filtersortby_createddatetime", 
            	bookmark_type: "individual", 
            	sort_order: "desc", 
            	creator: props.user.username, 
            	result_size_limit: "10", 
            	range_operator: ">", 
            	created_datetime: "0"
            }) 
        }).catch((err) => {
            console.log("getBookmarks Error:", err)
        }); 
        return new Promise((resolve, reject) => {
            res.json().then(body => {resolve(body)})
        }).catch((err) => console.log("Error:", err));     
    }    
    
    getBookmarks().then((res) => { 
      let bookmarks = res.map((obj) => {  
        let bookmark_filters = obj.bookmark_filters.map((filter) => {
          let type = Object.keys(filter)[0];
          let label = filter[type];
          return { type: type, label: label }  
        })  
        var temp = {};
        temp.id = obj.id;
        temp.bookmark_name = obj.bookmark_name;
        temp.bookmark_category = obj.bookmark_category;
        temp.created_datetime = obj.created_datetime;
        temp.bookmark_type = obj.bookmark_type;  
        temp.bookmark_filters = bookmark_filters;
        return temp;
      })
      const temp_groups = Object.groupBy(bookmarks, ({bookmark_category}) => bookmark_category)
      let temp_bookmarks = []; 
      Object.values(temp_groups).forEach((inner_arr) => {
        let category_set = {};
        let category = inner_arr[0].bookmark_category; 
        category_set.category = category;
        let temp_sets = [];
        inner_arr.forEach((set) => { 
          temp_sets.push({name: set.bookmark_name, filters: set.bookmark_filters, id: set.id }) 
        })
        category_set.sets = temp_sets;
        temp_bookmarks.push(category_set);
      })
      setBookmarks(temp_bookmarks); 
    }) 
}, [key]) 
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); 
    setKey(uuid().slice(0, 8));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
    
  useEffect(() => {
    // const delayDebounce = setTimeout(() => {
      props.callback(search); 
    // }, 2000) 
    // return () => clearTimeout(delayDebounce)
  }, [search])
   
  /** 'Enter' to generate search **/
  const handleSubmit = (event) => { 
    props.callback(event.target.value);
    event.preventDefault();
    // setSearch('');
  }
   
  return ( 
      <>
        <Box
            sx={{  
            height: '3.8vh', 
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            bgcolor: 'background.paper', 
            }}
        >    
          <Box>
            <IconButton sx={{ padding: '15px'}} onClick={handleClick}>
              <BookmarkIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <BookmarkPopover loadBookmarkCallback={props.loadBookmarkCallback} closeCallback={handleClose} tags={props.tags} user={props.user} bookmarks={bookmarks}/>
            </Popover>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem /> 
            <Grid sx={{  marginLeft: '1rem', width: '35vw', display: "flex", alignItems: 'center' }}>
              <TextField 
                fullWidth 
                placeholder="Search here" 
                InputProps={{ disableUnderline: true, }} 
                variant="standard" 
                value={search} 
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                }}}   
              />  
            </Grid>   
        </Box>    
      </> 
    );
}; 
export default SearchModal;  