import { useState } from 'react';  
import AbcIcon from '@mui/icons-material/Abc'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'; 
import CircularProgress from '@mui/material/CircularProgress'; 
import FaceIcon from '@mui/icons-material/Face'; 
import FolderIcon from '@mui/icons-material/Folder';
import FormControl from '@mui/material/FormControl';
import GroupsIcon from '@mui/icons-material/Groups';
import InputAdornment from '@mui/material/InputAdornment';  
import LinkIcon from '@mui/icons-material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MessageIcon from '@mui/icons-material/Message';
import Select from '@mui/material/Select';  
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TagIcon from '@mui/icons-material/Tag';
import TextField from '@mui/material/TextField'; 
import { v4 as uuid } from "uuid";
import { get } from 'aws-amplify/api'; 

const TYPE_LIST = [
    {label: "[Configured] Keyword Hits Category", type: "extractedKeywordCategories_master", icon: <FolderIcon/>},
    {label: "[Configured] Group Tagging", type: "extractedGroupTaggings", icon: <FolderIcon/>},
    {label: "[Configured] Faceprint Category", type: "extractedFaceHitWGroup", icon: <FolderIcon/>},  
    {label: "Application", type: "application", icon: <SmartphoneIcon/>}, 
    {label: "Faceprint", type: "extractedFaceHitWPerson", icon: <FaceIcon/>}, 
    {label: "Keyword Hit", type: "extractedKeywords_master", icon: <AbcIcon/>},
    {label: "Group UID", type: "commsGuid", icon: <GroupsIcon/>},
    {label: "Group Name", type: "commsName", icon: <GroupsIcon/>},
    {label: "Group URL", type: "commsUrl", icon: <GroupsIcon/>},
    {label: "Sender Display Name", type: "senderDisplayName", icon: <AccountCircleIcon/>},
    {label: "Sender UID", type: "senderSelectorAccountId", icon: <AccountCircleIcon/>},
    {label: "Sender Username", type: "senderSelectorUsername", icon: <AccountCircleIcon/>},
    {label: "Sender Phone Number", type: "senderSelectorPhoneNumber", icon: <AccountCircleIcon/>},
    {label: "Hashtag", type: "extractedHashtags", icon: <TagIcon/>}, 
    {label: "Extracted URL", type: "extractedUrls", icon: <LinkIcon/>},
    {label: "Extracted URL Domain", type: "extractedUrlDomains", icon: <LinkIcon/>},
    {label: "Hit Message URL", type: "messageUrl", icon: <MessageIcon/>},  
] 

const APP_LIST = ["Telegram", "WhatsApp", "WeChat", "Facebook", "Instagram", "Tiktok", "Twitter", "YouTube", "XiaoHongShu", "Weibo"]

const FilterModal = (props) => {
    const [type, setType] = useState(''); 
    const [categoryList, setCategoryList] = useState([]); 
    const [label, setLabel] = useState(''); // For textfield tags
    const [generated, setGenerated] = useState(false);
    const [loading, setLoading] = useState(false); 

    const handleChangeType = (event) => {
        let type = event.target.value; 
        setType(type); 
        setLabel('');
        setGenerated(false);
        // Generate list of categories based on the type chosen here:
        if (type === "extractedKeywordCategories_master") {
            setLoading(true);
            const getCategories = async () => {
                try {
                    const getOperation = get({
                        apiName: 'gcckeywordmgtapi',
                        path: '/gcckeywordmgt'
                    });
                    const { body } = await getOperation.response; 
                    const json = await body.json();
                    const temp = new Set(); 
                    json.forEach(x => x.delete ? console.log('') : temp.add(x.category)); 
                    setCategoryList(Array.from(temp)); 
                    setLoading(false); 
                } catch (error) {
                    console.log('GET call failed: ', error);
                }
            }
            getCategories();
            setGenerated(true);
        } else if (type === "extractedGroupTaggings") { 
            const getCategories = async () => {
                setLoading(true);
                try {
                    const getOperation = get({
                        apiName: 'gccgrouptagapi',
                        path: '/gccgrouptag'
                    });
                    const { body } = await getOperation.response; 
                    const json = await body.json();
                    const temp = new Set(); 
                    json.forEach(x => x.delete ? console.log('') : temp.add(x.tag));
                    setCategoryList(Array.from(temp)); 
                    setLoading(false); 
                } catch (error) {
                    console.log('GET call failed: ', error);
                }
            } 
            getCategories();  
            setGenerated(true);
        } else if (type === "extractedFaceHitWGroup") {
           const getCategories = async () => {
                setLoading(true);
                try {
                    const getOperation = get({
                        apiName: 'gccfaceprintapi',
                        path: '/gccfaceprint'
                    });
                    const { body } = await getOperation.response; 
                    const json = await body.json();
                    var temp = new Set(); 
                    for (let i = 0; i < json.length; i++) {
                        let x = json[i]; 
                        if (!x.delete) {
                            let cat = x.categories;
                            console.log(x.categories)
                            temp = [...new Set([...temp ,...cat])];
                        }
                    }   
                    setCategoryList(temp); 
                    setLoading(false); 
                } catch (error) {
                    console.log('GET call failed: ', error);
                }
            } 
            getCategories(); 
            setGenerated(true); 
        }
    }; 
    
    const handleChangeLabel = (event) => {  
        setLabel(event.target.value); 
    };
    
    /** Callback to searchHeader.js when "Add" button is clicked **/  
    const handleSubmit = () => {   
        if (label.trim().length > 0) {
            props.callback({type: type, label: label, key: uuid().slice(0, 8)});
        } 
    }
    
    return ( 
        <Box sx={{paddingBottom: '10px'}}>
            <FormControl fullWidth> 
                <Select  
                  variant='standard' 
                  value={type} 
                  onChange={handleChangeType}
                  displayEmpty
                  disableUnderline={true} 
                  autoWidth={true}
                  MenuProps={{   
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                    }}
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, margin: '15px 10px 10px 15px', '.MuiSelect-select': { display: 'flex', flexDirection: 'row', alignItems: 'center' }}}
                >
                    <MenuItem disabled value="">
                        <em>Type of Field</em>
                    </MenuItem>
                    {TYPE_LIST.map((item) => (
                      <MenuItem
                        key={item}
                        value={item.type} 
                      >
                          <ListItemIcon fontSize="small">
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText>{item.label}</ListItemText> 
                      </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {loading ? 
            <Box sx={{ display: 'flex',  margin: '15px 10px 10px 15px' }}>
              <CircularProgress size="1rem" color="inherit"/>
            </Box> :  
            type ?
            (generated ? 
                <FormControl fullWidth> 
                    <Select  
                      variant='standard' 
                      value={label} 
                      onChange={handleChangeLabel}
                      displayEmpty
                      disableUnderline={true} 
                      disabled={categoryList.length === 0}
                      MenuProps={{    
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                        }}
                      sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, margin: '0 10px 0 15px' }}
                    >
                        <MenuItem disabled value="">
                            <em>Category</em>
                        </MenuItem>
                        {categoryList.map((item) => (
                          <MenuItem
                            key={item}
                            value={item} 
                          >
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                </FormControl> : 
                (type === "application" ?
                <FormControl fullWidth> 
                    <Select  
                      variant='standard' 
                      value={label} 
                      onChange={(e) => setLabel(e.target.value)}
                      displayEmpty
                      disableUnderline={true}  
                      MenuProps={{    
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                        }}
                      sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, margin: '0 10px 0 15px' }}
                    >
                        <MenuItem disabled value="">
                            <em>Name of Application</em>
                        </MenuItem>
                        {APP_LIST.map((item) => (
                          <MenuItem
                            key={item}
                            value={item} 
                          > 
                             <ListItemText>{item}</ListItemText> 
                          </MenuItem>
                        ))}
                    </Select>
                </FormControl> 
                :
                <TextField 
                    fullWidth  
                    variant="standard" 
                    placeholder='Insert a filter tag'
                    value={label} 
                    sx={{paddingLeft: '1rem', paddingRight: '1rem'}} 
                    onChange={(e) => setLabel(e.target.value)} 
                    InputProps={{ 
                        startAdornment: type === "extractedHashtags" ? <InputAdornment position="start">#</InputAdornment> : <></>, 
                    }}
                 />) 
            ) : 
                <TextField 
                    fullWidth 
                    disabled
                    placeholder="Insert a filter tag" 
                    InputProps={{ disableUnderline: true, }}
                    variant="standard" 
                    value={label} 
                    sx={{paddingLeft: '1rem'}}
                    onChange={(e) => setLabel(e.target.value)} 
                 /> 
            } 
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingRight: '10px'}}>
                <Button 
                    sx={{ marginTop: '1rem', marginLeft: '2rem', "&.Mui-disabled": { color: "#c7c7c7" } }} 
                    className='filter-button' 
                    type="submit"
                    onClick={handleSubmit}   
                    variant="contained"  
                    disabled={!(label && label.trim().length > 0 && type !== '')} 
                >
                    <span sx={{color: 'white'}}>
                        Add
                    </span> 
                </Button>     
            </Box>
        </Box>
    );
}

export default FilterModal;