// import React, { useState, useEffect }from 'react';   
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton'; 
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar'; 
import PendingIcon from '@mui/icons-material/Pending';  
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom"; 
import './faceprintCard.js'; 

const States = Object.freeze({
    SUCCESS: "completed",
    FAILURE: "error",
    PENDING: "pending"
});  
 
const FaceprintCard = ({item}) => {  
    let navigate = useNavigate(); 
    const routeChange = (pk) =>{  
      let path = `/faceprint-mgt/view/${pk}`; 
      navigate(path);
    }
    
    return (  
        <ImageListItem 
            key={item.url} 
            sx={{ 
                '&:hover': {
                  cursor: 'pointer', 
                }, 
            }}
        >   
        <Chip 
            sx={{ 
                marginLeft: "5px", 
                marginTop: "5px", 
                maxWidth: "5rem", 
                position: "absolute", 
                top:"0", 
                left:"0", 
                backgroundColor: item.enable ? "#1976d2" : "#a6a6a6" , 
                color: item.enable ? "#ffffff" : "#e5e5e5"
            }}  
            size="small"  
            label={item.enable ? "Active" : "Inactive"} 
        /> 
        <img
            srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={item.url} 
            style={{ width: "100%", height: "300px", objectFit: "cover"}} 
            alt={item.name}
            loading="lazy" 
            onClick={() => routeChange(`${item.pk}`)}  
        />
        <ImageListItemBar
            title={<div>{item.name}</div>}
            subtitle={<span>Tags: {item.categories}</span>} 
            actionIcon={ 
                <Tooltip 
                    title={ item.status === States.PENDING ? "Processing" : item.status === States.SUCCESS ? "Completed" : "Failed" }
                    slotProps={{
                        popper: {
                            modifiers: [{
                                name: 'offset',
                                options: { offset: [0, -14] },
                            }]
                        },
                    }}
                >
                    <span>
                        <IconButton
                          sx={{ color: 'white' }}
                          aria-label={`Success`} 
                          disabled={true}
                        >
                        { item.status === States.PENDING ? <PendingIcon /> : item.status === States.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon /> }
                        </IconButton> 
                    </span>
                </Tooltip>
            }
        /> 
        </ImageListItem>  
    );
};

export default FaceprintCard; 