import { useState, useEffect } from 'react';
import AbcIcon from '@mui/icons-material/Abc'; 
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert'; 
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';  
import Chip from '@mui/material/Chip'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';  
import Divider from '@mui/material/Divider';
// import EditIcon from '@mui/icons-material/Edit'; // Future enhancement
import FaceIcon from '@mui/icons-material/Face'; 
import FolderIcon from '@mui/icons-material/Folder';
import GroupsIcon from '@mui/icons-material/Groups';
import IconButton from '@mui/material/IconButton'; 
import LinkIcon from '@mui/icons-material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'; 
import ListSubheader from '@mui/material/ListSubheader';
import MessageIcon from '@mui/icons-material/Message';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Snackbar from '@mui/material/Snackbar';
import TagIcon from '@mui/icons-material/Tag';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from "uuid";  
import './bookmarkPopover.css'; 
import fetch from "node-fetch"; 
import * as endpoints from '../../../endpoints.js';

function FilterChip({filter}) {
  return (
    <Chip 
      sx={{ marginLeft: '5px' }} 
      icon= { 
        filter.type === 'extractedGroupTaggings' || filter.type === 'extractedKeywordCategories_master' || filter.type === 'extractedFaceHitWGroup' ? <FolderIcon/> :
        filter.type === 'commsGuid' || filter.type === 'commsName' || filter.type === 'commsUrl' ? <GroupsIcon/> :
        filter.type === 'senderDisplayName' || filter.type === 'senderSelectorAccountId' || filter.type === 'senderSelectorUsername' || filter.type === 'senderSelectorPhoneNumber' ? <AccountCircleIcon/> :
        filter.type === 'extractedKeywords_master' ? <AbcIcon /> :
        filter.type === 'application' ? <SmartphoneIcon/> :
        filter.type === 'extractedFaceHitWPerson' ? <FaceIcon/> :
        filter.type === 'extractedHashtags' ? <TagIcon/> :
        filter.type === 'extractedUrls' || filter.type === 'extractedUrlDomains' ? <LinkIcon/> :
        filter.type === 'messageUrl' || filter.type === 'messageResponseType' ? <MessageIcon/> : <></>
      } 
      label={filter.label}  
    />
  )
}

function BookmarkItem(props) { 
  const handleAddFilters = (item) => { 
    let filters = item.filters;
    filters.map((tag) => {
      tag.key = uuid().slice(0, 8);
      return tag;
    })
    props.closeCallback();
    props.loadBookmarkCallback(filters);
  }
  
  const handleDeleteBookmark = (item) => {
    const deleteBookmark = async () => {
        const res = await fetch(endpoints.SAVE_BOOKMARK, { 
            headers: {
                authorizationToken: endpoints.AUTHORIZATION_TOKEN
            },
            method: "POST",
            body: JSON.stringify({
            	action_type: "delete", 
            	bookmark_type: "individual", 
            	id: item.id  
            }) 
        }).catch((err) => {
            console.log("deleteBookmark Error:", err);
            props.deleteCallback({name: item.name, status: false})
        }); 
        return new Promise((resolve, reject) => {
            res.json().then(body => {
              props.deleteCallback({name: item.name, status: true})
              resolve(body);
            })
        }).catch((err) => {
          console.log("Error:", err)
          props.deleteCallback({name: item.name, status: false}) 
        });     
    }     
    deleteBookmark();
  }
  
  return <li key={`section-${props.unit.category}`}>
          <ul>
          <ListSubheader >
            <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }} >
              {props.unit.category}
            </Typography>
          </ListSubheader>
            {props.unit.sets.map((item) => (  
                <ListItem 
                  key={item}
                  sx={{ paddingTop: '4px', paddingBottom: '4px', overflow: 'hidden', whiteSpace: "nowrap", textOverflow: 'ellipsis' }} 
                  secondaryAction={
                    <div> 
                      <IconButton edge="end" aria-label="comments">
                        <DeleteIcon onClick={() => handleDeleteBookmark(item)}/>
                      </IconButton>
                    </div>
                  }
                >
                  <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <AddIcon onClick={() => handleAddFilters(item)}/>
                      </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{ style: { overflow: 'hidden', whiteSpace: "nowrap", textOverflow: 'ellipsis', fontSize: '14px' } }}
                  />
                  <Box sx={{ width: '75%', overflow: 'hidden', whiteSpace: "nowrap", textOverflow: 'ellipsis' }} elevation={0}> 
                    {item.filters.map((filter) => (
                      <FilterChip filter={filter}/>
                    ))}
                  </Box> 
                </ListItem>    
            ))}
          </ul>
        </li>;
} 
 
export default function BookmarkPopover(props) {
  const [open, setOpen] = useState(false); 
  const [bookmarks, setBookmarks] = useState([]);
  const [filters, setFilters] = useState([]);
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [categories, setCategories] = useState([]); 
  const [activated, setActivated] = useState(false); 
  const [alert, setAlert] = useState({
    open: false, 
    status: true, // success == true, error == false
    delete: false
  });
    
  /** Activate "Add Bookmark" Button **/  
  useEffect(() => { 
    if (filters.length > 0 && name && category) {
      setActivated(true);
    } else {
      setActivated(false);
    }
  }, [filters, name, category]);
  
  useEffect(() => {
    setBookmarks(props.bookmarks);
    let temp = [];
    props.bookmarks.forEach((x) => temp.push(x.category));
    setCategories(temp);
  }, [props.bookmarks])
    
  /** Dialog **/  
  const handleOpen = () => { 
    const filters = props.tags;
    filters.map((filter) => {
      delete filter.key;
      return filter;
    })
    setFilters(filters);
    setOpen(true);
  };
  
  const handleClose = () => {
    setName('');
    setCategory('');
    setOpen(false);
  }; 
   
  const handleAddBookmark = () => { 
    let bookmark_filters = [];
    filters.forEach((x) => {
      let type = x.type;
      let label = x.label;
      let temp = {};
      temp[type] = label;
      bookmark_filters.push(temp);
    })   
    const date = new Date(); 
    const addBookmark = async () => {
      const res = await fetch(endpoints.SAVE_BOOKMARK, { 
        headers: {
            authorizationToken: endpoints.AUTHORIZATION_TOKEN
        },
        method: "POST",
        body: JSON.stringify({ 
  	      action_type: "create",
  	      creator: props.user.username,  
          creator_remarks: "",  
        	team: props.user.team,
  	      bookmark_type: "individual", 
  	      bookmark_category: category, 
        	bookmark_name: name, 
        	bookmark_filters: bookmark_filters,       
          created_datetime: date.getTime().toString(),
          created_datetime_str: date.toLocaleString('sv-SE', { timeZoneName: 'short' }).replace(' ', 'T').replace('UTC', '+08:00')
        }) 
      }).catch((err) => {
          console.log("addBookmark Error:", err);
          setAlert({ open: true, status: false, delete: false });
      }); 
      return new Promise((resolve, reject) => {
          res.json().then(body => {
            setAlert({ open: true, status: true });
            setOpen(false);
            resolve(body);
          })
      }).catch((err) => {
        setAlert({ open: true, status: false, delete: false });
        console.log("Error:", err);
      });     
    }     
    addBookmark();
    // props.closeCallback();
  }
  
  const handleDeleteBookmarkCallback = (value) => {
    setName(value.name);
    setOpen(false);
    setAlert({ open: true, status: value.status, delete: true }); 
  }
  
  /** Alert **/
  const handleCloseAlert = () => {
    setAlert(prev => ({ ...prev, open: false })); 
  } 
   
  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: "100%",
          bgcolor: 'background.paper',
          position: 'relative',
          overflowX: 'hidden',
          maxHeight: 300,
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
      >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ padding: '1rem 0 1rem 1rem', fontWeight: 'bold' }}>My Bookmarks</Typography>
          <Button sx={{ marginRight: '1rem', marginLeft: '8rem' }} className='filter-button' onClick={handleOpen} variant="contained">
            <span sx={{color: 'white'}} >
                Bookmark Current Filter(s)
            </span> 
          </Button>  
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  minWidth: "10vw", 
                },
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Add Current Filter(s) to Bookmark"}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ paddingBottom: "10px" }}>
                <Typography className="bookmark-field-header">
                  Category
                </Typography>
                <Autocomplete 
                  value={category}
                  size="small" 
                  freeSolo 
                  onChange={(event, value) => setCategory(value)} 
                  options={categories}   
                  renderInput={(params) => 
                    <TextField size="small" {...params} onChange={(event) => setCategory(event.target.value)} label="Select/add a category"/>}
                />
              </Box>
              <Box sx={{ paddingBottom: "10px" }}>
                <Typography className="bookmark-field-header">
                  Name
                </Typography>
                <TextField
                  label="Name of bookmark" 
                  size="small"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)} 
                />
              </Box>
              <Box>
                <Typography className="bookmark-field-header">
                  Filters Saved
                </Typography>
                <Box elevation={0} sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  {filters.length > 0 ? 
                    filters.map((filter) => { return (
                      <Box sx={{ paddingBottom: "5px" }}>
                        <FilterChip filter={filter}/>
                      </Box> 
                    )}) 
                    :
                    <Typography sx={{ paddingLeft: "4px" }}><i>No filter detected.</i></Typography> 
                  } 
                </Box> 
              </Box> 
            </DialogContent>
            <DialogActions> 
                <Button className='bookmark-button' variant="contained" onClick={handleAddBookmark} disabled={!activated}>
                  <span className='bookmark-button-text'>
                      Add Bookmark
                  </span>  
                </Button>
                <Button className='bookmark-button' variant="contained" onClick={handleClose}>
                  <span className='bookmark-button-text'>
                      Cancel
                  </span>  
                </Button> 
            </DialogActions>
          </Dialog> 
        </Box> 
        <Divider variant="middle" component="li" sx={{ marginBottom: '1rem' }} />      
          {bookmarks.length > 0 ? bookmarks.map((unit) => 
            { 
              return <BookmarkItem unit={unit} loadBookmarkCallback={props.loadBookmarkCallback} closeCallback={props.closeCallback} deleteCallback={handleDeleteBookmarkCallback} />;
            }) 
          :
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingBottom: "1rem"}}>
            <Typography sx={{ fontStyle: "italic" }}>Your bookmark list is empty.</Typography>
          </Box> 
        }
      </List> 
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }} 
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert} 
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.status ? "success" : "error"}
          sx={{ width: '100%' }}
        >
          { alert.status ? `Bookmark '${name}' has been ${alert.delete ? "deleted" : "added"}. Refresh the page to see your changes.` : `Unable to ${alert.delete ? "delete" : "add"} bookmark '${name}', please try again later.`}
        </Alert>
      </Snackbar>
    </>
  );
}  