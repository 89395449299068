import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import ContentColumn from '../components/contentColumn.js';
import Divider from '@mui/material/Divider';
import GenerateColumn from '../components/generateColumn.js';
import SearchHeader from '../components/filter/searchHeader.js';
import SelectionColumn from '../components/selectionColumn.js';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import { v4 as uuid } from "uuid"; 

let today = new Date(); 
today.setHours(23, 59, 59, 59);  
let last_7_days = new Date();
last_7_days.setDate(today.getDate() - 7); 
last_7_days.setHours(0, 0, 0, 0); 

const tabs = ["overview", "chat", "social"]

const DashboardPage = (props) => { 
  const [tab, setTab] = useState('overview'); 
  const [addedTag, setAddedTag] = useState(); // This is to link to the added tags from word cloud
  const [selected, setSelected] = useState([]); 
  const [current, setCurrent] = useState([]); 
  const [generate, setGenerate] = useState(false);  
  const [filter, setFilter] = useState(
    [
      {
        match_all: {}
      },
      {
        range: {
          messageDatetime: {
            gte: Date.parse(last_7_days),
            lte: Date.parse(today)
          }
        }
      }
    ]
  )  
  const [selectedGroups, setSelectedGroups] = useState([]); 
  const [date, setDate] = useState();
  const [viewerInfo, setViewerInfo] = useState([]);
  const [deselect, setDeselect] = useState({
    deselect: false,
    key: uuid().slice(0, 8)
  })
  
  /** Callback logic for filter values (search header). This fires when "Search" button is clicked. **/ 
  function handleChangeFilter(value) {   
    setFilter(value);     
    setSelected([]); 
    setSelectedGroups([]);
  }
  
  useEffect(() => {  
    setCurrent([]); // Reset generated checked boxes when "Search" button is clicked
  }, [filter])
   
  /** Changing of tabs **/ //  
  function handleChange(event, tab) {
    setTab(tab);
    setSelected([]); 
    setSelectedGroups([]);
    setCurrent([]);
    setViewerInfo({viewer: false, general: false});
    
  };  
  
  /** Callback function for word cloud/tables/application chip labels **/
  function handleAddFilterTag(value) {   
    setAddedTag(value);   
  }
  
  /** Callback function for new selection **/  
  function handleAddCheckedList(value) { 
    const temp = [...value];
    setSelected(temp)   // const temp = [...value.selected]; 
  }
  
  /** Callback function to handle selected groups from GenerateColumn **/
  function handleUpdateRequest(value) { 
    setCurrent(value); 
    let selected_groups = value.filter(item => item !== 0);  
    setSelectedGroups(selected_groups);  
  } 
  
  /** Check to display generateColumn or contentColumn **/
  useEffect(() => {  
    var filtered_curr = current.filter(item => item !== 0);
    var filtered_sel = selected.filter(item => item !== 0);
    var curr = filtered_curr.sort((a, b) => a.commsName.localeCompare(b.commsName));
    var sel = filtered_sel.sort((a, b) => a.commsName.localeCompare(b.commsName));   
    setGenerate(JSON.stringify(curr) !== JSON.stringify(sel))
  }, [current, selected])
  
  /** Callback function for date change from activitiesTrend and multimediaPropagationTrend **/
  function handleChangeDate(value) {  
    setDate(value);   
  }
  
  /** TODO: Callback from selectionColumn.js to navigate to general chat log **/
  function handleViewerInfo(value) {   
    setViewerInfo(value);
  }
  
  /** Callback from generateDisplay.js **/
  const handleClickDeselectAll = (value) => {  
    setDeselect({
      deselect: value.deselect,
      key: value.key
    })
  }
  
  return (
    <div sx={{height: '100%'}}> 
      <SearchHeader added={addedTag} callback={handleChangeFilter} date={date} viewer={handleViewerInfo} user={props.user}/> 
      <Divider variant="middle" /> 
      <Box sx={{ marginLeft: '1rem' }}>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={handleChange} 
            textColor="inherit"
            indicatorColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FFB100", 
              }
            }} 
          >
            <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="overview" label="Overview" />
            <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="chat" label="Chat" />
            <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="social" label="Social Media" /> 
          </Tabs>
          {tabs.map((value) => { 
              return (
                <TabPanel sx={{"&.MuiTabPanel-root": { padding: "0 1rem 1rem 0" }}} value={value}> 
                  <div sx={{ width: '100%' }}> 
                    <Box bgcolor="#F6F6F6" sx={{ display: 'flex', height: '100%', paddingLeft: '1rem' }}>
                      <SelectionColumn sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', marginTop: '1rem' }} deselect={deselect} filters={filter} filterCallback={handleAddFilterTag} callback={handleAddCheckedList} viewer={handleViewerInfo} tab={value} />   
                      <Box sx={{ flexGrow: 1, width: '60vw' }}>
                        {generate ? <GenerateColumn selected={selected} callback={handleUpdateRequest} deselect={handleClickDeselectAll}/> : 
                        <ContentColumn user={props.user} filterCallback={handleAddFilterTag} dateCallback={handleChangeDate} filters={filter} selected={selectedGroups} tab={value} viewerInfo={viewerInfo}/> }
                      </Box>
                    </Box> 
                  </div> 
              </TabPanel> 
              )
          })} 
        </TabContext> 
      </Box>
    </div>
    );
};

export default DashboardPage;