import { useState, useEffect } from "react";
import ContentCopy from '@mui/icons-material/ContentCopy';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from "@mui/material/Menu"; 
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import { v4 as uuid } from "uuid"; 

export default function ClickableTypography({callback, css, type, value}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [content, setContent] = useState("");
  
  useEffect(() => {
    if (css === "id-typography" || css === "type-typography") {
      setContent(`[${value}]`);
    } else if (type === "senderSelectorUsername") {
      if (value.includes("@")) {
        setContent(value); 
      } else {
        setContent(`@${value}`);
      } 
    } else if (type === "extractedHashtags") {
        setContent(`#${value}`); 
    } else if (css === "root-id") {
       setContent(`(${value})`);
    } else {
      setContent(value);
    }
  }, [value])
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };    
  const handleAddFilter = () => {
    if (value !== "-") { 
      callback({type: type, label: value.toString(), key: uuid().slice(0, 8)})
    }  
    setAnchorEl(null);
  } 
  const handleCopy = () => {
    global.navigator.clipboard.writeText(value.toString()); 
    setAnchorEl(null);
  }
  
  return (
    <div>
      <Typography className={css} onClick={handleClick}>{content}</Typography> 
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleAddFilter}>
          <ListItemIcon>
            <FilterAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add to Filter</ListItemText> 
        </MenuItem> 
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            <ContentCopy  fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText> 
        </MenuItem>  
      </Menu>  
    </div>  
  )
}