import { useState, useEffect, useCallback } from "react";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from "@mui/material/Box"; 
import ChatIcon from '@mui/icons-material/Chat'; 
import CircularProgress from '@mui/material/CircularProgress'; 
import ClickableTypography from '../clickableTypography.js'; 
import FormControl from '@mui/material/FormControl'; 
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack'; 
import Tooltip from '@mui/material/Tooltip';  
import Typography from "@mui/material/Typography";
import { ReactPhotoCollage } from "react-photo-collage";
import fetch from "node-fetch";  
import { format } from 'date-fns';
import { DataGridPro } from '@mui/x-data-grid-pro'; 
import './contentPreview.css'; 
import * as endpoints from '../../../endpoints.js';

// const google = window.google;
const chat = ["WhatsApp", "WeChat", "Telegram"];
const social = ["Facebook", "Instagram", "Tiktok", "Twitter", "Weibo", "YouTube"] 
       
const fetchMediaUrl = (filePath) => {
  // const filePath = "https://ap-southeast-1.console.aws.amazon.com/s3/object/spoc-media-bucket?region=ap-southeast-1&bucketType=general&prefix=ec_twitter/raw/LoliHourly%7C1860352319411585166/LoliHourly%7C1860352319411585166%7C0.jpg"
  const endpoint = endpoints.FILES + "?filepath=" + encodeURIComponent(filePath); 
  const xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    xhr.open("GET", endpoint);   
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*"); 
    xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,access-control-allow-origin,access-control-allow-methods,access-control-allow-headers,auth-token,Access-Control-Allow-Origin,GET,OPTIONS,Origin,X-Auth-Token,authorizationToken")
    xhr.setRequestHeader("authorizationToken", "hunny-allow-1713316693000"); 
    xhr.send(); 
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {    
        resolve(xhr.response);
      } else { 
        console.log("Error: " + xhr.status); 
      }
    }    
  })
}   
 
function DetailPanelContent({ row: rowProp }) { 
  const [hasMedia, setHasMedia] = useState(false); 
  const [isLoading, setIsLoading] = useState(true);  
  const [hasImage, setHasImage] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [hasAudio, setHasAudio] = useState(false);
  const [settings, setSettings] = useState({
      width: "0px",
      height: ["100px", "100px"],
      layout: [8],
      photos: [],
      showNumOfRemainingPhotos: true,
    }
  );   
  const [videoUrls, setVideoUrls] = useState([]);
  const [audioUrls, setAudioUrls] = useState([]);
  
  let extracted_categories = rowProp.expanded_params.extracted_categories.toString().replaceAll(",", ", ");
  let extracted_keywords = rowProp.expanded_params.extracted_keywords.toString().replaceAll(",", ", ");
  let message = rowProp.expanded_params.full_message; 
  useEffect(() => {
    if (rowProp.expanded_params.media_url !== "") {  
      setHasMedia(true);
      let temp_arr = [];
      if (Array.isArray(rowProp.expanded_params.media_url)) {
        temp_arr = rowProp.expanded_params.media_url;
      } else {
        temp_arr = [rowProp.expanded_params.media_url];
      }
      let file_type = temp_arr.map((media_url) => media_url.split(".").pop().replace("&tab=details", ""));
      const img_list = [];
      const vid_list = [];
      const aud_list = [];
      temp_arr.forEach((media_url, idx) => {
        if (["jpg", "jpeg", "png", "gif", "webp"].includes(file_type[idx])) {
          setHasImage(true);
          img_list.push(media_url); 
        } else if (["mp4", "webm"].includes(file_type[idx])) {
          setHasVideo(true);
          vid_list.push(media_url)
        } else if (["mp3", "mpeg", "wav", "flac", "mp4a-latm", "amr", "webm"].includes(file_type[idx])) {
          setHasAudio(true);
          aud_list.push(media_url)
        }
      });
      
      let temp_img = img_list.map((media_url) => fetchMediaUrl(media_url));  
      
      /** Fetching of images **/
      Promise.all(temp_img).then((res) => res.map((media_url) => {
        return {
          source: media_url
        }
      })).then((res) => { 
        setSettings((prev) => ({
          ...prev,
          width: res.length > 8 ? "calc(8 * 100px)" : `calc(${res.length} * 100px)`,
          photos: res
        }));  
        setIsLoading(false);
      })  
      
      /** Fetching of videos **/
      let temp_vid = vid_list.map((media_url) => fetchMediaUrl(media_url)); 
      Promise.all(temp_vid).then((res) => setVideoUrls(res)) 
      
      /** Fetching of audios **/
      let temp_aud = aud_list.map((media_url) => fetchMediaUrl(media_url)); 
      Promise.all(temp_aud).then((res) => setAudioUrls(res))  
    } 
  }, [rowProp.expanded_params.media_url])   
  let transcription = rowProp.expanded_params.extracted_transcription;
  // console.log(rowProp.expanded_params.translated)
   
  return ( 
    <Stack
      sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
      direction="column"
    >
    <Paper sx={{ flex: 1,  height: '100%', margin: '10px', padding: '10px', border: 1, borderColor: 'rgb(0, 0, 0, 0.2)' }} elevation={0}>  
      {extracted_categories ? 
        <Typography>
          <strong>Extracted Keyword Categories: </strong> {extracted_categories}
        </Typography>
        :
        <></>
      } 
      {extracted_keywords ? 
        <Typography>
          <strong>Extracted Keyword Hits: </strong>{extracted_keywords}
        </Typography>
        :
        <></>
      } 
      {message ? 
        <Typography>
          <strong>Message: </strong> {message}
        </Typography>
        :
        <></>
      } 
      {hasMedia ?  
        (isLoading ? 
          <div className="loader"><CircularProgress size="2rem" color="inherit"/></div> : 
          hasVideo ? 
          <Typography><strong>Attached Media: </strong>
            <div>
              {videoUrls.map((url) =>  {
                return <video className="video" width="100" height="100" controls><source src={url} type={"video/mp4"}/></video>
              })}
            </div>
          </Typography> 
          : 
          hasAudio ? 
          <Typography><strong>Attached Media: </strong>
            <div>
              {audioUrls.map((url) =>  {
                return <audio src={url} controls></audio>
              })}
            </div>
          </Typography> 
          :
          hasImage ?
          <Typography>
            <strong>Attached Media: </strong><div className='message-media'><ReactPhotoCollage {...settings} /></div>
          </Typography>
          : <></>)  
        :
        <></>
      } 
      {transcription ? 
        <Typography>
          <strong>Extracted Transcription: </strong> {transcription}
        </Typography>
        :
        <></>
      } 
    </Paper> 
    </Stack>
  );
}

const renderDate = (date) => {
 if (!date) {
   return '';
 } 
 return format(new Date(date), 'Pp');
}

const sort_labels = [
  "Application (A - Z)", 
  "Application (Z - A)", 
  "Group ID (Ascending)", 
  "Group ID (Descending)", 
  "Group Name (A - Z)", 
  "Group Name (Z - A)", 
  "Sender ID (Ascending)", 
  "Sender ID (Descending)", 
  "Sender Name (A - Z)", 
  "Sender Name (Z - A)",
  "Sender Username (A - Z)", 
  "Sender Username (Z - A)",
  "Message ID (Ascending)", 
  "Message ID (Descending)",
  "Response Type (A - Z)", 
  "Response Type (Z - A)",
  "Date (Latest First)",
  "Date (Earliest First)"
  ]   

export default function ContentPreview(props) { 
  
  const columns = [
    {
      field: "application",
    },
    {
      field: "group_id", 
    },
    {
      field: "group_name", 
    },
    {
      field: "sender_id", 
    },
    {
      field: "sender_name", 
    },
    {
      field: "sender_username", 
    },
    {
      field: "message_id",  
    },
    {
      field: "response_type",  
    },
    {
      field: "expanded_params",   
    },
    {
      field: "group",
      headerName: "Group",
      minWidth: 150,
      flex: 1,
      sortable: false, 
      renderCell: (params) => ( 
        <div>  
          <ClickableTypography css="id-typography" type="commsGuid" value={params.value.id} callback={props.filterCallback}/> 
          <ClickableTypography css="typography-group" type="commsName" value={params.value.name} callback={props.filterCallback}/> 
          <ClickableTypography css="typography-group" type="application" value={params.value.application} callback={props.filterCallback}/> 
        </div>
      )
    }, 
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      flex: 1,
      sortable: false, 
      type: "date", 
      renderCell: (params) => (
        // <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1.5em"}}>
          <Typography className='typography'>{renderDate(params.value)}</Typography> 
        // </Box>
      )
    }, 
    {
      field: "sender",
      headerName: "Sender",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div> 
          {params.value.id !== "-" ? <ClickableTypography css="id-typography" type="senderSelectorAccountId" value={params.value.id} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography>}
          {params.value.name !== "-" ? <ClickableTypography css="typography-copyable" type="senderDisplayName" value={params.value.name} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography>} 
          {params.value.username ? <ClickableTypography css="typography-copyable" type="senderSelectorUsername" value={params.value.username.slice(1)} callback={props.filterCallback}/> : <></>} 
        </div>
      )
    },
    {
      field: "keyword_hits",
      headerName: "Keyword Hits",
      // minWidth: 300,
      // flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={<Typography className='cell-tooltip'>{params.value}</Typography>} placement="bottom-start">
          <Typography className='typography' sx={{ WebkitLineClamp: "3"}}>{params.value.split(",").join(', ')}</Typography>
        </Tooltip>
      )
    }, 
    {
      field: "message",
      headerName: "Message",
      minWidth: 500,
      flex: 1,
      sortable: false,
      renderCell: (params) => ( 
        <Tooltip title={<Typography className='cell-tooltip'>{params.value.message}</Typography>} placement="bottom-start">
          <div>
            <Box sx={{ display: "flex", flexDirection: "row"}}>
              <ClickableTypography css="id-typography" type="messageId" value={params.value.id} callback={props.filterCallback}/>
              {params.value.response_type ? 
                <ClickableTypography css='type-typography' type="messageResponseType" value={params.value.response_type} callback={props.filterCallback}/>
                :
                <></>
              } 
              {params.value.media_exists ? <AttachFileIcon sx={{fontSize: '16px', marginLeft: '2px', color: 'grey' }}/> : <></>}
            </Box> 
            <Typography className='typography' sx={{ WebkitLineClamp: "2"}}>{params.value.message}</Typography> 
          </div> 
        </Tooltip>   
      )
    },
    {
      field: "media_transcription",
      headerName: "Media Transcription",
      minWidth: 500,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={<Typography className='cell-tooltip'>{params.value}</Typography>} placement="bottom-start">
        <Typography className='typography' sx={{ WebkitLineClamp: "4"}}>{params.value}</Typography>
        </Tooltip>
      )
    },
    {
      field: "view_params", 
      headerName: "",
      sortable: false, 
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: '8px'}}>
            <IconButton  
              onClick={() => props.callback({
                message_id: params.value.message_id, 
                root_id: params.value.root_id, 
                parent_id: params.value.parent_id,
                message_type: params.value.message_type,
                application: params.value.application, 
                group_name: params.value.group, 
                group_id: params.value.group_id, 
                date: params.value.date,
                viewer: true,
                general: false
              })}>
              <ChatIcon />
            </IconButton>  
          </Box> 
          );
        }
      }, 
  ]; 
  const [option, setOption] = useState('');  
  const [sortModel, setSortModel] = useState([
    {
      field: 'date',
      sort: 'desc',
    },
  ]);  
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);   
    
  useEffect(() => {    
    let filters = props.filters;
    let selected = props.selected;
    let selected_commsGuid = [];
    let selected_commsName = [];
    selected.forEach((group) => { 
      selected_commsGuid.push({
        match_phrase: {
          commsGuid: group.commsGuid
        }
      });
      selected_commsName.push({
        match_phrase: {
          commsName: group.commsName
        }
      }) 
    })
    let temp = [];
    if (selected.length > 0) {
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsGuid  
        }
      })
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsName
        }
      })
    }
    
    if (props.tab === "social") { 
      let should_applications = social.map((app) => [
        {
          match_phrase: {
            application: app
          }
        }]); 
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: should_applications.flat(1)
        }
      })    
    } else if (props.tab === "chat") {
      let should_applications = chat.map((app) => [
        {
          match_phrase: {
            application: app
          }
        }]); 
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: should_applications.flat(1)
        }
      })   
    } 
    
    const content_filters = filters.concat(temp);  
    const body = JSON.stringify({
                  es_query: {
                      _source: [],
                  		aggs: {},
                  		index: "messages.*",
                  		query: {
                  		    bool: {
                  		        filter: content_filters,
                  		        must: [],
              		            must_not: [],
              		            should: [], 
                  		    }
                  		},
                  		size: 500, 
                  		sort: [
                  		    {
                  		        messageDatetime: {
                  		            order: "desc",
                  		            unmapped_type: "boolean"
                  		        }
                  		    }
                  		]
                  }
              })  
    const getContent = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: body,
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      }); 
      // const applications = props.tab === "chat" ? chat : props.tab === "social" ? social : []; 
      res.json().then(body => { 
          let arr = body.hits.hits;
          let temp_arr = [];
          let id_count = 0;
          arr.forEach((item) => {
            let x = item._source;   
            id_count++;
            let temp_message = "";
            if (x.message) {  
              temp_message += x.message;
            }
            // May remove this and add another indicator for mediaExists
            // if (x.mediaType) {
            //   temp_message += `[Attachment: ${x.mediaType}]`
            // } 
            
            temp_arr.push({
              id: id_count,
              group_id: x.commsGuid,
              group_name: x.commsName,
              application: x.application,
              sender_id: x.senderSelectorAccountId ? x.senderSelectorAccountId : '',
              sender_name: x.senderDisplayName,
              sender_username: x.senderSelectorUsername,
              message_id: x.messageId,
              response_type: x.messageResponseType,
              group: { id: x.commsGuid, name: x.commsName, application: x.application },
              date: typeof x.messageDatetime == "string" ? new Date(parseInt(x.messageDatetime)) : new Date(x.messageDatetime),
              sender: { id: x.senderSelectorAccountId ? x.senderSelectorAccountId : '-', name: x.senderDisplayName, username: x.senderSelectorUsername  ? "@"+ x.senderSelectorUsername : '' },
              keyword_hits: x.extractedKeywords_master.length > 0 ? x.extractedKeywords_master.toString() : '-',
              message: { id: x.messageId, message: temp_message, response_type: x.messageResponseType, media_exists: x.mediaType ? true : false },
              media_transcription: x.extractedTranscription ? x.extractedTranscription : "-",
              view_params: { 
                message_id: x.messageId, 
                root_id: x.rootMessageId ? x.rootMessageId : "", 
                parent_id: x.parentMessageId ? x.parentMessageId : "",
                message_type: x.messageResponseType ? x.messageResponseType : "",
                application: x.application, 
                group: x.commsName, 
                group_id: x.commsGuid, 
                date: typeof x.messageDatetime == "string" ? new Date(parseInt(x.messageDatetime)) : new Date(x.messageDatetime) 
              },
              expanded_params: { 
                extracted_keywords: x.extractedKeywords_master,
                extracted_categories: x.extractedKeywordCategories_master,
                full_message: x.message,  
                message_url: x.message_url,
                media_url: x.spocS3MediaUrl ? (x.spocS3MediaUrl_list ? x.spocS3MediaUrl_list : x.spocS3MediaUrl) : "",  // Only fetch image when expanded
                face_hit: x.extractedFaceHitWithDetails,
                extracted_embedded_text: x.extractedEmbeddedText,
                extracted_transcription: x.extractedTranscription
                // translated: new google.translate.TranslateElement({pageLanguage: 'en'}, "test") 
              }
            }) 
          })    
          setRows(temp_arr);  
          setLoading(false); 
          props.count(body.hits.total.value);
      }); 
  }  
    getContent();
  }, [props.filters, props.selected])  
  
  const handleChange = (event) => {  
    let value = event.target.value; 
    setOption(value);
    const field = value.split(" (")[0].replace(" ", "_").toLowerCase();  
    const sort = event.target.value.includes("Ascending") ||
    event.target.value.includes("Latest First") || 
    event.target.value.includes("A - Z") ?
    "asc" : "desc"; 
    setSortModel([
      {
        field: field,
        sort: sort
      }
    ])  
  };   
  
  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);
 
  return (
    <> 
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start'}}> 
        <FormControl sx={{ m: 1, minWidth: 120, mt: 3, "&.MuiFormControl-root": { margin: '0 0 8px 0' }, backgroundColor: '#605958', ".MuiOutlinedInput-input": {color: "white"}, borderRadius: 1}} size="small">
          <Select  
            value={option}
            label="Sort By"
            onChange={handleChange}   
            sx={{ "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 }, 
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { border: 0 }, 
            ".MuiSelect-icon": { color: "white"},
            height: 35,
            fontSize: '14px',
            }}
            displayEmpty
            input={<OutlinedInput />}  
          >
            <MenuItem value="" sx={{color: "#605958" }}>
              <em>Sort By</em>
            </MenuItem>
            {sort_labels.map((label) => (  
              <MenuItem value={label} sx={{color: "#605958"}}>{label}</MenuItem>
            ))} 
          </Select>
        </FormControl> 
      </Box>   
        <Box sx={{ width: '100%' }}>
          <DataGridPro 
            loading={loading}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            columnVisibilityModel={{ 
              application: false, 
              expanded_params: false, 
              group_id: false, 
              group_name: false, 
              sender_id: false, 
              sender_name: false, 
              sender_username: false, 
              message_id: false, 
              response_type: false,
              keyword_hits: false
            }}
            sortModel={sortModel}  
            getRowHeight={() => 'auto'}
            getDetailPanelContent={getDetailPanelContent} 
            getDetailPanelHeight={getDetailPanelHeight}
            disableRowSelectionOnClick 
            disableColumnResize 
            disableColumnMenu 
            disableRowClick
            rows={rows}  
            columns={columns} 
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                 outline: "none !important",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "rgb(0, 0, 0, 0, 0.5)", 
              },  
            }}
            pagination
            autoHeight={true}
          />
        </Box>   
    </>
  );
}  