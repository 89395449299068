import * as React from 'react'; 
import Button from '@mui/material/Button';  
import LogoutIcon from '@mui/icons-material/Logout';
import './navigationBar.css'; 

export default function SignOutMenu(props) {  
  return (
    <div>
      <Button className='menu-subheader' endIcon={<LogoutIcon />} onClick={props.signOut}>
        Sign Out
      </Button> 
    </div>
  );
}