import { useState, useEffect } from "react"; 
import { Link, useSearchParams } from 'react-router-dom';  
import * as endpoints from '../../endpoints.js';
import './downloadPage.css';
  
const DOWNLOAD_STATE = [
    "initial",
    "downloading",
    "downloaded",
    // "unauthorized",
    "invalid request"];
      
const DownloadPage = () => {  
    const [status, setStatus] = useState(DOWNLOAD_STATE[0]);
    const [searchParams] = useSearchParams();  

    useEffect(() => { 
        const filePath = searchParams.get("filepath");
        if (filePath === null) {
            setStatus(DOWNLOAD_STATE[4]);
            console.log("Error: Params for 'filepath' not found."); 
            return;
        }
        // const endpoint = "https://kv2fa2wh23.execute-api.ap-southeast-1.amazonaws.com/Dev/files?filepath=" + encodeURIComponent(filePath);  
        
        // console.log("Filepath: ", filePath); // Multiple search params in url
        // const filePath = "https://ap-southeast-1.console.aws.amazon.com/s3/object/spoc-media-bucket-gcc?region=ap-southeast-1&bucketType=general&prefix=tg/videos/-1001781035677/1297992/document_2024-11-19_01-15-01.mp4"
        // const filePath = "https%3A%2F%2Fs3.console.aws.amazon.com%2Fs3%2Fobject%2Fspoc-media-bucket%3Fregion%3Dap-southeast-1%26prefix%3Dtg%2Fimages%2F-1001288303688%2F244422%2Fphoto_2024-05-03_01-24-01.jpg%26tab%3Ddetails"
        const endpoint = endpoints.FILES + "?filepath=" + encodeURIComponent(filePath);  
        // console.log("https%3A%2F%2Fs3.console.aws.amazon.com%2Fs3%2Fobject%2Fspoc-media-bucket%3Fregion%3Dap-southeast-1%26prefix%3Dtg%2Fimages%2F-1001288303688%2F244422%2Fphoto_2024-05-03_01-24-01.jpg%26tab%3Ddetails");
        
        const xhr = new XMLHttpRequest(); 
        xhr.open("GET", endpoint);   
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*"); 
        xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,access-control-allow-origin,access-control-allow-methods,access-control-allow-headers,auth-token,Access-Control-Allow-Origin,GET,OPTIONS,Origin,X-Auth-Token,authorizationToken")
        xhr.setRequestHeader("authorizationToken", "hunny-allow-1713316693000");
        // xhr.setRequestHeader("Access-Control-Allow-Methods", "GET"); 
        xhr.send(); 
        xhr.onload = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {   
            // console.log(xhr.response);  
            setStatus(DOWNLOAD_STATE[1]);
            window.open(xhr.response);
            setStatus(DOWNLOAD_STATE[2]);
            return;
          } else {
            setStatus(DOWNLOAD_STATE[3]); 
            console.log("Error: " + xhr.status);
            return;
          }
        };  
      }, [searchParams]);     
      
    if (status === DOWNLOAD_STATE[0] || status === DOWNLOAD_STATE[1]) {
        return (  
            <div className='download-header'>  
                <span>Processing your request... </span>
                If this is taking too long, refresh the page.
            </div>   
        )
    } else if (status === DOWNLOAD_STATE[2]) {
        return (  
            <div className='download-header'>  
                <span> File has been downloaded. </span> Return <Link className='home-link' to="/">home</Link>.
            </div>  
        );         
    } else if (status === DOWNLOAD_STATE[3]) {
        return (  
            <div className='download-header'>  
                An error has occurred. Refresh and try again or return <Link className='home-link' to="/">home</Link>.
            </div>  
        );        
    } 
}

export default DownloadPage;  