import React from 'react';  
import ViewAllAdhocTaskingTable from '../components/viewAllAdhocTaskingTable.js';
import './viewAllTaskingPage.css';

const ViewAllAdhocTaskingPage = (props) => {
  return (
    <>
        <h1 className='header'>
            View All Urgent Tasking
        </h1>
        <div className='view-tasking-table'>
          <ViewAllAdhocTaskingTable user={props.user}/>
        </div>
    </>
    );
};

export default ViewAllAdhocTaskingPage; 