import { useState, useEffect } from 'react'; 
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';  
import Paper from '@mui/material/Paper';  
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails'; 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; 
import ActivitiesTrend from "./activitiesTrend.js";
import MultimediaPropagationTrend from "./multimediaPropagationTrend.js";
import WordCloud from './wordCloud.js'  
import fetch from "node-fetch"; 
import * as endpoints from '../../../endpoints.js';

const Accordion = styled((props) => ( <MuiAccordion disableGutters elevation={0} square {...props} /> ))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export default function AnalyticsDisplay(props) {  
    const [keywords, setKeywords] = useState([]);
    const [faceprints, setFaceprints] = useState([]);
    
    /** Fetch Data for Word Cloud **/
    useEffect(() => {  
        let filters = props.filters;
        let selected = props.selected;
        let selected_commsGuid = [];
        let selected_commsName = [];
        selected.forEach((group) => { 
          selected_commsGuid.push({
            match_phrase: {
              commsGuid: group.commsGuid
            }
          });
          selected_commsName.push({
            match_phrase: {
              commsName: group.commsName
            }
          }) 
        })
        let temp = [];
        if (selected.length > 0) {
          temp.push({
            bool: {
              minimum_should_match: 1,
              should: selected_commsGuid  
            }
          })
          temp.push({
            bool: {
              minimum_should_match: 1,
              should: selected_commsName
            }
          })
        }
        const content_filters = filters.concat(temp);
        const getKeywordHits = async () => {
            const res = await fetch(endpoints.ELASTIC, { 
                headers: {
                    authorizationToken: endpoints.AUTHORIZATION_TOKEN
                },
                method: 'POST',
                body: JSON.stringify({
                  es_query: {
                    _source: [],
                    index: "messages.*",
                    aggs: {
                      2: {
                        terms: {
                          field: "extractedKeywords_master",
                          order: {
                            _count: "desc"
                          },
                          size: 50
                        }, 
                      }
                    },
                    size: 0,
                    stored_fields: [
                      "*"
                    ],
                    script_fields: {},
                    query: {
                      bool: {
                        must: [],
                        filter: content_filters,
                        should: [],
                        must_not: []
                      }
                    }
                  }
                })   
            }).catch((err) => {
              console.log('POST call failed: ', err.message);
            });
            res.json().then(body => {
                let keywords = [];
                body.aggregations["2"].buckets.forEach((x) => {
                    keywords.push({
                        text: x.key,
                        value: x.doc_count
                    })  
                }) 
                setKeywords(keywords); 
            });
        } 
        const getFaceprints = async () => {
            const res = await fetch(endpoints.ELASTIC, { 
                headers: {
                    authorizationToken: endpoints.AUTHORIZATION_TOKEN
                },
                method: 'POST',
                body: JSON.stringify({
                  es_query: {
                    _source: [],
                    index: "messages.*",
                    aggs: {
                      2: {
                        terms: {
                          field: "extractedFaceHitWPerson",
                          order: {
                            _count: "desc"
                          },
                          size: 20
                        }, 
                      }
                    },
                    size: 0,
                    stored_fields: [
                      "*"
                    ],
                    script_fields: {},
                    query: {
                      bool: {
                        must: [],
                        filter: content_filters,
                        should: [],
                        must_not: []
                      }
                    }
                  }
                })   
            }).catch((err) => {
              console.log('POST call failed: ', err.message);
            });
            res.json().then(body => {
                let faceprints = [];
                body.aggregations["2"].buckets.forEach((x) => {
                    faceprints.push({
                        text: x.key,
                        value: x.doc_count
                    })  
                }) 
                setFaceprints(faceprints); 
            });
        }
        getKeywordHits();
        getFaceprints();
    }, [props.filters])
     
    return (
        <Box bgcolor="white" className='content-box'>
            <div className='content-header'>
                Charts & Analytics
            </div>  
            <div sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1 }}> 
                <Paper sx={{ margin: '1rem 1rem 0 1rem', paddingTop: '0.5rem'}} variant="outlined"> 
                    <div className='sub-header-alt'>
                        Top 50 Keyword Hits Word Cloud
                    </div>   
                    {keywords.length !== 0 ? <WordCloud words={keywords} callback={props.filterCallback} type={"extractedKeywords_master"} /> : <Box sx={{ padding: "1rem 0 1rem 1rem" }}> No results found. </Box>}  
                </Paper> 
                <Paper sx={{ margin: '1rem 1rem 0 1rem', paddingTop: '0.5rem'}} variant="outlined"> 
                    <div className='sub-header-alt'>
                        Top 20 Watched Faceprint Personalities Cloud 
                    </div>   
                    {faceprints.length !== 0 ? <WordCloud words={faceprints} callback={props.filterCallback} type={"extractedFaceHitWPerson"} /> : <Box sx={{ padding: "1rem 0 1rem 1rem" }}> No results found. </Box>} 
                </Paper> 
            </div> 
            <div className='content-accordion'> 
                <Accordion elevation={0} disableGutters defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >
                        <div className='sub-header'>
                            Activities Trend
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ActivitiesTrend callback={props.dateCallback} filters={props.filters} selected={props.selected}/>
                    </AccordionDetails>
                </Accordion>  
                <Accordion elevation={0} disableGutters defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >
                        <Box sx={{ display: 'flex', gap: '10px' }}> 
                            <div className='sub-header'>
                                Multimedia Propagation Trend
                            </div>  
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MultimediaPropagationTrend callback={props.dateCallback} filters={props.filters} selected={props.selected}/>
                    </AccordionDetails>
                </Accordion>   
            </div>        
        </Box> 
    );
}

// <MultimediaPropagationTrend/> <KeywordHitsWordCloud/> <WordCloud words={keywords} callback={props.callback}/>