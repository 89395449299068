import { useState, useEffect } from 'react'; 
import AnalyticsDisplay from './analytics/analyticsDisplay.js';
import Box from '@mui/material/Box';
import ChatDisplay from './content/chatDisplay.js';
import ContentDisplay from './content/contentDisplay.js';
import GeneralChatDisplay from './content/generalChatDisplay.js';
import StatisticsDisplay from './statistics/statisticsDisplay.js';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
 
const ContentColumn = (props) => {   
    const [viewerInfo, setViewerInfo] = useState({
        message_id: '', 
        root_id: '',
        parent_id: '',
        message_type: '',
        group: '',
        group_id: '',
        date: '',
        application: '',
        viewer: false,
        general: false
    });
    const [value, setValue] = useState('one');
    const [chatDisplayKey, setChatDisplayKey] = useState(Math.random());   
    
    useEffect(() => { 
        if (props.viewerInfo.viewer && props.viewerInfo.general) {
            setViewerInfo({
                group_name: props.viewerInfo.group_name,
                group_id: props.viewerInfo.group_id ,
                application: props.viewerInfo.application,
                date: new Date(), // To change
                viewer: props.viewerInfo.viewer,
                general: props.viewerInfo.general
            })
            setValue('one');
        } else {
            setViewerInfo({viewer:false, general: false})
        }
    }, [props.viewerInfo])
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    // Callback to view chat-log for specific group
    const handleToggleView = (value) => {  
        setViewerInfo({
            message_id: value.message_id, 
            root_id: value.root_id,
            parent_id: value.parent_id,
            message_type: value.message_type,
            group_name: value.group_name,
            group_id: value.group_id,
            date: value.date,
            application: value.application,
            viewer: value.viewer,
            general: value.general
        }) 
    } 
    
    const handleRenderChatDisplay = (value) => { 
        setChatDisplayKey(value);
    }
    
    return (
    <Box sx={{ height: '100%', marginLeft: '1rem', marginTop: '1rem' }}>    
        <TabContext value={value}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                indicatorColor="inherit"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FFB100", 
                  }
                }} 
            >
                <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="one" label="Content" />
                <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="two" label="Statistics" />
                <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="three" label="Analytics" /> 
            </Tabs>
            <TabPanel sx={{"&.MuiTabPanel-root": { padding: "0 1rem 1rem 0" }}} value="one">
                { viewerInfo.viewer ? 
                    (viewerInfo.general ? 
                        <GeneralChatDisplay user={props.user} info={viewerInfo} callback={handleToggleView} renderCallback={handleRenderChatDisplay} key={chatDisplayKey} filterCallback={props.filterCallback}/> : 
                        <ChatDisplay user={props.user} info={viewerInfo} callback={handleToggleView} filterCallback={props.filterCallback} key={chatDisplayKey} renderCallback={handleRenderChatDisplay}/>
                    ) 
                    : <ContentDisplay dateCallback={props.dateCallback} callback={handleToggleView}  filters={props.filters} selected={props.selected} tab={props.tab} filterCallback={props.filterCallback}/>  
                } 
            </TabPanel>
            <TabPanel sx={{"&.MuiTabPanel-root": { padding: "0 1rem 1rem 0" }}} value="two">
                <StatisticsDisplay dateCallback={props.dateCallback} filters={props.filters} selected={props.selected} tab={props.tab} filterCallback={props.filterCallback}/>
            </TabPanel>
            <TabPanel sx={{"&.MuiTabPanel-root": { padding: "0 1rem 1rem 0" }}} value="three">
                <AnalyticsDisplay dateCallback={props.dateCallback} filters={props.filters} selected={props.selected} filterCallback={props.filterCallback}/>
            </TabPanel>  
        </TabContext>  
    </Box>
    );
};

export default ContentColumn;