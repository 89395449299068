/** DEV ENDPOINTS **/
// export const ELASTIC = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/elastic_dev2"
// export const FACEPRINT = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/faceprint_dev2"
// export const FILES = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/files_dev2"
// export const GET_BOOKMARK = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/get_bookmark_dev2"
// export const SAVE_BOOKMARK = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/save_bookmark_dev2"
// export const GET_PIN_MESSAGE = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/get_pin_msg_dev2"
// export const SAVE_PIN_MESSAGE = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/pin_msg_dev2"
// export const AUTHORIZATION_TOKEN = "hunny-allow-1713316693000" 
 
/** PROD ENDPOINTS **/ 
export const ELASTIC = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/elastic" 
export const FACEPRINT = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/faceprint" 
export const FILES = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/files" 
export const GET_BOOKMARK = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/get_bookmark" 
export const SAVE_BOOKMARK = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/save_bookmark" 
export const GET_PIN_MESSAGE = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/get_pin_msg" 
export const SAVE_PIN_MESSAGE = "https://qjkb2y8gnl.execute-api.ap-southeast-1.amazonaws.com/Dev/pin_msg" 
export const AUTHORIZATION_TOKEN = "hunny-allow-1713316693000"