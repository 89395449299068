import * as React from 'react'; 
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';   
import KeywordMenu from './keywordMenu.js';
import GroupTagMenu from './groupTagMenu.js';
import FaceprintMenu from './faceprintMenu.js';
import TaskingMenu from './taskingMenu.js'; 
import AdhocTaskingMenu from './adhocTaskingMenu.js'; 
import './navigationBar.css'; 

export default function ConfigurationMenu(navigation) {
    const [checked, setChecked] = React.useState(false); 
  
const location = useLocation();
React.useEffect(() => {
   setChecked(false);
}, [location]);
  
  const handleOpen = (event) => {
      setChecked(!checked);
  } 
  return ( 
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%',  alignItems: 'center',  }}>
      <Button className='menu-subheader' onClick={handleOpen}>
        Configure
      </Button> 
            <Collapse orientation="horizontal" in={checked}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>  
                  <KeywordMenu/>
            <GroupTagMenu/>
            <FaceprintMenu/>
            <TaskingMenu/> 
                </Box>
            </Collapse>  
    </Box>    
  );
} 

// <AdhocTaskingMenu/>