import { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, getGridStringOperators } from '@mui/x-data-grid'; 
import Box from "@mui/material/Box"; 
import {format} from 'date-fns'
import fetch from "node-fetch";
import Typography from "@mui/material/Typography";
import ClickableTypography from '../clickableTypography.js';
import * as endpoints from '../../../endpoints.js';
 
const filterOperators = getGridStringOperators().filter(({ value }) => 
  ['equals', 'contains'].includes(value),
); 

const renderDate = (date) => {
 if (!date) {
   return '';
 } 
 return format(new Date(date), 'Pp');
}

function CustomToolbar() {
  return (
    <GridToolbarContainer> 
      <GridToolbarFilterButton /> 
      <Box sx={{ flexGrow: 1 }} /> 
    </GridToolbarContainer>
  );
}

export default function Top100Domains(props) {    
  const [rows, setRows] = useState([]);
  const columns = [  
    {
        field: "url_domain",
        headerName: "URL Domain",
        minWidth: 100,
        flex: 1,
        sortable: true,
        filterOperators,
        renderCell: (params) => ( 
          <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1em"}}>
            { params.value !== "NA" ? <ClickableTypography css="typography-single" type="extractedUrlDomains" value={params.value} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography> }  
          </Box> 
        )
    }, 
    {
        field: "message_count",
        headerName: "# Messages",
        minWidth: 50,
        flex: 1,
        type: "number",
        headerAlign: "left",
        align: "left",
        sortable: true, 
        filterable: false,
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "group_count",
        headerName: "# Groups",
        minWidth: 50,
        flex: 1,
        type: "number",
        headerAlign: "left",
        align: "left",
        sortable: true, 
        filterable: false,
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "last_active",
        headerName: "Last Active",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        type: "date", 
        renderCell: (params) => (
          <div className='typography-single' onClick={() => props.dateCallback(params.value)}> {renderDate(params.value)} </div> 
        )
    },   
  ];
  const [loading, setLoading] = useState(true); 
  
  useEffect(() => {    
    let filters = props.filters;
    let selected = props.selected; 
    let selected_commsGuid = [];
    let selected_commsName = []; 
    selected.forEach((group) => { 
      selected_commsGuid.push({
        match_phrase: {
          commsGuid: group.commsGuid
        }
      });
      selected_commsName.push({
        match_phrase: {
          commsName: group.commsName
        }
      }) 
    })
    let temp = [];
    if (selected.length > 0) {
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsGuid  
        }
      })
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsName
        }
      })
    }
    
    const content_filters = filters.concat(temp);
    const getContent = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
                  es_query: {
                      _source: [],
                		  aggs: {
                        2: {
                          terms: {
                            field: "extractedUrlDomains",
                            order: {
                              _count: "desc"
                            },
                            size: 100
                          },
                          aggs: {
                            3: {
                              cardinality: {
                                field: "commsGuid"
                              }
                            },
                            4: {
                              max: {
                                field: "messageDatetime"
                              }
                            }
                          }
                        }
                      },
                  		index: "messages.*",
                  		query: {
                  		    bool: {
                  		        filter: content_filters,
                  		        must: [],
              		            must_not: [],
              		            should: [], 
                  		    }
                  		},
                  		size: 0 
                  }
              })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });  
      res.json().then(body => {  
        let agg = body?.aggregations; 
        let arr = agg ? (agg["2"] ? agg["2"].buckets : []) : [];
        let temp_arr = [];
        let id_count = 0;
        arr.forEach((item) => {
          let url_domain = item.key;  
          let message_count = item.doc_count;
          let group_count = item["3"].value;
          let date = new Date(item["4"].value);
          id_count++;
          temp_arr.push({
            id: id_count,
            url_domain: url_domain, 
            message_count: message_count, 
            group_count: group_count,
            last_active: date 
          })
        })    
        setRows(temp_arr);
        setLoading(false);
      }); 
  }  
    getContent();
  }, [props.filters, props.selected])    
  
  if (loading) {
    return <div> Loading... </div>
  }
  
  return (
    <> 
      <div style={{ height: '100%', width: "300"}}>
        <DataGrid   
          autoHeight 
          initialState={{ 
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 30]}
          disableRowSelectionOnClick 
          disableColumnResize 
          disableColumnMenu
          slots={{ toolbar: CustomToolbar }}
          rows={rows} 
          columns={columns}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
               outline: "none !important",
            }, 
            ".MuiButton-root": {
              color: "#605958"
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "rgba(218,218,218,0)", 
            }
          }}
        />
      </div>  
    </>
  );
}