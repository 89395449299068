import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import ChatDisplayPinList from './chatDisplayPinList.js';
import ChatDisplaySenderList from './chatDisplaySenderList.js';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from '@mui/material/Menu';
import PushPinIcon from '@mui/icons-material/PushPin';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RefreshIcon from '@mui/icons-material/Refresh';
import Toolbar from '@mui/material/Toolbar';
import './chatDisplay.css';
import fetch from "node-fetch"; 
import * as endpoints from '../../../endpoints.js';

export default function ChatDisplayAppBar(props) { 
    const [loading, setLoading] = useState(true);
    const [anchorElSenderList, setAnchorElSenderList] = useState(null);
    const [anchorElIndividualPinList, setAnchorElIndividualPinList] = useState(null);
    const [anchorElTeamPinList, setAnchorElTeamPinList] = useState(null); 
    const [pinListLoaded, setPinListLoaded] = useState({
        individual: true,
        team: true
    })
    const [pinList, setPinList] = useState({
        individual: [],
        team: []
    })
    
    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])
    
    const handleClickSenderList = (event) => {
        setAnchorElSenderList(anchorElSenderList ? null : event.currentTarget);
    };
    const handleClickIndividualPinList = (event) => {
        setAnchorElIndividualPinList(anchorElIndividualPinList ? null : event.currentTarget);
        setPinListLoaded({
            individual: false,
            team: true
        })
    };
    const handleClickTeamPinList = (event) => {
        setAnchorElTeamPinList(anchorElTeamPinList ? null : event.currentTarget);
        setPinListLoaded({
            individual: true,
            team: false
        })
    };    
    const openSenderList = Boolean(anchorElSenderList);
    const openIndividualPinList = Boolean(anchorElIndividualPinList);
    const openTeamPinList = Boolean(anchorElTeamPinList);
    const id = openSenderList ? 'simple-popper' : undefined; 
    const handleCloseSenderList = () => {
        setAnchorElSenderList(null);
    };
    const handleCloseIndividualPinList = () => {
        setAnchorElIndividualPinList(null);
    };
    const handleCloseTeamPinList = () => {
        setAnchorElTeamPinList(null);
    };    
    
    const handleLoadCallback = (value) => {
        setPinListLoaded(value); 
    }
    
    const handlePinCallback = (value) => {
        props.pinCallback(value); 
    }
    
    useEffect(() => {   
        const username = props.user.username; 
        const team = props.user.team;
        const application = props.info.application;
        const group_id = props.info.group_id; 
        const getPinnedMessages = async (isIndividual) => {
            const res = await fetch(endpoints.GET_PIN_MESSAGE, { 
                headers: {
                    authorizationToken: endpoints.AUTHORIZATION_TOKEN
                },
                method: "POST",
                body: JSON.stringify({
                    action_type: isIndividual ? "get_records_by_creatorApplicationCommsguid_and_filtersortby_messagedatetime": "get_records_by_teamApplicationCommsguid_and_filtersortby_messagedatetime",
                    pin_type: isIndividual ? "individual" : "team",
                    sort_order: "desc",
                    creator: isIndividual ? username : "-",
                    team: isIndividual ? "-" : team,
                    application: application,
                    comms_guid: group_id, 
                    range_operator: ">", 
	                message_datetime: "0", 
                    result_size_limit: "100"
                }) 
            }).catch((err) => {
                console.log("getPinnedMessages Error:", err)
            }); 
            return new Promise((resolve, reject) => {
                res.json().then(body => {resolve(body)})
            }).catch((err) => console.log("Error:", err));     
        }   
        let individual = true; 
        const individualPinnedMessages = getPinnedMessages(individual);
        const teamPinnedMessages = getPinnedMessages(!individual);
        
        Promise.all([individualPinnedMessages, teamPinnedMessages]).then((res) => {
            const temp_individual = res[0];
            const temp_team = res[1];
            let temp_individual_records = temp_individual.map((obj) => 
                { 
                    var temp = {};
                    temp[obj.es_record_id] = obj.id
                    return temp; 
                }); 
            let temp_team_records = temp_team.map((obj) => 
                { 
                    var temp = {};
                    temp[obj.es_record_id ] = obj.id
                    return temp; 
                });  
            setPinList({
                individual: temp_individual_records,
                team: temp_team_records
            })
            props.pinListCallback(temp_individual_records.concat(temp_team_records)); 
            setPinListLoaded({
                individual: true,
                team: true
            }) 
        }) 
    }, []) 
    
    return ( 
        <AppBar position="static" sx={{ backgroundColor: "#605958", marginTop: '0.5rem', borderRadius: '10px 10px 0 0' }} elevation={0}> 
            <Toolbar sx={{ "&.MuiToolbar-root": { padding: "0 10px 0 20px" }}}variant="dense"> 
                <div className='chatlog-appbar' color="white">
                    <IconButton
                        size="small" 
                        edge="start"
                        color="inherit" 
                        sx={{ mr: 2 }}
                        onClick={() => { props.callback({viewer: false, general: false}) }}
                    >
                        <ArrowBackIcon />
                    </IconButton>  
                    <div className='chatlog-header'> 
                        Chat Log  
                    </div> 
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>  
                        <LoadingButton loading={!pinListLoaded.individual} loadingPosition="start" variant="outlined" startIcon={<PushPinIcon sx={{ color: "white" }}/>} onClick={handleClickIndividualPinList} className="pin-button">
                            <span sx={{ color: "white" }}>
                                My Pins
                            </span>
                        </LoadingButton>  
                        <LoadingButton loading={!pinListLoaded.team} loadingPosition="start" variant="outlined" startIcon={<PushPinIcon sx={{ color: "white" }}/>} onClick={handleClickTeamPinList} className="pin-button">
                            <span sx={{ color: "white" }}>
                                Team Pins
                            </span>
                        </LoadingButton>  
                        <IconButton
                            aria-describedby={id}
                            size="small" 
                            color="inherit" 
                            sx={{ mr: 1 }}
                            onClick={handleClickSenderList}
                        >
                            <RecentActorsIcon />
                        </IconButton> 
                        <Box sx={{ marginRight: "5px" }}>
                            { loading ? 
                              <CircularProgress sx={{ marginRight: '10px' }} color="inherit" size="20px" /> 
                              : 
                              <IconButton size="small" onClick={() => {props.renderCallback(Math.random())}}><RefreshIcon sx={{ color: "white" }}/></IconButton>
                            }
                        </Box> 
                        <Menu
                            anchorEl={anchorElIndividualPinList}
                            open={openIndividualPinList}
                            onClose={handleCloseIndividualPinList}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          > 
                            <ChatDisplayPinList pinType={"individual"} pinList={pinList.individual} loadingCallback={handleLoadCallback} pinCallback={handlePinCallback} unpinCallback={props.unpinCallback}/>
                        </Menu>
                        <Menu
                            anchorEl={anchorElTeamPinList}
                            open={openTeamPinList}
                            onClose={handleCloseTeamPinList}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          > 
                            <ChatDisplayPinList pinType={"team"} pinList={pinList.team} loadingCallback={handleLoadCallback} pinCallback={handlePinCallback} unpinCallback={props.unpinCallback}/>
                        </Menu>                        
                        <Menu
                            anchorEl={anchorElSenderList}
                            open={openSenderList}
                            onClose={handleCloseSenderList}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          > 
                            <ChatDisplaySenderList senderList={props.senderList}/>
                        </Menu>
                    </Box> 
                </div>
            </Toolbar>
        </AppBar> 
    );
} 