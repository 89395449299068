import React, { useState } from 'react';
import Alert from '@mui/material/Alert'; 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { put } from 'aws-amplify/api'; 
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid"; 

const ERROR_MESSAGE = "Platform, tasking type and/or tasking value field(s) are empty!";
const SUCCESS_MESSAGE = "Successfully submitted tasking. Activate entry ";
const platforms = [
    "Facebook", 
    "Instagram",
    "YouTube",
    "TikTok",
    "Twitter"
    ];
const tasking_type = [ 
    "Hashtag", 
    "Keyword",
    "Account"
    ]; 

export default function AddTaskingForm(props) {  
    const [platform, setPlatform] = useState('');  
    const [taskingType, setTaskingType] = useState('');
    const [hashtag, setHashtag] = useState(''); // If tasking type is 'Hashtag'
    const [keyword, setKeyword] = useState(''); // If tasking type is 'Keyword'
    const [accountID, setAccountID] = useState(''); // If tasking type is 'Activity'
    const [accountName, setAccountName] = useState(''); // If tasking type is 'Activity'
    const [accountURL, setAccountURL] = useState(''); // If tasking type is 'Activity' 
    const [accountUsername, setAccountUsername] = useState(''); // If tasking type is 'Activity' 
    const [status, setStatus] = useState(null); 
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);  
    // const [team, setTeam] = useState("");
    
    // useEffect(() => {  
    //   const getTeam = async () => {
    //     try {
    //       const getOperation = get({ 
    //       apiName: 'gccusermapapi',
    //       path: '/gccusermap' 
    //       });
    //       const { body } = await getOperation.response;
    //       const json = await body.json(); 
    //       const username = props.user.username; 
    //       let team = ""; // Only to check if user-to-team mapping exists for current user
    //       json.forEach((obj) => { 
    //         if (obj.username === username) { 
    //           team = obj.team;  
    //           setTeam(team);
    //           return;
    //         }});
    //       if (team.length === 0) { 
    //         console.log("User-to-team mapping for this user does not exist.");
    //       } 
    //       // console.log('GET call succeeded: ', json); 
    //     } catch (error) {
    //       console.log('GET call failed: ', error);
    //     }
    //   } 
    //   getTeam();   
    // }, [props.user]);
    
    const addHashtagTasking = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'gcctaskingapi',
                path: '/gcctasking',
                options: {
                    body: {
                        PK: pk,
                        platform: platform, 
                        tasking_type: taskingType,
                        tasking_value: hashtag,
                        hashtag: hashtag,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        is_active: false,
                        date_modified: 'N.A.',
                        latest_status: 'N.A.',
                        last_modifier: 'N.A.',
                        account_name: 'N.A.',
                        team: props.user.team,
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
    
    const addKeywordTasking = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'gcctaskingapi',
                path: '/gcctasking',
                options: {
                    body: {
                        PK: pk,
                        platform: platform,
                        tasking_type: taskingType,
                        tasking_value: keyword,
                        keyword: keyword,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        is_active: false,
                        date_modified: 'N.A.',
                        latest_status: 'N.A.',
                        last_modifier: 'N.A.',
                        account_name: 'N.A.',
                        team: props.user.team,
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
    
    const addAccountTasking = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'gcctaskingapi',
                path: '/gcctasking',
                options: {
                    body: {
                        PK: pk,
                        platform: platform,
                        tasking_type: taskingType,
                        tasking_value: accountID + "," + accountName + "," + accountUsername + "," + accountURL,
                        account_id: accountID,
                        account_name: accountName,
                        account_url: accountURL,
                        account_username: accountUsername,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        is_active: false,
                        date_modified: 'N.A.',
                        latest_status: 'N.A.',
                        last_modifier: 'N.A.',
                        team: props.user.team,
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
 
    const handleConfirm = () => {
        if (platform === '' || taskingType ==='') {
            setStatus(false);
            setOpenAlert(true);
        } else if ((taskingType === 'Hashtag' && hashtag.trim() === '') || 
        (taskingType === 'Keyword' && keyword.trim() === '') || 
        (taskingType === 'Account' && (accountID.trim() === '' || accountName.trim() === '' || accountURL.trim() === '' || accountUsername.trim() === ''))) {
            setStatus(false);
            setOpenAlert(true);     
        } else { 
            const pk = uuid().slice(0, 12); 
            setStatus(true);
            setOpenAlert(true);  
            if (taskingType === 'Hashtag') {
                addHashtagTasking(pk); 
            } else if (taskingType === 'Keyword') {
                addKeywordTasking(pk);
            } else if (taskingType === 'Account') {
                addAccountTasking(pk); 
            } else {
                console.log('Error: Tasking type does not exist.');
            }            
        } 
        setOpenDialog(false);   
    } 
    
    const handleReset = () => {
        setPlatform('');
        setTaskingType(''); 
        setHashtag('');
        setKeyword('');
        setAccountID('');
        setAccountName('');
        setAccountURL('');
        setAccountUsername('');
        setStatus(null);
        setOpenAlert(false);
    }
    
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
    } 
    
    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}<Link to="/social-media-tasking/manage-team">here</Link>.</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    return (
        <Box className='table-position'> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to submit record?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment> 
            <Paper elevation={0} className='table'> 
                <Box> 
                    <div className='table-header'>
                        Platform
                    </div>
                    <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                        <FormControl fullWidth>
                            <InputLabel size="small">Select a platform</InputLabel>
                            <Select  
                              value={platform}
                              label="Select a platform"
                              size="small"
                              onChange={(event) => setPlatform(event.target.value)}
                            >
                            {platforms.map(
                                (type, i) => ( 
                                    <MenuItem key={i} value={type}>{type}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Box>
                    <div className='table-header'>
                        Tasking Type
                    </div>
                    <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                        <FormControl fullWidth>
                            <InputLabel size="small">Select a tasking type</InputLabel>
                            <Select  
                              value={taskingType}
                              label="Select a tasking type"
                              size="small"
                              onChange={(event) => setTaskingType(event.target.value)}
                            >
                            {tasking_type.map(
                                (type, i) => ( 
                                    <MenuItem key={i} value={type}>{type}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Box>
                    {(taskingType === 'Hashtag') ?
                        <><div className='table-header'>
                            Tasking Value
                        </div> 
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                            <FormControl fullWidth> 
                                <TextField 
                                    value={hashtag}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Hashtag"
                                    onChange={(event) => setHashtag(event.target.value)}
                                />     
                            </FormControl>
                        </Box> </>:
                        <div></div> 
                    }
                    {(taskingType === 'Keyword') ?
                        <><div className='table-header'>
                            Tasking Value
                        </div> 
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                            <FormControl fullWidth>                          
                                <TextField 
                                    value={keyword}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Keyword"
                                    onChange={(event) => setKeyword(event.target.value)}
                                />     
                            </FormControl>
                        </Box> </>:
                        <div></div> 
                    }
                    {(taskingType === 'Account') ?
                        <><div className='table-header'>
                            Tasking Value
                        </div> 
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                            <FormControl fullWidth> 
                                <TextField 
                                    value={accountID}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account ID"
                                    onChange={(event) => setAccountID(event.target.value)}
                                />     
                            </FormControl>
                            <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                <TextField 
                                    value={accountName}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account Name"
                                    onChange={(event) => setAccountName(event.target.value)}
                                />     
                            </FormControl>
                            <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                <TextField 
                                    value={accountUsername}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account Username"
                                    onChange={(event) => setAccountUsername(event.target.value)}
                                />     
                            </FormControl>
                            <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                <TextField 
                                    value={accountURL}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account URL"
                                    onChange={(event) => setAccountURL(event.target.value)}
                                />     
                            </FormControl>
                        </Box> </>:
                        <div></div> 
                    }
                </Box>
                <Box className='button-box-position'>
                        <div className='button-position'>
                            <Button className='form-button' onClick={handleOpen} variant="contained">
                                <span className='button-text'>
                                    SUBMIT
                                </span> 
                            </Button>
                            <Button className='form-button' onClick={handleReset} variant="contained">
                                <span className='button-text'>
                                    RESET
                                </span>  
                            </Button>
                        </div>
                    </Box> 
            </Paper>   
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>  
        </Box>
    );
} 