import { useState, useEffect } from 'react'; 
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails'; 
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';   
import Chip from '@mui/material/Chip'; 
import MuiAccordion from '@mui/material/Accordion';
import OverviewOfGroups from './overviewOfGroups.js';
// import OverviewOfParticipants from './overviewOfParticipants.js';
// import OverviewOfSelectedParticipants from './overviewOfSelectedParticipants.js'; 
import TopActive100CommsInfo from './topActive100CommsInfo.js';
import Top100Hashtags from './top100Hashtags.js';
import Top100Urls from './top100Urls.js';
import Top100Domains from './top100Domains.js';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export default function StatisticsDisplay(props) { 
    const [groupCount, setGroupCount] = useState(0); 
    const [participantCount, setParticipantCount] = useState(1042);
    const [selectedParticipantCount, setSelectedParticipantCount] = useState(743);
    const [groupSelected, setGroupSelected] = useState({group_id: '', group_name: ''})
    const [expanded, setExpanded] = useState(true);
    
    /** Set the number of groups selected **/
    useEffect(() => { 
        setGroupCount(props.selected.length);  
    }, [props.selected]);
    
    
    /** Callback function fired when a specific group is clicked in OverviewOfGroups**/
    const handleGroupSelected = (group) => {  
        setGroupSelected(group);   
        setExpanded(true);
    }
    
    return (
        <Box bgcolor="white" sx={{ height: '64vh', border: 4, borderColor: 'white', borderRadius: '10px', marginTop: '0.5rem', overflow: 'auto', 
            '&::-webkit-scrollbar': {
                width: '0.5em',
                backgroundColor: '#D8D8D8',
                border: '1px solid #D8D8D8',
                borderRadius: '20px'
            },  
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#605958', 
                border: '1px solid #605958',
                borderRadius: '20px',
                
            }}}>
            <div className='content-header'>
                Metadata Information
            </div> 
            <div className='content-accordion'>   
                <Accordion elevation={0} disableGutters defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >   
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <div className='sub-header'>
                                Top Active 100 Comms Info
                            </div> 
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TopActive100CommsInfo filters={props.filters} tab={props.tab} dateCallback={props.dateCallback} filterCallback={props.filterCallback}/>
                    </AccordionDetails>
                </Accordion>  
                <Accordion elevation={0} disableGutters>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >   
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <div className='sub-header'>
                                Top 100 Extracted Hashtags
                            </div> 
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Top100Hashtags filters={props.filters} selected={props.selected} tab={props.tab} dateCallback={props.dateCallback} filterCallback={props.filterCallback}/>
                    </AccordionDetails>
                </Accordion> 
                 <Accordion elevation={0} disableGutters>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >   
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <div className='sub-header'>
                                Top 100 Extracted URLs
                            </div> 
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Top100Urls filters={props.filters} selected={props.selected} tab={props.tab} dateCallback={props.dateCallback} filterCallback={props.filterCallback}/>
                    </AccordionDetails>
                </Accordion> 
                <Accordion elevation={0} disableGutters>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >   
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <div className='sub-header'>
                                Top 100 Domains of Shared URLs
                            </div> 
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Top100Domains filters={props.filters} selected={props.selected} tab={props.tab} dateCallback={props.dateCallback} filterCallback={props.filterCallback}/>
                    </AccordionDetails>
                </Accordion> 
                <Accordion elevation={0} disableGutters>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >   
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <div className='sub-header'>
                                Overview of Groups/Pages Selected
                            </div>
                            <Chip label={groupCount.toLocaleString()} variant="outlined" size="small" />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <OverviewOfGroups callback={handleGroupSelected} filters={props.filters} selected={props.selected} tab={props.tab} dateCallback={props.dateCallback} filterCallback={props.filterCallback}/>
                    </AccordionDetails>
                </Accordion>  
            </div>        
        </Box> 
    );
}

/** TODO: DO NOT REMOVE - Participants' Information **/
// <Accordion elevation={0} disableGutters sx={{ visibility: "hidden" }}>
//     <AccordionSummary
//       expandIcon={<ArrowDropDownIcon />} 
//       sx={{ backgroundColor: '#f5f5f5' }}
//     >
//         <Box sx={{ display: 'flex', gap: '10px' }}> 
//             <div className='sub-header'>
//                 Overview of Participants' Information
//             </div> 
//             <Chip label={participantCount.toLocaleString()} variant="outlined" size="small" />
//         </Box>
//     </AccordionSummary>
//     <AccordionDetails>
//       <OverviewOfParticipants/>
//     </AccordionDetails>
// </Accordion>   
// {groupSelected.group_name !== "" && 
//     <Accordion elevation={0} disableGutters>
//         <AccordionSummary
//           expandIcon={<ArrowDropDownIcon />} 
//           sx={{ backgroundColor: '#f5f5f5' }}
//         >
//             <Box sx={{ display: 'flex', gap: '10px' }}> 
//                 <div className='sub-header'>
//                     Participants' of {groupSelected.group_name} Information
//                 </div> 
//                 <Chip label={selectedParticipantCount.toLocaleString()} variant="outlined" size="small" />
//             </Box>
//         </AccordionSummary>
//         <AccordionDetails>
//           <OverviewOfSelectedParticipants/>
//         </AccordionDetails>
//     </Accordion>
// }