import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider'; 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PushPinIcon from '@mui/icons-material/PushPin';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography"; 
import avatar from './avatar.jpg';
import fetch from "node-fetch"; 
import * as endpoints from '../../../endpoints.js';

export default function ChatDisplayPinList(props) { 
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [pinPairList, setPinPairList] = useState([]); // Store list of pin_id: es_record_id
    const [temp, setTemp] = useState(); // Temporarily save state of message when menu opened
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
      
    const fetchPinList = async (filter) => {
        const res = await fetch(endpoints.ELASTIC, { 
            headers: {
                authorizationToken: endpoints.AUTHORIZATION_TOKEN
            },
            method: 'POST',
            body: JSON.stringify({
                es_query: {
                    _source: [
                        ],
                    aggs: {},
                    index: "messages.*",
                    query: {
                        bool: {
                            filter: filter,
                            must: [],
                            must_not: [],
                            should: [], 
                        }
                    },
                    "size": 51,
                    "sort": [
                        {
                            "messageDatetime": {
                                "order": "desc",
                                "unmapped_type": "boolean"
                            }
                        }
                    ],
                }
            })   
        }).catch((err) => {
            console.log('POST call failed: ', err.message);
        }); 
        return new Promise((resolve, reject) => {
            res.json().then(body => {   
                let messages = body.hits?.hits; 
                messages = messages.map((message) => { return message._source });
                messages = messages.map((message) => 
                {   
                    let pin_id;
                    pinPairList.forEach((pair) => {
                        if (pair[message.es_id]) {
                            pin_id = pair[message.es_id]
                        }
                    }) 
                    let timestamp = new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(message.messageDatetime);
                    message.timestamp = timestamp; 
                    message.avatar = avatar;
                    message.pin_id = pin_id; 
                    return message;
                })  
                resolve(messages); 
            })
        })  
    }
    
    useEffect(() => {   
        setPinPairList(props.pinList);  
        const temp_arr = props.pinList.map((obj) => { 
            return {match_phrase: { es_id: Object.keys(obj).toString() }};
        });
        
        if (temp_arr.length > 0) { // If there are pinned messages
            const filter = [
                {
                  match_all: {}
                },
                {
                  match_all: {}
                },
                {
                  bool: {
                    should: temp_arr,
                    "minimum_should_match": 1
                  }
                }
            ];      
            let list = fetchPinList(filter); 
            list.then(res => {
                setCount(res.length); 
                let data = [];
                let bucket = [];
                for (let i = 0; i < res.length; i++) {
                    let message = res[i];
                    bucket.push(message);
                    console.log(message.messageDatetime)
                    if (i !== res.length - 1) {
                        let next_message = res[i+1]; 
                        let next_datestring = new Date(next_message.messageDatetime).toDateString();
                        let curr_datestring = new Date(message.messageDatetime).toDateString();
                        if (curr_datestring !== next_datestring) {
                            data.push({
                                date: new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short", day: "2-digit"}).format(new Date(parseInt(message.messageDatetime))),
                                content: bucket 
                            })
                            bucket = [];
                        } 
                    } else {
                        data.push({
                            date: new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short", day: "2-digit"}).format(new Date(parseInt(message.messageDatetime))),
                            content: bucket
                        })
                    }
                }  
                setList(data.reverse()); 
                props.loadingCallback({individual: true, team: true})
                setLoading(false)
            }); 
        } else {
            setList([]);
            props.loadingCallback({individual: true, team: true})
            setLoading(false);
        } 
    }, [props.pinList])  
    
    const unpinMessage = async (pin_type, pin_id) => {    
        let query = {
            pin_type: pin_type,
            id: pin_id,
            action_type: "delete"
        }  
        const res = await fetch(endpoints.SAVE_PIN_MESSAGE, { 
          headers: {
            authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify(query)   
        }).catch((err) => {  
            console.log('POST call failed: ', err.message);
        });  
        return new Promise((resolve, reject) => { 
            const temp_arr = temp.es_id.split("|"); 
            const id = temp_arr[temp_arr.length - 1] 
            res.json().then(body => {
                props.unpinCallback({
                    status: true,
                    id: id,
                    action: 'delete' 
                }); 
                resolve(body); 
            }).catch((err) => { 
                props.unpinCallback({
                    status: false,
                    id: id,
                    action: 'delete' 
                }) 
                console.log('unpinMessage error:', err)
            }) 
        })  
    }  
    
    const handleOpen = (event, x) => { 
        setTemp(x);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleScrollToMessage = () => { 
        props.pinCallback(temp);
        setAnchorEl(null);
    }
    const handleUnpinMessage = () => {  
        const es_id = temp.es_id;
        let pin_id;
        let pin_type;
        pinPairList.forEach((obj) => {
            if (obj[es_id]) {
                pin_id = obj[es_id];
                if (pin_id.includes("individual")) {
                    pin_type = "individual";
                } else if (pin_id.includes("team")) {
                    pin_type = "team";
                }
                return;
            }
        })  
        unpinMessage(pin_type, pin_id).then((res) => {});
        setAnchorEl(null);
    }
    
    if (loading) {
        return (
            <Box sx={{ padding: "5px 5px 0 5px" }}>
                <i>
                  Fetching pinned messages... 
                </i>
            </Box>    
        )        
    } else if (count === 0) {
        return (
            <Box sx={{ padding: "5px 5px 0 5px" }}>
                <i>
                  You have no pinned messages. 
                </i>
            </Box>    
        )
    }
     
    return (
        <Box sx={{ width: '100%', maxHeight: 500, bgcolor: 'background.paper' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', paddingTop: '8px' }}>
                <PushPinIcon sx={{paddingLeft: '5px', color: '#605958'}} />
                <Typography>
                    <strong> {count} Pinned Message(s) </strong>
                </Typography>
            </Box>
            <Divider sx={{ paddingTop: '8px'}} />
            {list.map((bucket) => {  
                const date = bucket.date;
                const data = bucket.content; 
                return (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", paddingTop: '10px'}}> 
                            <Chip label={date} size="small" variant="outlined" /> 
                        </Box> 
                        {data.map((x) => {
                            const display_name = x.senderDisplayName ? x.senderDisplayName : x.senderSelectorAccountId ? x.senderSelectorAccountId : "-";
                            const content = x.messageSnippet ? x.messageSnippet : x.extractedTranscription ? x.extractedTranscription : "No textual content available.";
                            const has_media = typeof x.mediaMimeType === "undefined"; 
                            return (
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <ListItem disablePadding key={x} disableGutters sx={{ "&.MuiListItem-root": { padding: 0 } }} onClick={(event) => handleOpen(event, x)}>
                                        <ListItemButton>
                                        <Box >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                                <Avatar alt={x.senderDisplayName} src={x.avatar} /> 
                                                <ListItemText 
                                                    disableTypography
                                                    primary={
                                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>  
                                                            <Typography style={{ fontWeight: "bold", width: 200, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{display_name}</Typography> 
                                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
                                                                {has_media ? <AttachFileIcon sx={{fontSize: '16px', marginLeft: '2px', color: 'grey' }}/> : <></>}
                                                                <Typography style={{ paddingLeft: "3px" }}>{x.timestamp}</Typography>    
                                                            </Box> 
                                                        </Box> 
                                                    } 
                                                    secondary={
                                                    <Tooltip key='uniqueKey' title={content}> 
                                                        <Typography style={{ WebkitLineClamp: "2", fontSize: '13px', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>{content}</Typography> 
                                                    </Tooltip>    
                                                    }  
                                                />
                                            </Box>
                                        </Box>
                                        </ListItemButton>
                                    </ListItem> 
                                </List>
                            )
                        })}
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={handleScrollToMessage}>Scroll to Pinned Message</MenuItem> 
                            <MenuItem onClick={handleUnpinMessage}>Unpin</MenuItem>
                        </Menu>
                    </> 
                ) 
            })}
        </Box>
    );    
}     