import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ChatIcon from '@mui/icons-material/Chat';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from "@mui/material/IconButton";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField'; 
import fetch from "node-fetch";
import { v4 as uuid } from "uuid";  
import './selectionColumn.css'; 
import * as endpoints from '../../endpoints.js';

const chat = ["WhatsApp", "WeChat", "Telegram"];
const social = ["Facebook", "Instagram", "Tiktok", "Twitter", "Weibo", "YouTube"] 

const SelectionColumn = (props) => {
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState([0]); 
    const [unfiltered, setUnfiltered] = useState([]); // Unfiltered meaning selectionColumn search bar is empty
    const itemsPerPage = 14
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [inputText, setInputText] = useState("");
    const [filtered, setFiltered] = useState([]);    
    
    const inputHandler = (e) => { 
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    }; 
    
    /** Fetch Data Logic **/
    useEffect(() => {   
        setChecked([0]);
        const applications = props.tab === "chat" ? chat : props.tab === "social" ? social : [];
        const getSelectionList = async () => {
            const res = await fetch(endpoints.ELASTIC, { 
                headers: {
                    authorizationToken: endpoints.AUTHORIZATION_TOKEN
                },
                method: 'POST',
                body: JSON.stringify({
                        es_query: {
                            _source: [
                    			"commsGuid",
                    			"commsName",
                    			"application"
                    		],
                    		aggs: {},
                    		index: "messages.*",
                    		query: {
                    		    bool: {
                    		        filter: props.filters,
                    		        must: [],
                		            must_not: [],
                		            should: [], 
                    		    }
                    		},
                    		size: 500,
                    		collapse: {
                    		    field: "commsName" // For cases where two groups have the same commsGuid e.g. 60128547857-1630642763@g.us
                    		},
                    		sort: [
                    		    {
                    		        messageDatetime: {
                    		            order: "desc",
                    		            unmapped_type: "boolean"
                    		        }
                    		    }
                    		],
                    		from: 0 
                        }
                    })   
            }).catch((err) => {
              console.log('POST call failed: ', err.message);
            });
            res.json().then(body => { 
                let arr = body.hits.hits; 
                let temp = []; 
                if (applications.length === 0) { 
                    arr.forEach((item) => temp.push(item._source)); 
                } else {
                    arr.forEach((item) => { if (applications.includes(item._source.application)) { temp.push(item._source) }});
                }
                setUnfiltered(temp);
                setFiltered(temp);
                setPageCount(Math.ceil(temp.length / itemsPerPage))
                setLoading(false);
            }); 
        } 
        getSelectionList(); 
    }, [props.filters, props.tab])
    
    /** Search Bar Logic **/
    // TODO: Handle different fonts e.g. "𝙁𝙧𝙞𝙚𝙣𝙙𝙨 𝙤𝙛 𝘿𝘼𝙋". Update: works for 𝒏͟𝒐́͟͟𝒖͟𝒓͟𝒂.
    useEffect(() => {  
        const data = unfiltered.filter((item) => {  
            if (inputText === "") {
                return item;
            } 
            else { 
                return item.commsName.toLowerCase().includes(inputText)
            } 
        })  
        setPageCount(Math.ceil(data.length / itemsPerPage))
        setPage(1);
        setFiltered(data);
    }, [inputText, unfiltered]) 
    
    const handleChange = (event, value) => {
        setPage(value);
    }; 
    
    const handleToggle = (value) => () => { 
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];  
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }   
        setChecked(newChecked);    
        props.callback(newChecked);
    };
    
    useEffect(() => {
        if (props.deselect.deselect) {
            setChecked([0]);
            props.callback([0]);
        }
    }, [props.deselect]) 
 
    return (
    <div className='left-column'>
        <TextField
          hiddenLabel
          placeholder="Search for a group or page name" 
          size="small"
          variant="filled"
          InputProps={{ disableUnderline: true }} 
          onChange={inputHandler}
          sx={{ width: '20vw', marginBottom: '1rem', "& .MuiFilledInput-root": { background: "white" }}} 
        />  
        { loading ? 
            (<div className="loader">
                <CircularProgress color="inherit"/>
            </div>) 
        : 
        ((inputText === '') || (inputText !== '' && filtered.length !== 0))?
            (<Box 
                bgcolor="white"
                sx={{  
                "& .MuiList-root": { paddingBottom: '14px' }, 
                "& .MuiListItemText-primary": { display: "block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"},
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                border: 4,
                borderColor: 'white',
                borderRadius: '10px',
                width: '20vw'
            }}>
                <List sx={{ 
                bgcolor: 'background.paper', 
                height: '59vh', 
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '0.5em',
                    backgroundColor: '#D8D8D8',
                    border: '1px solid #D8D8D8',
                    borderRadius: '20px' 
                }, 
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#605958', 
                    border: '1px solid #605958',
                    borderRadius: '20px'
                }}}>
                  {filtered.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((value) => {  
                    const labelId = `checkbox-list-label-${value}`;  
                    return (
                    <>
                        <ListItem key={value} > 
                            <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(value) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }} 
                                  onClick={handleToggle(value)}
                                />
                            </ListItemIcon>
                            <Chip size="small" label={value.application} style={{backgroundColor: (value.application === "WhatsApp" ? "#1eb79f" : 
                        value.application === "Telegram" ? "#32a7d9" : 
                        value.application === "WeChat" ? "#01ce0a" : 
                        value.application === "Facebook" ? "#3b5998" : 
                        value.application === "Instagram" ? "#c42c83" : 
                        value.application === "Tiktok" ? "black" : 
                        value.application === "Twitter" ? "#1c9be9" : 
                        value.application === "YouTube" ? "#c52f2a" : 
                        value.application === "XiaoHongShu" ? "#f62740" : 
                        value.application === "Weibo" ? "#f79530" :  
                        "grey"
                    )}} 
                                sx={{ marginRight: '10px', color: 'white', fontSize: '11px' }} 
                                onClick={()=>{props.filterCallback({type: "application", label: value.application, key: uuid().slice(0, 8)})}} /> 
                            <ListItemText id={labelId} primary={value.commsName} />  
                            <ListItemIcon>
                                <IconButton onClick={() => props.viewer({ application: value.application, group_name: value.commsName, group_id: value.commsGuid, date: new Date(), viewer: true, general: true })}>
                                  <ChatIcon />
                                </IconButton>
                            </ListItemIcon>
                      </ListItem>
                      <Divider component="li" />                
                    </> 
                    );
                  })}
                </List>
                <Box sx={{ display: 'flex', width: '20vw', flexDirection:'row', justifyContent: 'center', paddingBottom: '1rem', height: '4vh' }}>
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={handleChange}
                      defaultPage={1}   
                      size="small"  
                    />
                </Box>
            </Box>) 
            : 
            (<Box 
                bgcolor="white"
                sx={{  
                "& .MuiList-root": { paddingBottom: '14px' }, 
                "& .MuiListItemText-primary": { display: "block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"},
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', 
                height: '100%',
                border: 4,
                borderColor: 'white',
                borderRadius: '10px',
                width: '20vw'
            }}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', color: 'grey'}}>  
                    <i>No such results.</i> 
                </Box>
            </Box>) 
        } 
    </div>
 
  );
}

export default SelectionColumn;  