import { useState, useEffect } from 'react';  
import Box from '@mui/material/Box';   
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip'; 
import { v4 as uuid } from "uuid"; 

export default function GenerateDisplay(props) {  
    const [current, setCurrent] = useState([]);
    const [count, setCount] = useState(0); 
    const [groupList, setGroupList] = useState([]);
    
    useEffect(() => {   
        if (props.selected.length === 0 || props.selected === 0) {
            setCount(0); 
        } else {    
            setCount(props.selected.length-1)  
            setCurrent(props.selected); 
            let arr = [];
            for(let i = 1; i < props.selected.length; i++) {
                arr.push(props.selected[i].commsName);
            } 
            setGroupList(arr);
        }
    }, [props.selected])
    
    const handleOnClick = () => { 
        props.callback(current);    
    }
    
    return (
        <Box bgcolor="white" sx={{ height: '64vh', border: 4, borderColor: 'white', borderRadius: '10px', marginTop: '0.5rem', display: "flex", direction: "row", justifyContent: "center", alignItems: "center" }}> 
            <Box>
                {count === 0 ? 
                    <Box sx={{display: "flex", direction: "row", justifyContent: "center", alignItems: "center", paddingBottom: '0.5rem'}}> No item selected. Data will be generated based on Top 500 groups. </Box> : 
                    <Box sx={{display: "flex", direction: "row", justifyContent: "center", alignItems: "center", paddingBottom: '0.5rem'}}> {count} item(s) selected. </Box> 
                }
                <Box sx={{ marginRight: '5rem', marginLeft: '5rem' }}> 
                        {groupList.map((item) => { 
                            return (
                                <Chip label={item} sx={{ marginRight: '10px', marginBottom: '10px'}}/> 
                            ) 
                        })} 
                </Box>
                
                <Box sx={{display: "flex", direction: "row", justifyContent: "center", alignItems: "center", paddingTop: '0.2rem'}}>
                    <Button sx={{ marginBottom: '3px' }} className='form-button' onClick={handleOnClick} variant="contained">
                        <span className='button-text'>
                            Generate Data
                        </span>  
                    </Button>    
                </Box>
                <Box sx={{display: "flex", direction: "row", justifyContent: "center", alignItems: "center", paddingTop: '0.2rem'}}>
                    <Button sx={{ marginBottom: '3px' }} className='form-button' onClick={()=>{props.deselect({deselect: true, key: uuid().slice(0, 8)})}} variant="contained">
                        <span className='button-text'>
                            Unselect All
                        </span>  
                    </Button>    
                </Box>
                
            </Box> 
        </Box> 
    );
}