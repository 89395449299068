import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'aws-amplify/api'; 
import { Link } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';  
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';  
import FilterListIcon from '@mui/icons-material/FilterList';  

const columns = [
  { id: 'pk', 
    label: 'Ref. #', 
    align: 'left', 
    sortable: true,
  }, 
  { id: 'platform', 
    label: 'Platform', 
    align: 'left', 
    sortable: true,
  },
  { id: 'tasking_type', 
    label: 'Tasking Type', 
    align: 'left', 
    sortable: true,
  },
  { id: 'account_name', 
    label: 'Account Name', 
    align: 'left', 
    sortable: true,
  },
  { id: 'tasking_value', 
    label: 'Tasking Value', 
    align: 'left', 
    sortable: false,
  }, 
  { id: 'creator', 
    label: 'Creator', 
    align: 'left', 
    sortable: true,
  },
  { id: 'latest_status', 
    label: 'Latest Status', 
    align: 'left', 
    sortable: false,
  },
  { id: 'is_active', 
    label: 'Activation', 
    align: 'left', 
    sortable: false,
  }, 
];

const platforms = [
  "Facebook",
  "Instagram",
  "YouTube",
  "TikTok",
  "Twitter"
] 
   
const tasking_types = [ 
    "Hashtag", 
    "Keyword",
    "Account"
    ];  
    
const status = [
    true,
    false
  ]

function createData(pk, platform, tasking_type, account_name, tasking_value, creator, latest_status, is_active, date) {
    return { pk, platform, tasking_type, account_name, tasking_value, creator, latest_status, is_active, date };
}  

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'date') { 
    return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
  }
  
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow> 
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sx={{ fontSize: '16px'}} 
            align={column.align}
            padding='normal'
            sortDirection={orderBy === column.id ? order : false}
          > 
          {column.sortable ? 
            <TableSortLabel 
              active={orderBy === column.id} 
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)} 
            >
              <div className='table-cell'>
                {column.label}
              </div>
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> :  
            <div className='table-cell'>
              {column.label}
            </div>
          } 
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = { 
  onRequestSort: PropTypes.func.isRequired, 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};  

export default function ViewAllTaskingTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    // const [searchResults, setSearchResults] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [searchQuery, setSearchQuery] = useState('');
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');
    const [totalActivated, setTotalActivated] = useState(0);
    const [displayedActivated, setDisplayedActivated] = useState(0);   
    // Filter default shows all. Filter will only be applied when clicked on "Apply" 
    const [platformFilter, setPlatformFilter] = useState([]);
    const [taskingTypeFilter, setTaskingTypeFilter] = useState([]);
    const [activationFilter, setActivationFilter] = useState([]);
    const [selectedPlatformFilter, setSelectedPlatformFilter] = useState([]);
    const [selectedTaskingTypeFilter, setSelectedTaskingTypeFilter] = useState([]);
    const [selectedActivationFilter, setSelectedActivationFilter] = useState([]);
     
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const open = Boolean(anchorEl);
    
    const handleOpenFilter = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
      setAnchorEl(null); 
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    useEffect(() => {   
      const getData = async () => {
        try {
          const getOperation = get({ 
          apiName: 'gcctaskingapi',
          path: '/gcctasking' 
          });
          const { body } = await getOperation.response;
          const json = await body.json();
          const temp = []; 
          var activated = 0;  
          json.forEach(x => {
            if (x.delete) {
                return; // Skip item
            } else { 
                temp.push(createData(x.PK, x.platform, x.tasking_type, x.account_name, x.tasking_value, x.creator, x.latest_status, x.is_active, Date.parse(x.date_created))); 
                if (x.is_active) {
                  activated++
                }  
            } 
          })  
          setTotalActivated(activated); 
          setDisplayedActivated(activated);
          setRows(temp); 
          setData(temp); 
          setLoading(false);
        } catch (error) {
          console.log('GET call failed: ', error);
        }
      } 
      getData();   
    }, [totalActivated]) 
   
    // Search and filter logic
    useEffect(() => {  
      const filterEmpty = () => {
        return (platformFilter.length === 0 && taskingTypeFilter.length === 0 && activationFilter.length === 0);
      }      
      var temp = [];      
      if (searchQuery === "") {
        temp = data; 
      } else {  
        temp = data.filter((obj) => JSON.stringify(obj).toLowerCase().includes(searchQuery.toLowerCase()));  
      }
      const filtered = temp.filter((x) => {
        if (filterEmpty()) { // Default state
          return true;
        } else {
          return platformFilter.includes(x.platform) || taskingTypeFilter.includes(x.tasking_type) || activationFilter.includes(x.is_active);
        } 
      });
      var activated = 0;
      filtered.forEach(x => { if (x.is_active) {activated++;}} );
      setDisplayedActivated(activated); 
      setRows(filtered);
      setAnchorEl(null);  
      setPage(0); 
    }, [searchQuery, data, totalActivated, platformFilter, taskingTypeFilter, activationFilter]);
    
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }; 
    
    const visibleRows = useMemo(() =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [rows, order, orderBy, page, rowsPerPage],
    );
    
    const updateFilter = (checked, category, filter) => {
      if (checked) {
        if (category === "Platforms") {
          setSelectedPlatformFilter((previous) => Array.from(new Set(previous).add(filter)));
        } 
        if (category === "Tasking Types") {
          setSelectedTaskingTypeFilter((previous) => Array.from(new Set(previous).add(filter)));
        } 
        if (category === "Activation") {
          setSelectedActivationFilter((previous) => Array.from(new Set(previous).add(filter)));
        }
      }  
      if (!checked) {
        if (category === "Platforms") {
          setSelectedPlatformFilter((previous) => {
            const next = new Set(previous);
            next.delete(filter);
            return Array.from(next);
          });
        }
        if (category === "Tasking Types") {
          setSelectedTaskingTypeFilter((previous) => {
            const next = new Set(previous);
            next.delete(filter);
            return Array.from(next);
          });          
        }
        if (category === "Activation") {
          setSelectedActivationFilter((previous) => {
            const next = new Set(previous);
            next.delete(filter);
            return Array.from(next);
          });          
        }         
      }
    }
     
    const handleApplyFilters = () => {
      setPlatformFilter(selectedPlatformFilter);
      setTaskingTypeFilter(selectedTaskingTypeFilter);
      setActivationFilter(selectedActivationFilter);
    }
    
    const handleResetFilters = () => { 
      setSelectedPlatformFilter([]);
      setSelectedTaskingTypeFilter([]);
      setSelectedActivationFilter([]); 
    }
 
    if (loading) {
      return <div className="loader">
        <CircularProgress color="inherit"/>
      </div> 
    }  
    
    return (  
        <>
        <div className='search-bar-position'>
          <Box sx={{width: "30rem"}}> 
            <TextField 
              fullWidth 
              size="small"  
              className='search-bar'
              placeholder="Search..."
              autoComplete='off'
              onInput={(e) => { setSearchQuery(e.target.value); }} 
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box> 
          <Button className='form-button' sx={{ marginLeft: '1rem' }} variant="contained" onClick={handleOpenFilter} startIcon={<FilterListIcon />}>
            <span className='button-text'>
                FILTERS
            </span>  
          </Button> 
          <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseFilter}
              sx={{marginTop: '0.5rem'}}
            >  
              <div className='filter-header'><strong>Platform</strong></div> 
              <Grid container> 
              {platforms.map((platform) => {
                return (
                  <Grid item xs={4}>
                    <span><Checkbox checked={selectedPlatformFilter.includes(platform)} onChange={(e) => updateFilter(e.target.checked, "Platforms", platform)}/>{platform}</span>
                  </Grid>
                )
              })}</Grid>
              <div className='filter-header'><strong>Tasking Type</strong></div> 
              <Grid container> 
              {tasking_types.map((tasking_type) => {
                return (
                  <Grid item xs={4}>
                    <span><Checkbox checked={selectedTaskingTypeFilter.includes(tasking_type)} onChange={(e) => updateFilter(e.target.checked, "Tasking Types", tasking_type)}/>{tasking_type}</span>
                  </Grid>
                )
              })}
              </Grid>
              <div className='filter-header'><strong>Activation</strong></div> 
              <Grid container> 
              {status.map((state) => {
                return (
                  <Grid item xs={4}>
                    <span><Checkbox checked={selectedActivationFilter.includes(state)} onChange={(e) => updateFilter(e.target.checked, "Activation", state)}/>{state ? "Activated" : "Deactivated" }</span>
                  </Grid>
                )
              })}</Grid> 
              <Box sx={{display: "flex", justifyContent: 'center'}}>
                <Button sx={{marginTop: '1rem', marginBottom: '1rem'}} className='form-button' variant="contained" onClick={handleApplyFilters}>
                  <span className='button-text'>
                    APPLY
                  </span>
                </Button> 
                <Button sx={{marginTop: '1rem', marginLeft: '1rem', marginBottom: '1rem'}} className='form-button' variant="contained" onClick={handleResetFilters}>
                  <span className='button-text'>
                    RESET
                  </span>
                </Button> 
              </Box>
            </Menu>          
        </div>
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}> 
          <TableContainer sx={{ maxHeight: 'auto' }}>
            <Table sx={{ tableLayout: 'fixed'}} stickyHeader aria-label="sticky table"> 
              <colgroup>
                  <col width="10%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="11%" /> 
                  <col width="35%" />
                  <col width="10%" /> 
                  <col width="10%" />
                  <col width="8%" />
              </colgroup> 
              <EnhancedTableHead 
                order={order}
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows 
                  .map((row) => {
                    return (
                      <Tooltip 
                        placement="bottom"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}
                        title="Click to view">
                      <TableRow className='table-cell' tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                            let value;
                            if (typeof row[column.id] === "boolean") {
                              value = row[column.id].toString();
                            } else {
                              value = row[column.id];
                            } 
                            return (
                              <TableCell className='table-cell' hover="true" component={Link} to={`/social-media-tasking/view/${row.pk}/`} sx={{ fontSize: '14px'}} key={column.id} align={column.align}> 
                                <div className='table-cell'>
                                 {value}
                                </div>
                              </TableCell>
                            ); 
                        })} 
                      </TableRow>  
                      </Tooltip>
                    );
                  })}
              </TableBody> 
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />  
          <div className='search-results'>
            Total Activated: {displayedActivated}
          </div>
        </Paper>
        {(searchQuery !== "") && 
        <div className='search-results'>
          {rows.length} record(s) found.
        </div>}  
      </>
  );
}  