import React, { useState } from 'react'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';    
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';  
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TextField from '@mui/material/TextField';   
import { Link } from "react-router-dom";  

export default function ViewAdhocTaskingForm(props) {  
    const [copied, setCopied] = useState({
        hashtag: false,
        keyword: false,
        account_id: false,
        account_name: false,
        account_username: false,
        account_url: false
    }); 
  
    const handleCopy = (value) => {  
        global.navigator.clipboard.writeText(value);
         
        if (value === props.data.hashtag) {
            setCopied({
                hashtag: true,
                keyword: false,
                account_id: false,
                account_name: false,
                account_username: false,
                account_url: false
            });    
        }
        
        if (value === props.data.keyword) {
            setCopied({
                hashtag: false,
                keyword: true,
                account_id: false,
                account_name: false,
                account_username: false,
                account_url: false
            });            
        }
        
        if (value === props.data.account_id) { 
            setCopied({
                hashtag: false,
                keyword: false,
                account_id: true,
                account_name: false,
                account_username: false,
                account_url: false
            });            
        } 
        
        if (value === props.data.account_name) {
            setCopied({
                hashtag: false,
                keyword: false,
                account_id: false,
                account_name: true,
                account_username: false,
                account_url: false
            });            
        }
        
        if (value === props.data.account_username) {
            setCopied({
                hashtag: false,
                keyword: false,
                account_id: false,
                account_name: false,
                account_username: true,
                account_url: false
            });            
        }

        if (value === props.data.account_url) {
            setCopied({
                hashtag: false,
                keyword: false,
                account_id: false,
                account_name: false,
                account_username: false,
                account_url: true
            });            
        }
        
        setTimeout(() => {
            setCopied({
                hashtag: false,
                keyword: false,
                account_id: false,
                account_name: false,
                account_username: false,
                account_url: false
            });
        }, 4000);
    }  
    
    return (
        <Box className='table-position'>  
            <Paper elevation={0} className='table'> 
                    <Box> 
                        <div className='table-header'>
                            Platform
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                            <FormControl fullWidth> 
                                <TextField  
                                  value={props.data.platform} 
                                  size="small" 
                                  disabled={true}
                                > 
                                </TextField>
                            </FormControl>
                        </Box>
                        <div className='table-header'>
                            Tasking Type
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                            <FormControl fullWidth> 
                                <TextField  
                                  value={props.data.tasking_type} 
                                  size="small" 
                                  disabled={true}
                                > 
                                </TextField>
                            </FormControl>
                        </Box>
                        {(props.data.tasking_type === 'Hashtag') ?
                            <><div className='table-header'>
                                Tasking Value
                            </div> 
                            <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                                <FormControl fullWidth> 
                                    <TextField 
                                        value={props.data.hashtag}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Hashtag" 
                                        disabled={true}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end"> 
                                                {copied.hashtag ?  
                                                    <IconButton disabled>
                                                        <TaskAltIcon />  
                                                    </IconButton>
                                                :
                                                <IconButton onClick={() => handleCopy(props.data.hashtag)} > 
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                }
                                            </InputAdornment>
                                          ),
                                        }}
                                    />       
                                </FormControl>
                            </Box> </>:
                            <div></div> 
                        }
                        {(props.data.tasking_type === 'Keyword') ?
                            <><div className='table-header'>
                                Tasking Value
                            </div> 
                            <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                                <FormControl fullWidth>                          
                                    <TextField 
                                        value={props.data.keyword}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Keyword" 
                                        disabled={true}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end"> 
                                                {copied.keyword ?  
                                                    <IconButton disabled>
                                                        <TaskAltIcon />  
                                                    </IconButton>
                                                :
                                                <IconButton onClick={() => handleCopy(props.data.keyword)} > 
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                }
                                            </InputAdornment>
                                          ),
                                        }}                                        
                                    />     
                                </FormControl>
                            </Box> </>:
                            <div></div> 
                        }
                        {(props.data.tasking_type === 'Account') ?
                            <><div className='table-header'>
                                Tasking Value
                            </div> 
                            <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                                <FormControl fullWidth> 
                                    <TextField 
                                        value={props.data.account_id}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account ID"
                                        disabled={true}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end"> 
                                                {copied.account_id ?  
                                                    <IconButton disabled>
                                                        <TaskAltIcon />  
                                                    </IconButton>
                                                :
                                                <IconButton onClick={() => handleCopy(props.data.account_id)} > 
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                }
                                            </InputAdornment>
                                          ),
                                        }}
                                    />     
                                </FormControl>
                                <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                    <TextField 
                                        value={props.data.account_name}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account Name"
                                        disabled={true}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end"> 
                                                {copied.account_name ?  
                                                    <IconButton disabled>
                                                        <TaskAltIcon />  
                                                    </IconButton>
                                                :
                                                <IconButton onClick={() => handleCopy(props.data.account_name)} > 
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                }
                                            </InputAdornment>
                                          ),
                                        }}
                                    />     
                                </FormControl>
                                <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                    <TextField 
                                        value={props.data.account_username}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account Username"
                                        disabled={true}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end"> 
                                                {copied.account_username ?  
                                                    <IconButton disabled>
                                                        <TaskAltIcon />  
                                                    </IconButton>
                                                :
                                                <IconButton onClick={() => handleCopy(props.data.account_username)} > 
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                }
                                            </InputAdornment>
                                          ),
                                        }}
                                    />     
                                </FormControl>
                                <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                    <TextField 
                                        value={props.data.account_url}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account URL"
                                        disabled={true} 
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end"> 
                                                {copied.account_url ?  
                                                    <IconButton disabled>
                                                        <TaskAltIcon />  
                                                    </IconButton>
                                                :
                                                <IconButton onClick={() => handleCopy(props.data.account_url)} > 
                                                    <ContentCopyIcon />
                                                </IconButton>
                                                }
                                            </InputAdornment>
                                          ),
                                        }}
                                    />     
                                </FormControl>
                            </Box> </>:
                            <div></div> 
                        }
                    </Box> 
            </Paper>   
            <Box>
                <div className='button-position'>
                    <Button sx={{ marginBottom:'1rem'}} className='form-button' variant="contained" component={Link} to="/social-media-urgent-tasking/view" startIcon={<ArrowBackIcon />}>
                        <span className='button-text'>
                            BACK
                        </span> 
                    </Button> 
                </div>
            </Box>
        </Box>
    );
}
   