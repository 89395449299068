import { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, getGridStringOperators } from '@mui/x-data-grid'; 
import Box from "@mui/material/Box"; 
import {format} from 'date-fns'
import fetch from "node-fetch"; 
import Typography from "@mui/material/Typography";
import ClickableTypography from '../clickableTypography.js';
import * as endpoints from '../../../endpoints.js';

const renderDate = (date) => {
 if (!date) {
   return '';
 } 
 return format(new Date(date), 'Pp');
}

const filterOperators = getGridStringOperators().filter(({ value }) => 
  ['equals', 'contains'].includes(value),
);  
 
function CustomToolbar() {
  return (
    <GridToolbarContainer> 
      <GridToolbarFilterButton /> 
      <Box sx={{ flexGrow: 1 }} /> 
    </GridToolbarContainer>
  );
} 
 
export default function TopActive100CommsInfo(props) { 
  const [rows, setRows] = useState([]);
  const columns = [   
    {
      field: "group_id",
      headerName: "Group ID",
      minWidth: 50,
      flex: 1,
      sortable: true,
      filterOperators,
      renderCell: (params) => ( 
        <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1em"}}>
          { params.value !== "NA" ? <ClickableTypography css="typography-group" type="commsGuid" value={params.value} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography> }  
        </Box> 
      )
    },
    {
      field: "group_name",
      headerName: "Group Name",
      minWidth: 100,
      flex: 1,
      sortable: true,
      filterOperators,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1em"}}>
          { params.value !== "NA" ? <ClickableTypography css="typography-group" type="commsName" value={params.value} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography> }  
        </Box>          
      )
    },
    {
      field: "platform",
      headerName: "Platform",
      minWidth: 30,
      flex: 1,
      sortable: true, 
      filterable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1em"}}>
          { params.value !== "NA" ? <ClickableTypography css="typography-single" type="application" value={params.value} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography> }  
        </Box>   
      )
    }, 
    {
      field: "message_count",
      headerName: "# Messages",
      minWidth: 20,
      flex: 1,
      sortable: true, 
      filterable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className='typography'> {params.value} </div>
      )
    }, 
    {
      field: "sender_count",
      headerName: "# Senders",
      minWidth: 20,
      flex: 1,
      sortable: true, 
      filterable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className='typography'> {params.value} </div>
      )
    }, 
    {
      field: "participant_count",
      headerName: "# Participants",
      minWidth: 20,
      flex: 1, 
      sortable: true, 
      filterable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className='typography'> {params.value} </div>
      )
    },
    {
      field: "hashtag_count",
      headerName: "# Hashtags",
      minWidth: 20,
      flex: 1,
      sortable: true, 
      filterable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className='typography'> {params.value} </div>
      )
    },
    {
      field: "url_count",
      headerName: "# URLs",
      minWidth: 20,
      flex: 1,
      sortable: true, 
      filterable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className='typography'> {params.value} </div>
      )
    }, 
    {
      field: "last_active",
      headerName: "Last Active",
      minWidth: 50,
      flex: 1,
      sortable: true, 
      type: "date", 
      renderCell: (params) => (
        <div sx={{lineHeight: '2.5 !important'}} className='typography-single' onClick={() => props.dateCallback(params.value)}> {renderDate(params.value)} </div> 
      )
    },
  ];  
  const [loading, setLoading] = useState(true);
   
  useEffect(() => {       
    let content_filters = props.filters; 
    const getGroupStatistics = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
            es_query: {
              _source: [],
              index: "messages.*",
              aggs: {
                2: {
                  terms: {
                    field: "commsGuid",
                    order: {
                      1: "desc"
                    },
                    size: 100
                  },
                  aggs: {
                    1: {
                      cardinality: {
                        field: "unitId"
                      }
                    },
                    3: {
                      terms: {
                        field: "commsName",
                        order: {
                          1: "desc"
                        },
                        missing: "__missing__",
                        size: 10
                      },
                      aggs: {
                        1: {
                          cardinality: {
                            field: "unitId"
                          }
                        },
                        8: {
                          terms: {
                            field: "application",
                            order: {
                              1: "desc"
                            },
                            size: 20
                          },
                          aggs: {
                            1: {
                              cardinality: {
                                field: "unitId"
                              }
                            },
                            2: {
                              cardinality: {
                                field: "senderSelectorAccountId"
                              }
                            },
                            3: {
                              max: {
                                field: "numberOfCommsMembers"
                              }
                            },
                            4: {
                              sum: {
                                field: "numberOfHashtags"
                              }
                            },
                            5: {
                              sum: {
                                field: "numberOfUrlLinks"
                              }
                            },
                            6: {
                              max: {
                                field: "messageDatetime"
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                }
              },
              size: 0,
              stored_fields: [
                "*"
              ],
              script_fields: {},
              query: {
                bool: {                    		        
                  filter: content_filters,
              		must: [],
                  should: [],
                  must_not: []
                }
              }
            }
          })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });
      res.json().then(body => { 
        let agg = body?.aggregations; 
        let arr = agg ? (agg["2"] ? agg["2"].buckets : []) : [];
        let id_count = 0;
        let temp_arr = []; 
        arr.forEach((x) => {  
          if(x.key === "NA") {}
          let group_id = x.key;
          const buckets = x["3"].buckets;
          buckets.forEach((y) => {
            let name = y.key;
            if(group_id === "NA") {}
            let metadata = y["8"].buckets[0];
            let platform = metadata.key;
            id_count++;
            temp_arr.push({
              id: id_count,
              group_id: group_id,
              group_name: name,
              platform: platform,
              message_count: metadata["1"].value,
              sender_count: metadata["2"].value,
              participant_count: metadata["3"].value ? metadata["3"].value : "-",
              hashtag_count: metadata["4"].value,
              url_count: metadata["5"].value,
              last_active: new Date(metadata["6"].value)
            }) 
          })   
        }) 
        setRows(temp_arr);  
        setLoading(false);
      });
    }
    getGroupStatistics(); 
    }, [props.filters])
  
  if (loading) {
    return <div> Loading... </div>
  }  
  
  return (
    <> 
      <div style={{ height: '100%', width: "300"}}>
        <DataGrid   
          getRowHeight={() => 'auto'}
          autoHeight 
          disableRowSelectionOnClick 
          disableColumnResize 
          disableColumnMenu
          slots={{ toolbar: CustomToolbar }}
          rows={rows} 
          columns={columns}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
               outline: "none !important",
            }, 
            ".MuiButton-root": {
              color: "#605958"
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "rgba(218,218,218,0)", 
            },
            "& .MuiDataGrid-cell": {
              lineHeight: "3.5em !important"
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'message_count', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 50, 100]}
        />
      </div>  
    </>
  );
} 