import { useState, useEffect, useRef } from 'react'; 
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChatDisplayAppBar from './chatDisplayAppBar.js';
import ClickableTypography from '../clickableTypography.js';
import ContentCopy from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from '@mui/material/Paper';
import PushPinIcon from '@mui/icons-material/PushPin';
import ReplyIcon from '@mui/icons-material/Reply';
import Snackbar from '@mui/material/Snackbar';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import StarsIcon from '@mui/icons-material/Stars';
import WorkIcon from '@mui/icons-material/Work';
import { ReactPhotoCollage } from "react-photo-collage"; 
import avatar from './avatar.jpg'; 
import {format} from 'date-fns';
import fetch from "node-fetch";
import { v4 as uuid } from "uuid";  
import './chatDisplay.css';
import * as endpoints from '../../../endpoints.js';

/** DO NOT REMOVE: MESSAGE FORMAT; THIS IS FOR ONE MESSAGE **/ 
// [
//   {
//     content: "This is a text message" or [{source: "url_one"}, {source: "url_two"}],
//     caption: "" // Only for media
//     timestamp: ,
//     hit: , // True if it is the hit message clicked on
//     type: ,// "text", "image", "video/mp4 (thumbnail poster)", "audio/mp3/ogg", "pdf/gif",
//     transcription: ,
//   }
// ] 

const renderDate = (date) => {
  if (!date) {
    return '';
  } 
  return format(new Date(date), 'dd LLL yyyy');
}  
  
function Formatter({value, type, caption, root, transcription}) { 
  console.log("value: ", value)
  const supported_types = ["image", "video", "audio", "gif", "text"];
  const [setting, setSetting] = useState({
      width: "18vw",
      height: ["10vw", "0vw"],  
      layout:  [1],
      photos: [],
      showNumOfRemainingPhotos: true
    }
  ); 
  const [singleUrl, setSingleUrl] = useState(""); // For single videos/audios
  const [loading, setLoading] = useState(true);  
//   const [mediaUnavailable, setMediaUnavailable] = useState(false);
//   const [mediaUnsupported, setMediaUnsupported] = useState(false); 
    
  const fetchMediaUrl = (filePath) => {
    const endpoint = endpoints.FILES + "?filepath=" + encodeURIComponent(filePath); 
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.open("GET", endpoint);   
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*"); 
      xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,access-control-allow-origin,access-control-allow-methods,access-control-allow-headers,auth-token,Access-Control-Allow-Origin,GET,OPTIONS,Origin,X-Auth-Token,authorizationToken")
      xhr.setRequestHeader("authorizationToken", "hunny-allow-1713316693000"); 
      xhr.send(); 
      xhr.onload = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {  
          console.log("res", xhr.response);
          resolve(xhr.response);
        } else { 
          console.log("Error:" + filePath); 
          console.log("Error: " + xhr.status); 
        }
      }    
    })
  }  
    
  const loadMedia = (value, type) => { 
    setLoading(true);
    if (type === "image") { 
      let temp_photos = [];
      temp_photos = value?.map((media_url) => fetchMediaUrl(media_url));  
      Promise.all(temp_photos).then((res) =>    
          res.map((media_url) => {  
            return {
              source: media_url
            }
          }) 
      )
      .then((res) => { 
        let len = value.length;   
        if (root) {
          setSetting({
            width: len > 6 ? "calc(6 * 150px)" : `calc(${len} * 150px)`,
            height: ["150px", "150px"], 
            layout: [6],
            photos: res,
            showNumOfRemainingPhotos: true
          })   
        } else {
            setSetting({
              width: "18vw",
              height: len === 1 ? ["10vw", "0vw"] : len === 2 ? ["10vw", "0vw"] : ["10vw", "10vw"],  
              layout: len === 1 ? [1] : len === 2 ? [2] : [1, 2],
              photos: res,
              showNumOfRemainingPhotos: true
          }) 
        } 
        setLoading(false); 
      })  
    } else if (type === "video" || type === "audio" || type === "gif") { 
      const url = value[0]; 
      fetchMediaUrl(url).then((res) => { setSingleUrl(res); setLoading(false);});  
    }
  }
  
  const downloadMedia = (value) => { 
    let temp = value.map((media_url) => fetchMediaUrl(media_url));  
    Promise.all(temp).then((res) =>    
      res.forEach((media_url) => {window.open(media_url, media_url)}) 
    )  
  } 

  useEffect(() => { 
    const media_types = ["image", "video", "audio", "gif"];
    if (media_types.includes(type)) {
      if (value.length !== 0) {
        loadMedia(value, type)
      }
    }
    if (type === "text") {
      setLoading(false);
    }
  }, [value, type])  
   
  if (!supported_types.includes(type)) {
    return <div className='message-content'> <i> [{type?.charAt(0).toUpperCase() + type?.slice(1)} is currently unsupported]  </i> </div>; 
  }
  
  if (value.length === 0) {
    return <div className='message-content'> <i> [{type?.charAt(0).toUpperCase() + type?.slice(1)} is currently unavailable]  </i> </div>; 
  }   
  
  
  if (type === "text") {
    return <div className='message-content'> {value} </div>;  
  } else if (type === "image") {  
    if (loading) {
      return <div className="loader"><CircularProgress size="1rem" color="inherit"/></div>
    }  
    return (
        <>
          <div className='message-media'><ReactPhotoCollage {...setting} /></div>
          {root ? <div className='root-message-caption'> {caption} </div> : <div className='message-caption'> {caption} </div> }    
          <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => loadMedia(value, type)} component={Link}> Refresh Media </a>  
          <span> | </span>
          <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => downloadMedia(value)} component={Link}> Download Media </a>  
        </>
    );  
  } else if (type === "video" || type === "audio" || type === "gif") { 
    if (loading) {
      return <div className="loader"><CircularProgress size="1rem" color="inherit"/></div>
    }  
    if (type === "video") {   
      return (
        <div className='message-media'> 
          <video width="300" height="400" controls src={singleUrl}> 
          </video>
          {root ? <div className='root-message-caption'> {caption} </div> : <div className='message-caption'> {caption} </div> }  
          {transcription ? <div className='root-message-caption'><i>[Extracted Transcription] {transcription.toString()}</i></div> : <></> }
          <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => loadMedia(value, type)} component={Link}> Refresh Media </a>
          <span> | </span>
          <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => downloadMedia(value)} component={Link}> Download Media </a>
        </div> 
      )    
    } else if (type === "audio") {
      return (
        <div className='message-media'>
          <audio src={singleUrl} controls>  
          </audio>
          {transcription ? <div className='root-message-caption' ><i>[Extracted Transcription] {transcription.toString()}</i></div> : <></> }
          <div >
             <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => loadMedia(value, type)} component={Link}> Refresh Media </a>
          <span> | </span>
          <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => downloadMedia(value)} component={Link}> Download Media </a>
          </div> 
        </div>
      )
    } else if (type === "gif") {
      // console.log("singleUrl", singleUrl)
      return (
        <div className='message-media'>
          <img src={singleUrl} width={250} height={250}>  
          </img>
          <div >
             <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => loadMedia(value, type)} component={Link}> Refresh Media </a>
          <span> | </span>
          <a className={root ? "media-refresh-link-post" : "media-refresh-link"} onClick={() => downloadMedia(value)} component={Link}> Download Media </a>
          </div> 
        </div>
      ) 
    }  
  }
} 

function ReplyFormatter({parent_id, group_id, response_type}) { 
  const [reply, setReply] = useState({})
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    const filter = [
      {
        match_all: {}
      },
      {
        match_phrase: {
          commsGuid: group_id
        }
      },
      {
        match_phrase: {
          messageId: parent_id
        }
      },  
    ];  
    const getReply = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
            es_query: {
              _source: [ 
                "messageId",
                "message",
                "messageResponseType",
                "messageDatetime",
                "senderDisplayName",
                "senderSelectorAccountId",
                "senderSelectorUsername",
                "commsName",
                "commsGuid",
                "numberOfShares",
                "mediaType",
                "mediaMimeType",
                "spocS3MediaUrl",
                "spocS3MediaUrl_list",
                "rootMessageId", 
                "parentMessageId"
              ],
          	  aggs: {},
          		index: "messages.*",
          		query: {
          		  bool: {
      		        filter: filter,
      		        must: [],
  		            must_not: [],
  		            should: [], 
          		  }
          		},
          		size: 500, 
          		sort: [
          		  {
      		        messageDatetime: {
    		            order: "desc",
    		            unmapped_type: "boolean"
    		          }
      		      }
          		]
            }
          })   
        }).catch((err) => {
          console.log('POST call failed: ', err.message);
        });  
      res.json().then(body => {   
        // TODO: Fix duplicate messageId 
        // console.log(body.hits?.hits[0]?._source);
        if (body.hits?.hits[0]?._source) {
          let data = body.hits.hits[0]._source;   
          const reply = {
            content: data.mediaMimeType ? (data.spocS3MediaUrl_list ? data.spocS3MediaUrl_list : data.spocS3MediaUrl_list ? [data.spocS3MediaUrl] : []) : data.message,
            caption: data.mediaMimeType ?  data.message : "",
            timestamp: new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(data.messageDatetime),
            hit: false,
            id: data.messageId,
            type: data.mediaMimeType ? 
              (
                (data.mediaMimeType.toLowerCase().startsWith("image") || data.mediaMimeType.toLowerCase().startsWith("photo")) ? 
                  "image" :
                data.mediaMimeType.toLowerCase().startsWith("video") ? 
                  "video" :
                data.mediaMimeType.toLowerCase().startsWith("audio") ?   
                  "audio" :
                  data.mediaMimeType.toLowerCase()
              ) : "text",
            sender_name: data.senderDisplayName ? data.senderDisplayName : "",
            sender_id : data.senderSelectorAccountId ? data.senderSelectorAccountId : ""
          }  
          setReply(reply);
          setLoading(false);
        } else {
          console.log("Error fetching reply"); 
        } 
      }); 
    }
    getReply();
  }, []) 
  
  if (loading) {
    return <i> Reply loading... </i>
  }
  
  return (
    <Paper variant="outlined" sx={{ borderLeft: 7, borderTop: 0, borderBottom: 0, borderRight: 0 }} className='reply-message'>  
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
        {reply.sender_name ?  
        <div >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <ReplyIcon sx={{color: "white"}} size="small"/>
            <span onClick={() => console.log(reply.sender_name)} className='message-username'> 
              {reply.sender_name}  
            </span> 
            <span onClick={() => console.log(reply.sender_id)} className='message-sender-id'> 
              ({reply.sender_id})   
            </span> 
            
          </Box>
        </div>  
        : <></>
        }
      </Box>
      <Formatter value={reply.content} type={reply.type} caption={reply.caption} root={false} />  
    </Paper> 
  ); 
}

export default function ChatDisplay(props) {   
    const [hitMessageInfo] = useState(
      {
        hit_message_id: props.info.message_id,
        hit_message_date: props.info.date,
        group_id: props.info.group_id,
        group_name: props.info.group_name
      }
    )  
    const [loadMessageInfo, setLoadMessageInfo] = useState(
      {
        message_id: props.info.message_id,
        message_date: props.info.date,
        group_id: props.info.group_id,
        group_name: props.info.group_name
      }
    ) 
    const [firstMessageId, setFirstMessageId] = useState('');
    const [lastMessageId, setLastMessageId] = useState('');
    const [scrollToId, setScrollToId] = useState({
      id: "",
      rand: uuid().slice(0, 4) // To ensure re-rendering of component even when id is the same
    }); // messageId string
    const [current, setCurrent] = useState([]);
    const [beforeButton, setBeforeButton] = useState(false);
    const [afterButton, setAfterButton] = useState(false); 
    const [timestampBefore, setTimestampBefore] = useState();
    const [timestampAfter, setTimestampAfter] = useState(); 
    const [data, setData] = useState([]);  
    const scrollToRef = useRef(null);  
    const [open, setOpen] = useState(false); 
    const [senderList, setSenderList] = useState([]);
    const [displayRootMessage, setDisplayRootMessage] = useState(); 
    const [loading, setLoading] = useState(true);
    const [pinList, setPinList] = useState([]);
    const actions = [
      { icon: <StarsIcon />, name: 'Scroll To Hit Message', operation: 'hit' },
      { icon: <KeyboardDoubleArrowDownIcon />, name: 'Scroll To Last Message', operation: 'bottom' },
      { icon: <KeyboardDoubleArrowUpIcon />, name: 'Scroll To First Message', operation: 'top' }, 
    ];   
    
    useEffect(() => {
      const temp = [];
      data.forEach((bucket) => {
        let messages = bucket.content;
        messages.forEach((message) => {
          const name = message.name;
          const username = message.username;
          const avatar = message.avatar;
          if (!temp.find(x => x.name === name && x.username === username)) {
            temp.push({name: name, username: username, avatar: avatar});   
          } 
        })
      }) 
      setSenderList(temp);
    }, [data])
    
    /** Load More: Before Date Range **/
    const generateBeforeRange = (date) => {  
      var copied = new Date(date.getTime()); 
      var month_before_date = copied.setMonth(copied.getMonth() - 2) // 2 months before
      return({
        messageDatetime: {
          gte: month_before_date,
          lte: Date.parse(date)
        }
      }) 
    }
    
    /** Load More: After Date Range **/
    const generateAfterRange = (date) => {  
      var copied = new Date(date.getTime()); 
      var month_after_date = copied.setMonth(copied.getMonth() + 2) // 2 months after
      return({
        messageDatetime: {
          gte: Date.parse(date),
          lte: month_after_date
        }
      }) 
    }   
     
    const reply_under_thread = props.info.message_type === "reply" && props.info.root_id !== props.info.parent_id; // Reply under a post-comment thread
    const comment_under_thread = props.info.message_type === "comment"; // Comment under a post-comment thread
    const post_under_thread = props.info.message_type === "post"; // Post under a post-comment thread 
     
    const get50Messages = async (range, is_before) => { 
      let filter = [
        {
          match_all: {}
        },
        {
          match_phrase: {
            commsName: loadMessageInfo.group_name // Instagram bug - to be fixed on backend data
          }
        },
        {
          match_phrase: {
            commsGuid: loadMessageInfo.group_id
          }
        },
        {
          range: range
        }
      ];      
      if (props.info.root_id) {
        if (reply_under_thread || comment_under_thread || post_under_thread) {
          filter.push(
            {
              match_phrase: {
                rootMessageId: props.info.root_id
              }
            }
          ) 
        }
      }  
      
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
            authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
                  es_query: {
                    _source: [
                      "application",
                      "messageId",
                      "message",
                      "messageResponseType",
                      "messageDatetime",
                      "senderDisplayName",
                      "senderSelectorAccountId",
                      "senderSelectorUsername",
                      "numberOfShares",
                      "mediaType",
                      "mediaMimeType",
                      "spocS3MediaUrl",
                      "spocS3MediaUrl_list",
                      "rootMessageId", 
                      "parentMessageId",
                      "es_id",
                      "commsUrl",
                      "extractedTranscription"
                    ],
                		aggs: {},
                		index: "messages.*",
                		query: {
                		    bool: {
                    		    filter: filter,
                		        must: [],
            		            must_not: [],
            		            should: [], 
                		    }
                		},
                		"size": 51,
                    "sort": [
                      {
                        "messageDatetime": {
                          "order": is_before ? "desc" : "asc",
                          "unmapped_type": "boolean"
                        }
                      }
                    ],
                  }
              })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });  
      return new Promise((resolve, reject) => {
        res.json().then(body => {
          let hits = body.hits.hits;
          if (is_before) {
            hits = hits.reverse(); 
          }      
          let temp = [];
          let temp_messages = [];  
        
          for (let i = 0; i < hits.length; i++) {
            const data = hits[i]._source;  
            const sender_name = data.senderDisplayName;
            const sender_username = data.senderSelectorUsername;
            const sender_id = data.senderSelectorAccountId; 
            const date = new Date(parseInt(data.messageDatetime)); 
            const datestring = date.toDateString(); // e.g. "Tue 22 Oct 2024" typeof x.messageDatetime == "string" ? new Date(parseInt(x.messageDatetime)) : new Date(x.messageDatetime) 
            const response_type = data.messageResponseType ? data.messageResponseType : ""; 
            const parent_id = data.parentMessageId ? data.parentMessageId : "";   
            // Note: If there is media, there WILL BE mediaMimeType (lower-case: photo/image, video, audio; pdf or other docs will not be downloaded but S3 link may not be there)
            if (response_type !== "post") {
              temp_messages.push({
                application: data.application,
                content: data.mediaMimeType ? (data.spocS3MediaUrl_list ? data.spocS3MediaUrl_list : data.spocS3MediaUrl ? [data.spocS3MediaUrl] : []) : data.message, // If multimedia files exist, then the multimedia file is the "content". If file not downloaded, null
                caption: data.mediaMimeType ?  data.message : "", // If multimedia files exist, then the message is the "caption"
                timestamp: new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(date), 
                date: date,
                media_transcription: data.extractedTranscription ? data.extractedTranscription : "-",
                hit: data.messageId === props.info.message_id,
                pin: pinList.includes(data.es_id),
                id: data.messageId,
                es_id: data.es_id, 
                response_type: response_type,
                // forwarded_name: response_type.includes("forwarded") ? getForwardedCommsName(parent_id) : "", // Can't get comms_guid from 1953103739#6	
                parent_id: parent_id === props.info.group_id ? "" : parent_id,
                comms_url: data.commsUrl ? data.commsUrl : "", 
                type: data.mediaMimeType ? 
                  (
                    (data.mediaMimeType.toLowerCase().startsWith("image") || data.mediaMimeType.toLowerCase().startsWith("photo")) ? 
                      "image" :
                    data.mediaMimeType.toLowerCase().startsWith("video") ? 
                      "video" :
                    data.mediaMimeType.toLowerCase().startsWith("audio") ?   
                      "audio" :
                    data.mediaMimeType.toLowerCase().startsWith("application/x-tgsticker") ?   
                      "gif" :
                      data.mediaMimeType.toLowerCase()
                  ) : "text" 
              })  
              
              if (i !== hits.length - 1) { 
                const next_sender_id = hits[i+1]._source.senderSelectorAccountId;  
                const next_datestring = new Date(parseInt(hits[i+1]._source.messageDatetime)).toDateString();
                if (next_sender_id !== sender_id || next_datestring !== datestring) { // Next message is a different sender or different sent on different days  
                  temp.push({
                    name: sender_name,
                    date: new Date(parseInt(data.messageDatetime)),
                    username: sender_username ? "@" + sender_username : sender_id, 
                    id: sender_id,
                    messages: temp_messages,
                    message_url: data.messageUrl ? data.messageUrl : "",
                    avatar: avatar
                  })
                  temp_messages = []; // Reset message bucket 
                }  
              } else { // Last message
                temp.push({
                  name: sender_name,
                  date: new Date(parseInt(data.messageDatetime)),
                  username: sender_username ? "@" + sender_username : sender_id, 
                  id: sender_id,
                  messages: temp_messages,
                  message_url: data.messageUrl ? data.messageUrl : "",
                  avatar: avatar
                })
              }
            } 
          } 
           
        if (is_before) { 
          if (hits.length > 49) { 
            setBeforeButton(true); 
            setTimestampBefore(temp[0].date);
          } else {
            setBeforeButton(false);
          }  
        } else {
          if (hits.length > 49) {
            setAfterButton(true);  
            setTimestampAfter(temp[temp.length-1].date);
          } else {
            setAfterButton(false);
          } 
        }      
          resolve(temp)});
      })  
    } 
    
    const getRootMessage = async (range) => {
      const filter = [
        {
          match_all: {}
        },
        {
          match_phrase: {
            commsGuid: props.info.group_id
          }
        },
        {
          match_phrase: {
            rootMessageId: props.info.root_id
          }
        }, 
        {
          match_phrase: {
            messageResponseType: "post"
          }
        }
      ]      
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
            authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
                  es_query: {
                    _source: [
                      "application",
                      "messageId",
                      "message",
                      "messageDatetime",
                      "senderDisplayName",
                      "senderSelectorAccountId",
                      "senderSelectorUsername",
                      "numberOfShares",
                      "mediaType",
                      "messageUrl", 
                      "messageResponseType",
                      "mediaMimeType",
                      "spocS3MediaUrl",
                      "spocS3MediaUrl_list", 
                      "es_id",
                      "commsUrl"
                    ],
                		aggs: {},
                		index: "messages.*",
                		query: {
                		    bool: {
                    		    filter: filter,
                		        must: [],
            		            must_not: [],
            		            should: [], 
                		    }
                		},
                		"size": 51,
                    "sort": [
                      {
                        messageDatetime: {
                          order: "desc",
                          unmapped_type: "boolean"
                        }
                      }
                    ],
                  }
              })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });  
      return new Promise((resolve, reject) => {
        res.json().then(body => { 
          // If reply-comment chain, body will be empty i.e. no post
          // console.log("body", body)
          if (body.hits.hits[0]) {
            const data = body.hits.hits[0]._source;  
            const root_content = {
            content: data.mediaMimeType ? (data.spocS3MediaUrl_list ? data.spocS3MediaUrl_list : data.spocS3MediaUrl ? [data.spocS3MediaUrl] : []) : data.message,
            caption: data.mediaMimeType ?  data.message : "",
            timestamp: new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(data.messageDatetime),
            hit: data.messageId === props.info.message_id,
            id: data.messageId,
            type: data.mediaMimeType ? 
              (
                (data.mediaMimeType.toLowerCase().startsWith("image") || data.mediaMimeType.toLowerCase().startsWith("photo")) ? 
                  "image" :
                data.mediaMimeType.toLowerCase().startsWith("video") ? 
                  "video" :
                data.mediaMimeType.toLowerCase().startsWith("audio") ?   
                  "audio" :
                  data.mediaMimeType.toLowerCase()
              ) : "text" 
            }   
            const root = {
              name: data.senderDisplayName ? data.senderDisplayName : props.info.group_name,
              date: new Date(parseInt(data.messageDatetime)),
              username: data.senderSelectorUsername ? `@${data.senderSelectorUsername}` : data.senderSelectorAccountId ? data.senderSelectorAccountId : "", 
              id: data.senderSelectorAccountId ? data.senderSelectorAccountId : "",
              es_id: data.es_id ? data.es_id : "",
              application: data.application,
              comms_url: data.commsUrl ? data.commsUrl : "", 
              message: root_content,
              avatar: avatar,
              message_url: data.messageUrl ? data.messageUrl : ""
            }  
            resolve(root); 
          } else {
            console.log("Error fetching root message")
            resolve("");
          } 
        })
      })  
    }  
     
    /** On render: Fetch 50 messages before and after the hit message/pinned message and activate formatMessages **/  
    useEffect(() => {    
      setData([]);
      // setCurrent([]);
      const date = typeof loadMessageInfo.message_date == "string" ? new Date(parseInt(loadMessageInfo.message_date)) : new Date(loadMessageInfo.message_date);
      // console.log("date", date)
      let before_range = generateBeforeRange(date);   
      let after_range = generateAfterRange(date);   
      const before_messages = get50Messages(before_range, true);    
      const after_messages = get50Messages(after_range, false);  
      let root_message;
      
      /** Fetch root message for post-comment threads **/
      if (props.info.root_id) {
        if (reply_under_thread || post_under_thread || comment_under_thread) { 
          root_message = getRootMessage();  
        }
      }  
      
      Promise.all([before_messages, after_messages, root_message]).then((res) => { 
        const before = res[0];
        const after = res[1];
        const root = res[2];  
        if (root) {
          setDisplayRootMessage(root);
        }   
        let messages;
        // messages = before.slice(0, -1).concat(current, after); // Commented to prevent current from concatenating with pinned message
        messages = before.slice(0, -1).concat(after);
        messages = messages.filter((value, index, self) =>
          index === self.findIndex((target) => (
            target.username === value.username && JSON.stringify(target.messages) === JSON.stringify(value.messages) // May not completely remove duplicates as order of messages may not be the same (?)
          ))
        ) 
        
        formatMessages(messages);
        // setCurrent(before.slice(0, -1).concat(current, after)); // Commented to prevent current from concatenating with pinned message
        setCurrent(before.slice(0, -1).concat(after)); 
        let scrollDelay = async()=>{
          return new Promise((resolve,reject)=>{
            setTimeout(()=>{
                setScrollToId({id: loadMessageInfo.message_id.toString(), rand: uuid().slice(0, 4)});
                resolve();
            }, 3000)
        }) 
      } 
      scrollDelay().then((res) => {setLoading(false);});
      }) 
    }, [loadMessageInfo]) 
 
    /** Format messages according to date buckets and setData**/
    const formatMessages = (messages) => {
      let data = [];
      let bucket = [];  
      for(let i = 0; i < messages.length; i++) {
        let message = messages[i];
        if (i === 0) {
          const first_message_id = message.messages[0].id;
          setFirstMessageId(first_message_id) 
        }  
        if (i === messages.length-1) {
          const last_message_id = message.messages[message.messages.length - 1].id;
          setLastMessageId(last_message_id)  
        } 
        bucket.push(message);
        if (i !== messages.length - 1) {
          let next_message = messages[i+1];
          let next_datestring = next_message.date.toDateString();
          let curr_datestring = message.date.toDateString();
          if (curr_datestring !== next_datestring) { // Next message date is different (belongs to a different bucket)
            data.push({
              date: message.date,
              content: bucket
            })
            bucket = [];
          }
        } else {
          data.push({
            date: message.date,
            content: bucket
          })
        }
      }  
      setData(data);
    }  
   
    /** When "Load Previous 50" button is clicked **/ 
    const handleLoadBefore = () => {
      let before_range = generateBeforeRange(timestampBefore);  
      const before_messages = get50Messages(before_range, true);
      before_messages.then((res) => { 
        let temp = res.slice(0, -1).concat(current); 
        formatMessages(temp);
        setCurrent(temp); 
      }) 
    }
  
    /** When "Load Next 50" button is clicked **/
    const handleLoadAfter = () => {
      let after_range = generateAfterRange(timestampAfter); 
      const after_messages = get50Messages(after_range, false);
      after_messages.then((res) => { 
        formatMessages(current.slice(0, -1).concat(res));
        setCurrent(current.slice(0, -1).concat(res)); 
      })      
    }   
    
    /** Handle scrolling options --> To be extended to starred messages; setScrollToId(starredMessageId.toString()) **/
    const handleSpeedDialClick = (operation) => {  
      if (operation === "hit") {  
        setScrollToId({id: hitMessageInfo.hit_message_id.toString(), rand: uuid().slice(0, 4)}); 
      } 
      if (operation === "top") { 
        setScrollToId({id: firstMessageId.toString(), rand: uuid().slice(0, 4)});
      } 
      if (operation === "bottom") {
        setScrollToId({id: lastMessageId.toString(), rand: uuid().slice(0, 4)});
      } 
    }
    
    useEffect(() => {   
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth' }); 
    }, [scrollToId])
    
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      } 
      setOpen(false);
    }; 
 
    const handleClickSenderDisplayName = (name) => {
      props.filterCallback({type: "senderDisplayName", label: name, key: uuid().slice(0, 8)})
    } 
    
    const handleClickSenderUID = (id) => {
      props.filterCallback({type: "senderSelectorAccountId", label: id, key: uuid().slice(0, 8)})
    } 
    
    const handleClickSenderUsername = (username) => {
      props.filterCallback({type: "senderSelectorUsername", label: username, key: uuid().slice(0, 8)})
    } 
    
    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) {
        newWindow.opener = null
      }
    }

    /** Feature: Pinning Messages **/ 
    const [alert, setAlert] = useState({status: false, id: "", action: ""}); 
    const [anchorEl, setAnchorEl] = useState(null);
    const [metadata, setMetadata] = useState({
      application: "",
      comms_guid: "",
      comms_name: "", 
	    comms_url: "",  
      es_record_id: "", 
    	message_datetime: "", 
    	message_datetime_str: "", 
    	message_id: ""
    });
    const openMenu = Boolean(anchorEl);
    
    const insertPinnedMessage = async (pin_type) => {   
      let query = {
          	application: metadata.application, 
          	comms_guid: metadata.comms_guid, 
          	comms_name: metadata.comms_name, 
          	comms_url: metadata.comms_url, 
          	created_datetime: new Date().getTime().toString(), 
          	created_datetime_str: new Date().toLocaleString('sv-SE', { timeZoneName: 'short' }).replace(' ', 'T').replace('UTC', '+00:00'), 
          	creator: props.user.username, 
          	creator_remarks: "", 
          	es_record_id: metadata.es_record_id, 
          	message_datetime: metadata.message_datetime, 
          	message_datetime_str: metadata.message_datetime_str, 
          	message_id: metadata.message_id, 
          	pin_type: pin_type, 
          	team: props.user.team, 
          	action_type: "create"
          }   
      const res = await fetch(endpoints.SAVE_PIN_MESSAGE, { 
          headers: {
            authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify(query)   
      }).catch((err) => {
        setAlert({
          status: false,
          id: metadata.message_id,
          action: 'insert'
        });
        setOpen(true);
        console.log('POST call failed: ', err.message);
      });  
      return new Promise((resolve, reject) => {
        res.json().then(body => resolve(body)).catch((err) => {
        setAlert({ 
          status: false,
          id: metadata.message_id,
          action: 'insert'
        });
        setOpen(true);
        console.log('insertPinnedMessage error:', err)
        })
      })  
    }  
 
    const handleClickMenu = (event, application, message_id, es_record_id, message_date, comms_url) => {  
      const message_datetime = message_date?.getTime().toString();
      const message_datetime_str = message_date?.toLocaleString('sv-SE', { timeZoneName: 'short' }).replace(' ', 'T').replace('UTC', '+00:00'); 
      let metadata = {
        application: application,
        comms_guid: props.info.group_id,
        comms_name: props.info.group_name, 
  	    comms_url: comms_url,  
        es_record_id: es_record_id, 
      	message_datetime: message_datetime, 
      	message_datetime_str: message_datetime_str, 
      	message_id: message_id
      }   
      setMetadata(metadata);  
      console.log(metadata);
      setAnchorEl(event.currentTarget);  
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    
    const handleCloseMenu = (event) => { 
      setAnchorEl(null);
    };
    
    const handlePinForMyself = () => {  
      let insert = insertPinnedMessage("individual");
      insert.then((res) => { 
        setAlert({
          status: true,
          id: metadata.message_id,
          action: 'insert'
        });
        setOpen(true);  
      }).catch((err) => {
        console.log("Error:", err);
        setAlert({
          status: false,
          id: metadata.message_id,
          action: 'insert'
        });
        setOpen(true); 
      })
      setAnchorEl(null);
    }
    
    const handlePinForTeam = () => { 
      let insert = insertPinnedMessage("team");
      insert.then((res) => { 
        setAlert({
          status: true,
          id: metadata.message_id,
          action: 'insert'
        });
        setOpen(true);  
      }).catch((err) => {
        console.log("Error:", err);
        setAlert({
          status: false,
          id: metadata.message_id,
          action: 'insert'
        });
        setOpen(true); 
      })
      setAnchorEl(null);
    }

    const handleClickOnPinMessage = (value) => { 
      setLoadMessageInfo({
        message_id: value.messageId,
        message_date: value.messageDatetime,
        group_id: props.info.group_id,
        group_name: props.info.group_name
      }); 
      setLoading(true);
    }
    
    const handlePinList = (value) => { 
      const pin_pairs = value;
      const pin_list = pin_pairs.map((obj) => {return Object.keys(obj).toString()}); 
      setPinList(pin_list); 
    }
    
    const handleUnpinMessageCallback = (value) => { 
      setAlert({
        status: value.status,
        id:value.id,
        action: "delete"
      });
      setOpen(true);
    }
    
    const handleCopyMessage = () => { 
      global.navigator.clipboard.writeText(metadata.message_id); 
      setAnchorEl(null);
    }
    
    const handleAddMessageToCase = () => {
      console.log("handleAddMessageToCase");
      setAnchorEl(null);
    } 
    
    return (
        <>
          <ChatDisplayAppBar 
            renderCallback={props.renderCallback} 
            unpinCallback={handleUnpinMessageCallback} 
            callback={props.callback} 
            loading={loading} 
            senderList={senderList} 
            user={props.user} 
            info={props.info} 
            pinCallback={handleClickOnPinMessage} 
            pinListCallback={handlePinList} 
          />  
          <div className='chatlog-startmessage'>
              This is the activity log for message ID <strong>{hitMessageInfo.hit_message_id}</strong> from the group <strong>{hitMessageInfo.group_name}</strong>.
          </div>  
          <Box bgcolor="white" className='chatlog'>   
            {displayRootMessage ?   
              <Paper elevation={3} variant="contained" sx={{ backgroundColor: '#EAEAEA', minHeight: '10vh',  marginLeft: '1rem', marginRight: '1rem' }} >
                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                  <Box className='root-avatar'> 
                    <Avatar alt={displayRootMessage.name} src={avatar} /> 
                  </Box>  
                  <Box sx={{ display: 'flex', flexDirection: 'column'}}> 
                    <div className="root-message-menu">
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <ClickableTypography css="root-displayname" type="commsName" value={displayRootMessage.name} callback={props.filterCallback}/> 
                        <ClickableTypography css="root-id" type="commsGuid" value={displayRootMessage.id} callback={props.filterCallback}/>  
                      </Box> 
                      <IconButton 
                        size="small"
                        sx={{marginTop: "0.6rem"}}
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => handleClickMenu(event, displayRootMessage.application, displayRootMessage.message.id, displayRootMessage.es_id?.toString(), displayRootMessage.date, displayRootMessage.comms_url)}
                      >
                        <MoreVertIcon />
                      </IconButton> 
                    </div>
                    <div className='root-datestring'>
                      {renderDate(displayRootMessage.date)}, {displayRootMessage.message.timestamp}
                    </div>  
                  </Box> 
                </Box>
                <div className='root-content'>
                  <Formatter value={displayRootMessage.message.content} type={displayRootMessage.message.type} caption={displayRootMessage.message.caption} root={true} />  
                </div>
                {displayRootMessage.message_url ? 
                  <Box sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
                    <Button onClick={() => openInNewTab(displayRootMessage.message_url)} variant="contained" className='link-button' startIcon={<OpenInNewIcon sx={{color: "white"}}/>}>
                      <span>
                          View Post
                      </span>  
                    </Button>
                  </Box>
                    : 
                  <></> 
                }
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openMenu}
                  onClose={handleCloseMenu}
                  onClick={handleCloseMenu}  
                  elevation={0}
                > 
                  <MenuItem onClick={handlePinForMyself}>
                    <ListItemIcon>
                      <PushPinIcon fontSize="small" />
                    </ListItemIcon>
                    Pin for myself
                  </MenuItem>
                  <MenuItem onClick={handlePinForTeam}>
                    <ListItemIcon>
                      <PushPinIcon fontSize="small" />
                    </ListItemIcon>
                    Pin for team 
                  </MenuItem>
                  <MenuItem onClick={handleCopyMessage}>
                    <ListItemIcon>
                      <ContentCopy fontSize="small" />
                    </ListItemIcon>
                    Copy message ID
                  </MenuItem>
                  <MenuItem onClick={handleAddMessageToCase}>
                    <ListItemIcon>
                      <WorkIcon fontSize="small" />
                    </ListItemIcon>
                    Add to case
                  </MenuItem>
                </Menu>
              </Paper>  
              :
              <></>
            } 
            {beforeButton ? 
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingBottom: '1rem' }}>
              <Button 
                sx={{ marginTop: '1rem', "&.Mui-disabled": { color: "#c7c7c7" } }} 
                className='filter-button' 
                onClick={handleLoadBefore} 
                variant="contained"   
              >
                <span sx={{color: 'white'}}>
                    Load Previous 50
                </span> 
              </Button>  
            </Box> : <></>}  
            {data.map((value) => { 
              let date = value.date;
              let messages = value.content;
              return (
              <>
                <div className='chatlog-date'> {renderDate(date)} </div>
              { messages.map((unit) => {  
                  let name = unit.name; 
                  let username = unit.username;
                  let id = unit.id; 
                  // let username = unit.username;
                  let indiv_messages = unit.messages; 
                  let avatar = unit.avatar; 
                  return (
                    <Box className='chatlog-position'> 
                      <Box className='chatlog-avatar'> 
                        <Avatar alt={name} src={avatar} />
                      </Box>
                      <Box>
                        { indiv_messages.map((message, index) => {   
                            // const labelId = `checkbox-list-label-${message}`;  
                            let last_index = messages.length - 1;
                            let last_index_consecutive = indiv_messages.length - 1;  
                            let pinned = pinList.includes(message.es_id);  
                            if (index === 0) {
                              return ( 
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                  <Box sx={{ backgroundColor: (message.hit ? "#468cf7" : "#605958") }} className='chatbubble-topleft' ref={ scrollToId.id === message.id.toString() ? scrollToRef : null }>
                                    <div className="message-menu">
                                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                        {name ?  
                                        <div>
                                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <span sx={{ color: (message.hit ? "white" : "black") }} onClick={() => handleClickSenderDisplayName(name)} className='message-username'> 
                                              {name}  
                                            </span> 
                                            {id ?
                                              <span sx={{ color: (message.hit ? "white" : "black") }} onClick={() => handleClickSenderUID(id)} className='message-sender-id'> 
                                                ({id})   
                                              </span>
                                              : <></>
                                            }
                                          </Box>
                                        </div>
                                        :
                                        username ?
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                          <span sx={{ color: (message.hit ? "white" : "black") }} onClick={() => handleClickSenderUsername(username)} className='message-username'> 
                                            {username}  
                                          </span> 
                                          {id ?
                                            <span sx={{ color: (message.hit ? "white" : "black") }} onClick={() => handleClickSenderUID(id)} className='message-sender-id'> 
                                              ({id})   
                                            </span>
                                            : <></>
                                          } 
                                        </Box>
                                        : 
                                        <i sx={{ color: (message.hit ? "white" : "black") }} className='message-username-channel'> 
                                          {hitMessageInfo.group_name}  
                                        </i> 
                                      }
                                      </Box> 
                                      <IconButton 
                                        size="small"
                                         sx={{color: "white"}}
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(event) => handleClickMenu (event, message.application, message.id.toString(), message.es_id.toString(), message.date, message.comms_url)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton> 
                                    </div> 
                                    {message.response_type.includes("forwarded") ? <i className='message-username-channel'> [Forwarded] </i> : <></>}
                                    {message.parent_id && message.response_type === "reply" ? 
                                      <ReplyFormatter parent_id={message.parent_id} group_id={props.info.group_id} response_type={message.response_type}/>
                                      : 
                                      <></>  
                                    }
                                    <Formatter value={message.content} type={message.type} caption={message.caption} root={false} transcription={message.media_transcription}/>  
                                    <div className='message-timestamp'>{pinned ? <PushPinIcon fontSize="small" /> : <></> } {message.timestamp} </div>
                                  </Box>  
                                </Box>   
                              )
                            } else if (index === last_index || index === last_index_consecutive) {
                              return (
                                <Box sx={{ display: "flex", flexDirection: "row"}}> 
                                  <Box sx={{ backgroundColor: (message.hit ? "#468cf7" : "#605958") }} className='chatbubble-bottomleft' ref={ scrollToId.id === message.id.toString() ? scrollToRef : null }>
                                    <div className="message-menu">
                                      <span></span>
                                      <IconButton 
                                        size="small"
                                        sx={{color: "white"}}
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(event) => handleClickMenu(event, message.application, message.id.toString(), message.es_id.toString(), message.date, message.comms_url)}
                                      >
                                        <MoreVertIcon/>
                                      </IconButton> 
                                    </div> 
                                    <Formatter value={message.content} type={message.type} caption={message.caption} root={false} transcription={message.media_transcription}/>
                                    <div className='message-timestamp'>{pinned ? <PushPinIcon fontSize="small" /> : <></> }{message.timestamp} </div>
                                  </Box>  
                                </Box> 
                              )
                            } else {
                              return ( 
                              <Box sx={{ display: "flex", flexDirection: "row"}}> 
                                <Box sx={{ backgroundColor: (message.hit ? "#468cf7" : "#605958") }} className='chatbubble-middle' ref={ scrollToId.id === message.id.toString() ? scrollToRef : null }> 
                                <div className="message-menu">
                                  <span></span>
                                    <IconButton 
                                      size="small"
                                      sx={{color: "white"}}
                                      aria-controls={open ? 'long-menu' : undefined}
                                      aria-expanded={open ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) => handleClickMenu(event, message.application, message.id.toString(), message.es_id.toString(), message.date, message.comms_url)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton> 
                                  </div> 
                                  <Formatter value={message.content} type={message.type} caption={message.caption} root={false} transcription={message.media_transcription}/>
                                  <div className='message-timestamp'>{pinned ? <PushPinIcon fontSize="small" /> : <></> }{message.timestamp} </div> 
                                </Box> 
                              </Box> 
                              ) 
                            }
                        })} 
                      </Box>  
                    </Box> 
                  )
                })
              }  
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}  
                elevation={0}
              > 
                <MenuItem onClick={handlePinForMyself}>
                  <ListItemIcon>
                    <PushPinIcon fontSize="small" />
                  </ListItemIcon>
                  Pin for myself
                </MenuItem>
                <MenuItem onClick={handlePinForTeam}>
                  <ListItemIcon>
                    <PushPinIcon fontSize="small" />
                  </ListItemIcon>
                  Pin for team 
                </MenuItem>
                <MenuItem onClick={handleCopyMessage}>
                  <ListItemIcon>
                    <ContentCopy fontSize="small" />
                  </ListItemIcon>
                  Copy message ID
                </MenuItem>
                <MenuItem onClick={handleAddMessageToCase}>
                  <ListItemIcon>
                    <WorkIcon fontSize="small" />
                  </ListItemIcon>
                  Add to case
                </MenuItem>
              </Menu> 
              </>)
            })}
            {afterButton ? 
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingBottom: '1rem' }}>
              <Button 
                sx={{ marginTop: '1rem', "&.Mui-disabled": { color: "#c7c7c7" } }} 
                className='filter-button' 
                onClick={handleLoadAfter} 
                variant="contained"   
              >
                <span sx={{color: 'white'}}>
                    Load Next 50
                </span> 
              </Button>  
            </Box> : <></>}   
            <SpeedDial 
              ariaLabel="SpeedDial basic example"
              sx={{ margin: 5,  top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed' }} 
              icon={<SpeedDialIcon />}
              onClick={handleSpeedDialClick} 
              FabProps={{
                sx: {
                  bgcolor: '#605958',
                  '&:hover': {
                    bgcolor: '#605958',
                  }
                }
              }}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => handleSpeedDialClick(action.operation)}
                />
              ))}
            </SpeedDial>   
          </Box>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity={alert.status ? "success" : "error"}
              sx={{ width: '100%' }}
            >
              { alert.action === "insert" ? 
                  (alert.status ? 
                    <div >
                      Successfully pinned message {alert.id}
                    </div>
                  : 
                    <div >
                      Failed to pin message {alert.id}
                    </div> 
                  )
                :
                alert.action === "delete" ?
                  (alert.status ? 
                    <div >
                      Successfully unpinned message {alert.id}
                    </div>
                  : 
                    <div >
                      Failed to unpin message {alert.id}
                    </div> 
                  )
                :
                <></>
              } 
            </Alert>
          </Snackbar>
        </> 
    );
}  