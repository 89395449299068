import { useState } from 'react'; 
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MuiAccordion from '@mui/material/Accordion';
import Top30Forwarders from "./top30Forwarders.js";
import Top30Repliers from "./top30Repliers.js";
import Top30UniqueSenders from "./top30UniqueSenders.js";
import Tooltip from '@mui/material/Tooltip';
import ContentPreview from "./contentPreview.js";
import "./contentDisplay.css"; 
 
const Accordion = styled((props) => ( <MuiAccordion disableGutters elevation={0} square {...props} /> ))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export default function ContentDisplay(props) { 
    const [messageCount, setMessageCount] = useState(0);  
    const [loading, setLoading] = useState(true); 
    const [expandedTop30UniqueSenders, setExpandedTop30UniqueSenders] = useState(false);
    const [expandedTop30Forwarders, setExpandedTop30Forwarders] = useState(false);
    const [expandedTop30Repliers, setExpandedTop30Repliers] = useState(false);
    
    /** Callback function **/
    const handleMessageCount = (value) => {
        setMessageCount(value);
        setLoading(false)
    }
       
    return (
        <Box bgcolor="white" className='content-box' >
            <div className='content-header'>
                Hit Messages Information
            </div> 
            <div className='content-accordion'> 
                <Accordion elevation={0} disableGutters onChange={(event, expanded) => setExpandedTop30UniqueSenders(expanded)}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >
                        <div className='sub-header'>
                            Top 30 Unique Senders
                        </div>
                    </AccordionSummary>
                    <AccordionDetails> 
                        <Top30UniqueSenders expanded={expandedTop30UniqueSenders} dateCallback={props.dateCallback} filterCallback={props.filterCallback} filters={props.filters} selected={props.selected} tab={props.tab}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters onChange={(event, expanded) => setExpandedTop30Forwarders(expanded)}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >
                        <div className='sub-header'>
                            Top 30 Forwarders of Message
                        </div>
                    </AccordionSummary>
                    <AccordionDetails> 
                        <Top30Forwarders expanded={expandedTop30Forwarders} dateCallback={props.dateCallback} filterCallback={props.filterCallback} filters={props.filters} selected={props.selected} tab={props.tab}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters onChange={(event, expanded) => setExpandedTop30Repliers(expanded)}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >
                        <div className='sub-header'>
                            Top 30 Frequent "Repliers"
                        </div>
                    </AccordionSummary>
                    <AccordionDetails> 
                        <Top30Repliers expanded={expandedTop30Repliers} dateCallback={props.dateCallback} filterCallback={props.filterCallback} filters={props.filters} selected={props.selected} tab={props.tab}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />} 
                      sx={{ backgroundColor: '#f5f5f5' }}
                    >
                        <Box sx={{ display: 'flex', gap: '10px' }}> 
                            <div className='sub-header'>
                                Content Preview
                            </div> 
                            <div>
                                {loading ? <Chip label={"Loading..."} variant="outlined" size="small" /> : <Chip label={messageCount.toLocaleString()} variant="outlined" size="small" />}    
                                <Tooltip key='uniqueKey' title="This represents total number of hit messages, but only the latest 500 hit messages will be displayed"> 
                                    <InfoOutlinedIcon sx={{fontSize: '14px', marginLeft: '2px', color: 'grey' }}/>    
                                </Tooltip>
                            </div> 
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ContentPreview callback={props.callback} filterCallback={props.filterCallback} filters={props.filters} selected={props.selected} count={handleMessageCount} tab={props.tab}/>
                    </AccordionDetails>
                </Accordion>           
            </div>        
        </Box> 
    );
}