import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { get, put } from 'aws-amplify/api'; 
import { v4 as uuid } from "uuid";
import './addGroupTagForm.css'; 

const ERROR_MESSAGE = "Tag, platform and/or group IDs fields are empty!";
const SUCCESS_MESSAGE = "Successfully submitted entry.";
const platforms = [
    "Discord",
    "Facebook",
    "Instagram",
    "Telegram",
    "TikTok",
    "Twitter",
    "WhatsApp",
    "YouTube"];
    
export default function AddGroupTagForm(props) { 
    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);
    const [platform, setPlatform] = useState(''); 
    const [groups, setGroups] = useState([]);  
    const [status, setStatus] = useState(null); 
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);  
 
    useEffect(() => {
        const getTags = async () => {
            try {
                const getOperation = get({
                    apiName: 'gccgrouptagapi',
                    path: '/gccgrouptag'
                });
                const { body } = await getOperation.response; 
                const json = await body.json();
                const temp = new Set();
                json.forEach(x => x.delete ? console.log('') : temp.add(x.tag));
                setTags(Array.from(temp)); 
                // console.log('GET call succeeded: ', json);
            } catch (error) {
                console.log('GET call failed: ', error);
            }
        } 
        getTags();  
    }, [tag]); 
    
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
    }
    
    const addGroups = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'gccgrouptagapi',
                path: '/gccgrouptag',
                options: {
                    body: {
                        PK: pk,
                        tag: tag,
                        platform: platform,
                        group_ids: groups,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(),
                        hit_type: 'grouptagging',
                        delete: false,
                        enable: true
                    }
                }
            })
            await putOperation.response;
            // console.log('PUT call succeeded: ', response); 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    } 
    
    const handleConfirm = () => { 
        if (tag === null || tag.trim() === '' || platform === '' || groups.length === 0) { 
            setStatus(false); 
            setOpenAlert(true);
        } else {
            const pk = uuid().slice(0, 12);
            addGroups(pk); 
            setStatus(true);
            setOpenAlert(true);
        } 
        setOpenDialog(false);    
    }  
    
    const handleReset = () => {
        setTag('');
        setPlatform('');
        setGroups([]);
        setStatus(null);
        setOpenAlert(false);
    } 
     
    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    return (
        <Box className='table-position'> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to submit record?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment> 
            <Paper elevation={0} className='table'> 
                    <Box>
                        <div className='table-header'>
                            Folder Tag
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>    
                            <Autocomplete 
                                value={tag}
                                options={tags}   
                                size="small"
                                id="free-solo-demo"
                                freeSolo 
                                onChange={(event, value) => setTag(value)} 
                                renderInput={(params) => 
                                    <TextField size="small" {...params} onChange={(event) => setTag(event.target.value)} label="Select/add a folder tag"/>}
                            />
                        </Box>
                        <div className='table-header'>
                            Platform
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                            <FormControl fullWidth>
                                <InputLabel size="small">Select a platform</InputLabel>
                                <Select  
                                  value={platform}
                                  label="Select a platform"
                                  size="small"
                                  onChange={(event) => setPlatform(event.target.value)}
                                >
                                {platforms.map(
                                    (platform, i) => ( 
                                        <MenuItem value={platform} key={i}>{platform}</MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>
                        </Box> 
                        <div className='table-header'>
                            IDs 
                            <Tooltip title="ID refers to the identity document of an account, social media group, chat group or page.">
                                <HelpOutlineIcon sx={{fontSize: 'small'}}/> 
                            </Tooltip>
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>    
                            <Autocomplete
                                value={groups}
                                multiple
                                clearIcon={false}
                                size="small" 
                                options={[]} 
                                freeSolo
                                onChange={(event, value) => setGroups(value)}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                  ))
                                }
                                renderInput={(params) => (
                                    <TextField size="small" {...params} label="Press 'Enter' to add ID" />
                                )}
                            /> 
                        </Box> 
                    </Box>
                    <Box className='button-box-position'>
                        <div className='button-position'>
                            <Button className='form-button' onClick={handleOpen} variant="contained">
                                <span className='button-text'>
                                    SUBMIT
                                </span> 
                            </Button>
                            <Button className='form-button' onClick={handleReset} variant="contained">
                                <span className='button-text'>
                                    RESET
                                </span>  
                            </Button>
                        </div>
                    </Box> 
            </Paper>   
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>  
        </Box>
    );
}
 