import React from 'react';
import AddAdhocTaskingForm from '../components/addAdhocTaskingForm.js'; 

const AddAdhocTaskingPage = (props) => {
  return (
    <>
        <h1 className='header'>
            Add Urgent Tasking
        </h1>
        <AddAdhocTaskingForm user={props.user}/>
    </>
    );
};

export default AddAdhocTaskingPage;