import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import Typography from "@mui/material/Typography"; 

export default function ChatDisplaySenderList(props) {
    const [members, setMembers] = useState(props.senderList);
    const [memberCount, setMemberCount] = useState(props.senderList.length);
    
    useEffect(() => {
        setMembers(props.senderList);    
        setMemberCount(props.senderList.length);
    }, [props.senderList]);
    
    return (
        <Box sx={{ width: '100%', maxHeight: 500, bgcolor: 'background.paper' }} >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', paddingTop: '8px' }}>
                <PersonIcon sx={{paddingLeft: '5px', color: '#605958'}}/>
                <Typography>
                    <strong>{memberCount} Sender(s)</strong>
                </Typography>
            </Box>
            <Divider sx={{ paddingTop: '8px'}} />
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {members.map((member) => (
                <ListItem
                  key={member}
                  disableGutters  
                >
                    <Box sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', }}>  
                            <Avatar alt={member.name} src={member.avatar} />     
                            <ListItemText 
                                disableTypography
                                primary={<Typography style={{ width: 200, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{member.name}</Typography>} 
                                secondary={<Typography style={{ width: 200, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{member.username}</Typography>} 
                            />
                        </Box>
                    </Box>
                </ListItem>
              ))}
            </List>
        </Box>
    );
} 