import { useState, useEffect } from 'react';  
import AddIcon from '@mui/icons-material/Add'; 
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import DateHeader from './dateHeader.js'; 
import FilterModal from './filterModal.js';  
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';  
import SearchIcon from '@mui/icons-material/Search';   
import SearchModal from './searchModal.js';
import Stack from '@mui/material/Stack'; 
import Tag from './tag.js'; 
import './searchHeader.css';

const SearchHeader = (props) => {  
    let today = new Date(); 
    today.setHours(0, 0, 0, 0);  
    let last_7_days = new Date();
    last_7_days.setDate(today.getDate() - 7); 
    last_7_days.setHours(0, 0, 0, 0); 
    const [anchorEl, setAnchorEl] = useState(null);     
    const [tags, setTags] = useState([]); // Chip Labels 
    const [conditions, setConditions] = useState([]); 
    const [generalSearch, setGeneralSearch] = useState('');  
    const [isDisabled, setIsDisabled] = useState(true);
    const [currentTags, setCurrentTags] = useState([]);
    const [currentGeneralSearch, setCurrentGeneralSearch] = useState('');
    const [currentDateRange, setCurrentDateRange] = useState({
        gte: Date.parse(last_7_days), 
        lte: Date.parse(today)
    });  
    const [isLoading, setIsLoading] = useState(false); 
    const [dateRange, setDateRange] = useState({
        gte: Date.parse(last_7_days), 
        lte: Date.parse(today)
    });    
    
    useEffect(() => { 
        if ((generalSearch !== currentGeneralSearch) ||
        (JSON.stringify(tags) !== JSON.stringify(currentTags)) ||
        JSON.stringify(dateRange) !== JSON.stringify(currentDateRange)) {  
            setIsDisabled(false) 
        } else {
            setIsDisabled(true)
        } 
        let temp_conditions = [];
        let and_tags = tags.filter((tag) => {
           return (tag.type === "extractedKeywordCategories_master" || 
            tag.type === "extractedGroupTaggings" ||
            tag.type === "extractedFaceHitWGroup") 
        }) 
         
        let keyword_tags = tags.filter((tag) => { return tag.type === "extractedKeywords_master" });
        let group_uid_tags = tags.filter((tag) => { return tag.type === "commsGuid" });
        let group_name_tags = tags.filter((tag) => { return tag.type === "commsName" });
        let group_url_tags = tags.filter((tag) => { return tag.type === "commsUrl" });
        let sender_name_tags = tags.filter((tag) => { return tag.type === "senderDisplayName" });
        let sender_uid_tags = tags.filter((tag) => { return tag.type === "senderSelectorAccountId" });
        let sender_phone_tags = tags.filter((tag) => { return tag.type === "senderSelectorPhoneNumber" });
        let sender_username_tags = tags.filter((tag) => { return tag.type === "senderSelectorUsername" });
        let application_tags = tags.filter((tag) => { return tag.type === "application" });
        let message_url_tags = tags.filter((tag) => { return tag.type === "messageUrl" });
        let faceprint_tags = tags.filter((tag) => { return tag.type === "extractedFaceHitWPerson" });
        let message_response_type_tags = tags.filter((tag) => { return tag.type === "messageResponseType" });
        let hashtag_tags = tags.filter((tag) => { return tag.type === "extractedHashtags" });
        let extracted_urls_tags = tags.filter((tag) => { return tag.type === "extractedUrls" });
        let extracted_url_domains_tags = tags.filter((tag) => { return tag.type === "extractedUrlDomains" });
        let message_id_tags = tags.filter((tag) => { return tag.type === "messageId" });
        
        and_tags.forEach((tag) => temp_conditions.push({ type: tag.type, tags: [tag] })) 
        
        if (keyword_tags.length) {
            temp_conditions.push({ type: "extractedKeywords_master", tags: keyword_tags }); 
        }
        if (group_uid_tags.length) {
            temp_conditions.push({ type: "commsGuid", tags: group_uid_tags }); 
        }
        if (group_name_tags.length) {
            temp_conditions.push({ type: "commsName", tags: group_name_tags }); 
        }
        if (group_url_tags.length) {
            temp_conditions.push({ type: "commsUrl", tags: group_url_tags }); 
        }
        if (sender_name_tags.length) {
            temp_conditions.push({ type: "senderDisplayName", tags: sender_name_tags }); 
        }
        if (sender_uid_tags.length) {
            temp_conditions.push({ type: "senderSelectorAccountId", tags: sender_uid_tags }); 
        }
        if (sender_phone_tags.length) {
            temp_conditions.push({ type: "senderSelectorPhoneNumber", tags: sender_phone_tags }); 
        }
        if (sender_username_tags.length) {
            temp_conditions.push({ type: "senderSelectorUsername", tags: sender_username_tags }); 
        }
        if (application_tags.length) {
            temp_conditions.push({ type: "application", tags: application_tags }); 
        }        
        if (message_url_tags.length) {
            temp_conditions.push({ type: "messageUrl", tags: message_url_tags }); 
        }
        if (faceprint_tags.length) {
            temp_conditions.push({ type: "extractedFaceHitWPerson", tags: faceprint_tags }); 
        }
        if (message_response_type_tags.length) {
            temp_conditions.push({ type: "messageResponseType", tags: message_response_type_tags }); 
        }
        if (hashtag_tags.length) {
            temp_conditions.push({ type: "extractedHashtags", tags: hashtag_tags }); 
        }
        if (extracted_urls_tags.length) {
            temp_conditions.push({ type: "extractedUrls", tags: extracted_urls_tags }); 
        }
        if (extracted_url_domains_tags.length) {
            temp_conditions.push({ type: "extractedUrlDomains", tags: extracted_url_domains_tags }); 
        }    
        if (message_id_tags.length) {
            temp_conditions.push({ type: "messageId", tags: message_id_tags }); 
        }
        
        // console.log(temp_conditions);
        setConditions(temp_conditions);
    }, [generalSearch, tags, dateRange])
    
    /** Callback to dashboardPage **/
    const handleClickSearch = () => {  
        // Populate lists 
        let keyword = [];
        let keyword_categories = [];
        let group_tags = [];
        let group_uid = [];
        let group_name  = [];
        let group_url = [];
        let sender_name = [];
        let sender_uid = [];
        let sender_phone = [];
        let sender_username = [];
        let application = [];
        let message_url = []; 
        let faceprint_categories = [];
        let faceprint = []; 
        let message_response_type = [];
        let hashtag = [];
        let extracted_urls = [];
        let extracted_url_domains = []; 
        let message_id = [];
        
        if (tags.length > 0) {
            for (let i = 0; i < tags.length; i++) {
                let tag = tags[i];
                if (tag.type === "extractedKeywordCategories_master") { 
                    keyword_categories = keyword_categories.concat(tag.label.trim()); 
                } else if (tag.type === "extractedKeywords_master") {
                    keyword = keyword.concat(tag.label.trim());
                } else if (tag.type === "extractedGroupTaggings") {  
                    group_tags = group_tags.concat(tag.label.trim());
                } else if (tag.type === "commsGuid") {
                    group_uid = group_uid.concat(tag.label.trim());    
                } else if (tag.type === "commsName") {
                    group_name = group_name.concat(tag.label.trim());    
                } else if (tag.type === "commsUrl") {
                    group_url = group_url.concat(tag.label.trim());    
                } else if (tag.type === "extractedFaceHitWGroup") {
                    faceprint_categories = faceprint_categories.concat(tag.label.trim());    
                } else if (tag.type === "extractedFaceHitWPerson") { 
                    faceprint = faceprint.concat(tag.label.trim());
                } else if (tag.type === "senderDisplayName") {
                    sender_name = sender_name.concat(tag.label.trim());    
                } else if (tag.type === "senderSelectorAccountId") {
                    sender_uid = sender_uid.concat(tag.label.trim());    
                } else if (tag.type === "senderSelectorUsername") {
                    sender_username = sender_username.concat(tag.label.trim());    
                } else if (tag.type === "senderSelectorPhoneNumber") {
                    sender_phone = sender_phone.concat(tag.label.trim());    
                } else if (tag.type === "application") {
                    application = application.concat(tag.label.trim());    
                } else if (tag.type === "messageUrl") {
                    message_url = message_url.concat(tag.label.trim());
                } else if (tag.type === "messageResponseType") {
                    message_response_type = message_response_type.concat(tag.label.trim());
                } else if (tag.type === "extractedHashtags") {
                    hashtag = hashtag.concat(tag.label.trim()); 
                } else if (tag.type === "extractedUrls") {
                    extracted_urls = extracted_urls.concat(tag.label.trim());
                } else if (tag.type === "extractedUrlDomains") {
                    extracted_url_domains = extracted_url_domains.concat(tag.label.trim());
                } else if (tag.type === "messageId") {
                    message_id = message_id.concat(tag.label.trim());
                }
                // else { 
                //     general = general.concat(tag.label); // TODO: Not needed
                // }
            } 
        }  
         
        let filter = [{
            match_all: {}
        }]; 
         
        if (keyword.length > 0) {   
            let phrases = [];
            keyword.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        extractedKeywords_master: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
        
        if (keyword_categories.length > 0) { 
            keyword_categories.forEach((term) => {
                filter.push({
                    match_phrase: {
                        extractedKeywordCategories_master: term
                    }
                })
                 
            }) 
        }  
        
        if (group_tags.length > 0) {
            group_tags.forEach((term) => {
                filter.push({
                    match_phrase: {
                        extractedGroupTaggings: term
                    }
                }) 
            })
        } 
        
        if (group_uid.length > 0) { 
            let phrases = [];
            group_uid.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        commsGuid: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
          
        if (group_name.length > 0) { 
            let phrases = [];
            group_name.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        commsName: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
        
        if (group_url.length > 0) { 
            let phrases = [];
            group_url.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        commsUrl: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
        
        if (faceprint_categories.length > 0) {
            faceprint_categories.forEach((term) => {
                filter.push({
                    match_phrase: {
                        extractedFaceHitWGroup: term
                    }
                })  
            })
        } 
        
        if (faceprint.length > 0) { 
            let phrases = [];
            faceprint.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        extractedFaceHitWPerson: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
        
        if (sender_name.length > 0) { 
            let phrases = [];
            sender_name.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        senderDisplayName: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        }
        
        if (sender_uid.length > 0) { 
            let phrases = [];
            sender_uid.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        senderSelectorAccountId: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        }
        
        if (sender_phone.length > 0) { 
            let phrases = [];
            sender_phone.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        senderSelectorPhoneNumber: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            })   
        }
        
        if (sender_username.length > 0) { 
            let phrases = [];
            sender_username.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        senderSelectorUsername: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            })              
        }
        
        if (application.length > 0) {
            let phrases = [];
            application.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        application: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            })   
            // For AND conditions
            // application.forEach((term) => {
            //     filter.push({
            //         match_phrase: {
            //             application: term
            //         }
            //     })
            // })
        }
        
        if (message_url.length > 0) { 
            let phrases = [];
            message_url.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        messageUrl: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            })     
        }
        
        if (message_response_type.length > 0) { 
            let phrases = [];
            message_response_type.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        messageResponseType: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            })            
        }
        
        if (hashtag.length > 0) {  
            let phrases = [];
            hashtag.forEach((term) => { 
                phrases.push({
                    match_phrase: {
                        extractedHashtags: `#${term}`
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
        
        if (extracted_urls.length > 0) { 
            let phrases = [];
            extracted_urls.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        extractedUrls: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        } 
         
        if (extracted_url_domains.length > 0) { 
            let phrases = [];
            extracted_url_domains.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        extractedUrlDomains: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            }) 
        }  
          
        if (message_id.length > 0) {
            let phrases = [];
            message_id.forEach((term) => {
                phrases.push({
                    match_phrase: {
                        messageId: term
                    }
                })
            })  
            filter.push({
                bool: {
                    minimum_should_match: 1,
                    should: phrases
                }
            })    
        } 
        /** Constructing filter request format for Multi-match **/
        // if (general.length > 0) { 
        //     general_term = general.join(', '); 
        //     filter.push({
        //         multi_match: {
        //             type: "best_fields",
        //             query: general_term,
        //             lenient: true
        //         }
        //     }) 
        // }         
        
        if (generalSearch) {
           filter.push({
                multi_match: {
                    type: "best_fields",
                    query: generalSearch,
                    lenient: true
                }
            })  
        } 
         
        filter.push({
            range: {
                messageDatetime: dateRange
            }
        })  
        setCurrentGeneralSearch(generalSearch);
        setCurrentTags(tags);
        setCurrentDateRange(dateRange)
        props.callback(filter);
        props.viewer({viewer: false, general: false});
        // setTimeout(
        //   () => setLoading(false), 1000
        // ); 
        setIsLoading(true); 
        // setTimeout(
        //   () => setIsDisabled(true), 1000
        // );  
    }
 
    useEffect(() => {
        if (isLoading) {
            setTimeout(
                () => {setIsLoading(false); setIsDisabled(true)}, 2000
            ); 
        }
    }, [isLoading])
    
    /** For words added outside of search header e.g. from word cloud **/
    useEffect(() => {   
        if (props.added == undefined) { 
            return;
        } else {
            let tag =  props.added;
            if(!isDuplicate(tag)) {
                setTags([...tags, tag]); 
            }
        } 
    }, [props.added])
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };   
    
    const handleClear = () => {
        setTags([]); 
    }
    
    const isDuplicate = (tag) => {  
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].label === tag.label && tags[i].type === tag.type) {
                return true;
            };
            
            // if (tag.type === '') {
            //     if (tags[i].label === tag.label && tags[i].type === tag.type) {
            //         return true;
            //     };
            // } else {
            //     if (tags[i].label === tag.label && tags[i].type === tag.type && tags[i].pk === tag.pk) {
            //         return true;
            //     };
            // } 
        } 
        return false; 
    }
      
    /** Callback function to filterModal.js **/
    function handleAddTag(tag) {    
        if(!isDuplicate(tag)) {  
            setTags([...tags, tag]);  
        }
    } 
    
    /** Callback function to searchModal.js **/
    function handleGeneralSearch(value) {  
        setGeneralSearch(value);
    } 
    
    /** Callback function to tag.js **/
    function handleDeleteTag(deleted_tag) {
        setTags(tags => tags.filter((tag) => tag.key !== deleted_tag.key));   
    }
    
    function handleChangeDate(value) {   
        setDateRange(value);    
    }    
    
    function handleLoadBookmark(value) { 
        setTags(value);
    }
    
    const open = Boolean(anchorEl);
    return ( 
        <>
            {isLoading ?  <LinearProgress sx={{color:"black"}} /> : <></> } 
            <Box sx={{ display: 'flex', flexDirection: 'row',  alignItems: 'end', marginTop: '3rem', marginLeft: '2rem', gap: '1rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}> 
                  <span className='header-tag'>
                    Tags  
                  </span>
                  <SearchModal callback={handleGeneralSearch} loadCallback={handleAddTag} loadBookmarkCallback={handleLoadBookmark} tags={tags} user={props.user}/>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}> 
                  <span className='header-tag'>
                    Date Frame   
                  </span>
                  <DateHeader value={props.date} callback={handleChangeDate}/>
                </Box>  
               <Button disabled={isDisabled} className='search-button' onClick={handleClickSearch} variant="contained" startIcon={<SearchIcon sx={{color: "white"}}/>}>
                    <span className='button-text'>
                        Search
                    </span>  
                </Button>   
            </Box> 
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start'}}>   
               <Button sx={{ marginTop: '1rem', marginLeft: '2rem' }} className='filter-button' onClick={handleClick} variant="contained" startIcon={<AddIcon />}>
                    <span sx={{color: 'white'}} >
                        Add Filter
                    </span> 
                </Button> 
                <Popover 
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{ vertical: -10 }}
                >   
                    <FilterModal callback={handleAddTag}/>
                </Popover>
                <Button sx={{ marginTop: '1rem', marginLeft: '10px' }} className='clear-button' onClick={handleClear} disabled={tags.length == 0} variant="outlined">
                    <span sx={{color: '#605958'}} >
                        Clear All Filters
                    </span> 
                </Button>   
                <Box sx={{ maxWidth: '60%' }}>  
                    <Stack sx={{ marginTop: '1rem', marginLeft: '2rem' }} spacing={{ xs: 1, sm: 1 }} direction="row" useFlexGap flexWrap="wrap">
                        { conditions.map((condition) => {
                            const and_types = ["extractedGroupTaggings", "extractedKeywordCategories_master", "extractedFaceHitWGroup"];
                            let type = condition.type;
                            let tags = condition.tags; 
                            let is_and_condition = and_types.includes(type);
                            if (!is_and_condition) {
                                return (
                                    <Box sx={{ display: "flex", flexDirection: "row", padding: '5px', border: '1px solid grey', borderRadius: '25px', gap: "4px" }}>
                                        {tags.map((tag) => (  
                                            <Tag tag={tag} callback={handleDeleteTag}/>
                                        ))} 
                                    </Box>
                                )
                            } else {
                                return (
                                    tags.map((tag) => (  
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Tag tag={tag} callback={handleDeleteTag}/>
                                        </Box>
                                        
                                    ))
                                )
                            } 
                        }   
                        )}
                    </Stack> 
                </Box>  
            </Box> 
            {currentGeneralSearch ? <Box sx={{ marginLeft: '1rem', marginTop: '2rem'}}><i>Search results for '{currentGeneralSearch}'</i></Box> : <Box sx={{ marginLeft: '1rem', marginTop: '3.2rem'}}/>}
        </>
    );
};

export default SearchHeader; 