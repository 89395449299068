import { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';  
import fetch from "node-fetch";  
import "./multimediaPropagationTrend.css"; 
import * as endpoints from '../../../endpoints.js';

const colors = [
    '#3F51B5',
    '#ff7f0e',
    '#2ca02c',
    '#FFC300',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
    '#7fc97f',
    '#beaed4',
    '#fdc086',
    '#ffff99',
    '#FBC02D',
    '#f0027f',
    '#bf5b17',
    '#666666',
    '#a6cee3',
    '#D35400',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
  ]; 

export default function MultimediaPropagationTrend(props) { 
  const [data, setData] = useState({});
  const [datetime, setDatetime] = useState([]);
  const [loading, setLoading] = useState(true);
  
  let highlight = 'series';
  let faded = 'global';  

  const formatter = (date) => { 
    return format(date, 'yyyy-MM-dd kk:mm');
  } 
  
  useEffect(() => {     
    let filter_req = [
      {
        exists: {
          field: "mediaId"
        }
      },
      {
        exists: {
          field: "mediaMimeType"
        }
      }
    ];
    let filters = props.filters; 
    const range = filters.filter(x => x.hasOwnProperty('range'));
    let gte = new Date(range[0].range.messageDatetime.gte);
    let lte = new Date(range[0].range.messageDatetime.lte);
    var hours_diff = Math.abs(lte - gte) / 36e5;
    let fixedInterval = "";
    if (hours_diff < 3 || hours_diff === 3) {
      fixedInterval = "5m"
    } else {
      fixedInterval = "3h"
    } 
    let selected = props.selected; 
    let selected_commsGuid = [];
    let selected_commsName = [];
    selected.forEach((group) => { 
      selected_commsGuid.push({
        match_phrase: {
          commsGuid: group.commsGuid
        }
      });
      selected_commsName.push({
        match_phrase: {
          commsName: group.commsName
        }
      }) 
    })
    let temp = [];
    if (selected.length > 0) {
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsGuid  
        }
      })
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsName
        }
      })
    }
    
    const content_filters = filters.concat(temp, filter_req);
       
    const getContent = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
            es_query: {
              _source: [],
              stored_fields: ["*"],
          		aggs: {
                2: {
                  date_histogram: {
                    field: "messageDatetime",
                    fixed_interval: fixedInterval,
                    time_zone: "Asia/Singapore",
                    min_doc_count: 1
                  },
                  aggs: {
                    3: {
                      terms: {
                        field: "mediaMimeType",
                        order: {
                          _count: "desc"
                        },
                        size: 100
                      }
                    }
                  } 
                } 
          		},
          		index: "messages.*",
          		query: {
          		    bool: {
          		        filter: content_filters,
          		        must: [],
      		            must_not: [
      		              {
                          match_phrase: {
                            mediaId: ""
                          }
                        },
                        {
                          match_phrase: {
                            mediaMimeType: ""
                          }
                        }
                      ],
      		            should: [], 
          		    }
          		},
            	size: 0 
            }
          })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });  
      res.json().then(body => {
        var datetime = []; 
        var data = {
          "Photo": [],
          "image/jpeg": [],
          "video/mp4": [],
          "WebPage": [],
          "image/jpg": [],
          "audio/ogg": [],
          "image/webp": [],
          "application/x-tgsticker": [],
          "audio/mpeg": [],
          "video/webm": [],
          "image/png": [],
          "text/plain": [],
          "application/pdf": [],
          "Poll": [],
          "video/x-matroska": [],
          "audio/opus": [],
          "video/quicktime": [],
          "audio/m4a": [],
          "application/zip": [],
          "audio/aac": [],
          "audio/mp3": [],
          "audio/mp4": [],
          "application/vnd.ms-powerpoint": [],
          "audio/x-flac": []
        } 
        var keys = Object.keys(data);  
        body.aggregations["2"].buckets.forEach((x) => {   
          datetime.push(formatter(new Date(x.key)));
          x["3"].buckets.forEach((point) => {
            if (keys.includes(point.key)) {
              data[point.key].push(point.doc_count);
            } else {
              // console.log(point.key + " is not displayed.");
            } 
          })   
        });
        setDatetime(datetime);
        setData(data);
        setLoading(false); 
      }); 
    }  
    getContent();
  }, [props.filters, props.selected])   
  
  const handleChangeDate = (event, d) => {
    props.callback(new Date(d.axisValue));
  }
  
  if (loading) {
    return <div className="loader">
      <CircularProgress color="inherit"/>
    </div>     
  }
    
  return (
    <Box className="custom-y-padding-bottom" style={{ height: "35vh", flexGrow: 1}}>
      <LineChart  
        series={[
          { curve: "linear", data: data["Photo"], label: 'Photo', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["video/mp4"], label: 'video/mp4', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["video/webm"], label: 'video/webm', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["video/quicktime"], label: 'video/quicktime', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["video/x-matroska"], label: 'video/x-matroska', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["WebPage"], label: 'Webpage', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["image/jpeg"], label: 'image/jpeg', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["image/jpg"], label: 'image/jpg', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["image/png"], label: 'image/png', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["image/webp"], label: 'image/png', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/ogg"], label: 'audio/ogg', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/mp3"], label: 'audio/mp3', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/mp4"], label: 'audio/mp4', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/mpeg"], label: 'audio/mpeg', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/opus"], label: 'audio/opus', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/m4a"], label: 'audio/m4a', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/aac"], label: 'audio/aac', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["audio/x-flac"], label: 'audio/x-flac', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["Poll"], label: 'Poll', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["application/vnd.ms-powerpoint"], label: 'application/vnd.ms-powerpoint', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["application/pdf"], label: 'application/pdf', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["application/zip"], label: 'application/zip', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["application/x-tgsticker"], label: 'application/x-tgsticker', highlightScope: { highlight, faded }},
          { curve: "linear", data: data["text/plain"], label: 'text/plain', highlightScope: { highlight, faded }},
        ]} 
        onAxisClick={handleChangeDate}
        xAxis={[{ scaleType: 'point', data: datetime, label: "Message Time", labelStyle: { fontSize: "16px", fontWeight: "bold" }}]}
        yAxis={[{ label: "Multimedia Count", labelStyle: { fontSize: "16px", fontWeight: "bold" }}]}   
        margin={{
          left: 60, 
          top: 100, 
        }}
        colors={colors}
      />
    </Box> 
  );
}          