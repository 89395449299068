import React, { useState, useEffect }from 'react';
import KeywordDetailCard from '../components/keywordDetailCard.js';
import ModifyKeywordForm from '../components/modifyKeywordForm.js';
import CircularProgress from '@mui/material/CircularProgress'; 
import { get } from 'aws-amplify/api'; 
import './modifyKeywordPage.css';

const ModifyKeywordPage = (props) => { 
    const path = window.location.pathname;
    const pk = path.split("/")[3];   
    const [data, setData] = useState([]);
    
    useEffect(() => {
      const getKeywords = async () => {
        try {
          const getOperation = get({ 
          apiName: 'gcckeywordmgtapi',
          path: '/gcckeywordmgt' 
        });
          const { body } = await getOperation.response;
          const json = await body.json(); 
          const temp = json.filter(function(x) {
            return (!x["delete"] && x["PK"] === pk); 
          }); 
          setData(temp[0]);  
          // console.log('GET call succeeded: ', json); 
        } catch (error) {
          console.log('GET call failed: ', error);
        }
      }
      getKeywords(); 
    }, [pk]) 
     
    if (data.length === 0) {
        return <div className="loader"><CircularProgress sx={{ marginTop: '3rem' }} color="inherit"/></div>
    }  
    
    return (
        <> 
            <div className='detail-card'>
                <KeywordDetailCard data={data}/>
            </div>
            <div sx={{ marginBottom: "2rem"}}>
              <ModifyKeywordForm user={props.user} data={data}/>
            </div>
        </>
    );
};

export default ModifyKeywordPage;