import AbcIcon from '@mui/icons-material/Abc'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Chip from '@mui/material/Chip'; 
import FaceIcon from '@mui/icons-material/Face';
import FolderIcon from '@mui/icons-material/Folder'; 
import GroupsIcon from '@mui/icons-material/Groups';
import LinkIcon from '@mui/icons-material/Link'; 
import MessageIcon from '@mui/icons-material/Message';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TagIcon from '@mui/icons-material/Tag';
import './tag.css'; 

const Tag = (props) => {  
    let type = props.tag.type;
    let label = props.tag.label.trim();
    let key = props.tag.key;  
    const and_types = ["extractedGroupTaggings", "extractedKeywordCategories_master", "extractedFaceHitWGroup"]; 
    const and_condition = and_types.includes(type);
    
    const handleDeleteTag = (deleted_tag) => {  
        props.callback(deleted_tag);  
    } 
    
    return (
        <Chip sx={{paddingLeft: '2px', borderStyle: and_condition ? 'solid' : 'dashed'}} 
            icon= { 
                    type === 'extractedGroupTaggings' || type === 'extractedKeywordCategories_master' || type === 'extractedFaceHitWGroup' ? <FolderIcon/> :
                    type === 'commsGuid' || type === 'commsName' || type === 'commsUrl' ? <GroupsIcon/> :
                    type === 'senderDisplayName' || type === 'senderSelectorAccountId' || type === 'senderSelectorUsername' || type === 'senderSelectorPhoneNumber' ? <AccountCircleIcon/> :
                    type === 'extractedKeywords_master' ? <AbcIcon /> :
                    type === 'application' ? <SmartphoneIcon/> :
                    type === 'extractedFaceHitWPerson' ? <FaceIcon/> :
                    type === 'extractedHashtags' ? <TagIcon /> :
                    type === 'extractedUrls' || type === 'extractedUrlDomains' ? <LinkIcon /> :
                    type === 'messageUrl' || type === 'messageResponseType' || type === 'messageId' ? <MessageIcon/> : <></>
                } 
            variant="outlined" 
            label={<div>
                <span style={{ color: "#605958", fontWeight: "bold" }}>
                    {
                        type === 'extractedKeywordCategories_master' ? "Keyword Hits: " : 
                        type === 'extractedGroupTaggings' ? 'Group Tagging: ' :   
                        type === 'extractedFaceHitWGroup' ? "Faceprint: " : 
                        type === 'commsGuid' || type === 'senderSelectorAccountId' ? "UID: " : 
                        type === 'commsName' ? "Name: " : 
                        type === 'senderDisplayName' ? "Display Name: " :  
                        type === 'commsUrl' || type === 'messageUrl' ? "URL: " :
                        type === 'senderSelectorUsername' ? "Username: " :  
                        type === 'senderSelectorPhoneNumber' ? "Phone: " :   
                        type === 'messageResponseType' ? "Response Type: " :
                        type === 'extractedUrls' ? "Extracted URL: " : 
                        type === 'extractedUrlDomains' ? "Extracted URL Domain: " :
                        ""
                    } 
                </span>
                <span>
                    {label}
                </span>
            </div>}
            key={key} 
            onDelete={() => handleDeleteTag(props.tag)}  
            // onClick={handleClick}
        /> 
    );
};

export default Tag; 