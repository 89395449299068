import Box from '@mui/material/Box';  
import ReactWordcloud from 'react-wordcloud';
import { v4 as uuid } from "uuid"; 

const colorPalette = [
    "#0076A8", // Saturated Darker Light Steel Blue
    "#A6393D", // Saturated Darker Muted Red
    "#D89B00", // Saturated Darker Light Yellow
    "#4A8E4D", // Saturated Darker Soft Green
    "#8B5D99", // Saturated Darker Lavender
    "#D6A800", // Saturated Darker Soft Gold
    "#D24D4D", // Saturated Darker Light Coral
    "#0095B7", // Saturated Darker Pale Cyan
    "#D76A6A", // Saturated Darker Light Terracotta
    "#007B66", // Saturated Darker Soft Teal
    "#C76A6A", // Saturated Darker Light Blush
    "#0079B8", // Saturated Darker Soft Sky Blue
    "#6D5B92", // Saturated Darker Lavender Gray
    "#A67C7C", // Saturated Darker Dusty Rose
    "#808080"  // Saturated Darker Soft Gray
];

const options = {
  colors: colorPalette,
  // enableTooltip: true,
  deterministic: false,
  fontFamily: "Inter Tight",
  fontSizes: [20, 50],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 0, 
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};  
  
export default function WordCloud(props) { 
  // const getTooltip = (word) => {
  //   return <React.Fragment> <div> {word.value} </div> </React.Fragment>
  // }
  
  const callbacks = {  
    onWordClick: word => props.callback({type: props.type, label: word.text, key: uuid().slice(0, 8)}),
    getWordTooltip: word => `${word.text} (${word.value})`,
  } 
  
  return (
    <Box sx ={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
      <ReactWordcloud options={options} words={props.words} callbacks={callbacks}/>
    </Box>
        
    )
}   