import { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, getGridStringOperators } from '@mui/x-data-grid'; 
import Box from "@mui/material/Box"; 
import Typography from "@mui/material/Typography";
import {format} from 'date-fns'
import fetch from "node-fetch";  
import ClickableTypography from '../clickableTypography.js';
import * as endpoints from '../../../endpoints.js';

const filterOperators = getGridStringOperators().filter(({ value }) => 
  ['equals', 'contains'].includes(value),
); 

const renderDate = (date) => {
 if (!date) {
   return '';
 } 
 return format(new Date(date), 'Pp');
}

function CustomToolbar() {
  return (
    <GridToolbarContainer> 
      <GridToolbarFilterButton /> 
      <Box sx={{ flexGrow: 1 }} /> 
    </GridToolbarContainer>
  );
} 

export default function Top30Forwarders(props) {    
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  //sender_id, name, forward_count, unit_count, last_active  
  const columns = [  
    {
        field: "sender_id",
        headerName: "Sender ID",
        minWidth: 100,
        flex: 1,
        sortable: true,
        filterOperators,
        renderCell: (params) => ( 
          <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1em"}}>
            { params.value !== "NA" ? <ClickableTypography css="typography-single" type="senderSelectorAccountId" value={params.value} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography> }  
          </Box> 
        )
    },
    {
        field: "name",
        headerName: "Display Name",
        minWidth: 100,
        flex: 1,
        sortable: true,
        filterOperators,
        renderCell: (params) => ( 
          <Box sx={{ display: "flex", flexDisplay: "row", alignItems: "center", paddingTop: "1em"}}>
            { params.value !== "NA" ? <ClickableTypography css="typography-single" type="senderDisplayName" value={params.value} callback={props.filterCallback}/> : <Typography className='typography'>-</Typography> }  
          </Box> 
        )
    }, 
    {
        field: "forward_count",
        headerName: "# Forwards",
        minWidth: 50,
        flex: 1,
        type: "number",
        headerAlign: "left",
        align: "left",
        sortable: true, 
        filterable: false,
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "unit_count",
        headerName: "# Groups",
        minWidth: 50,
        flex: 1,
        type: "number",
        headerAlign: "left",
        align: "left",
        sortable: true, 
        filterable: false,
        renderCell: (params) => (
          <div> {params.value} </div>
        )
    },
    {
        field: "last_active",
        headerName: "Last Active",
        minWidth: 50,
        flex: 1,
        sortable: true, 
        type: "date", 
        renderCell: (params) => (
          <div className='typography-single' onClick={() => props.dateCallback(params.value)}> {renderDate(params.value)} </div>
        )
    },   
  ]; 
  
  useEffect(() => {   
    let response_filter = [
      {
        exists: {
            field: "parentMessageId"
        }
      },
      {
          bool: {
            minimum_should_match: 1,
            should: [
              {
                match_phrase: {
                  messageResponseType: "forwarded_from_user"
                }
              },
              {
                match_phrase: {
                  messageResponseType: "forwarded_from_channel"
                }
              },
              {
                match_phrase: {
                  messageResponseType: "forwarded"
                }
              }
            ]
          }
        }
    ]
    let filters = props.filters;
    let selected = props.selected; 
    let selected_commsGuid = [];
    let selected_commsName = [];
    selected.forEach((group) => { 
      selected_commsGuid.push({
        match_phrase: {
          commsGuid: group.commsGuid
        }
      });
      selected_commsName.push({
        match_phrase: {
          commsName: group.commsName
        }
      }) 
    })
    let temp = [];
    if (selected.length > 0) {
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsGuid  
        }
      })
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsName
        }
      })
    }
    const content_filters = filters.concat(temp, response_filter);
    const getContent = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
                  es_query: {
                      _source: [],
                  		aggs: {
                        2: {
                          terms: {
                            field: "senderSelectorAccountId",
                            order: {
                              1: "desc"
                            },
                            size: 30
                          },
                          aggs: {
                            1: {
                              cardinality: {
                                field: "unitId"
                              }
                            },
                            3: {
                              terms: {
                                field: "senderDisplayNameTerm",
                                order: {
                                  1: "desc"
                                },
                                missing: "__missing__",
                                size: 5
                              },
                              aggs: {
                                1: {
                                  cardinality: {
                                    field: "unitId"
                                  }
                                },
                                2: {
                                  cardinality: {
                                    field: "commsGuid"
                                  }
                                },
                                3: {
                                  max: {
                                    field: "messageDatetime"
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                  		index: "messages.*",
                  		query: {
                  		    bool: {
                  		        filter: content_filters,
                  		        must: [],
              		            must_not: [],
              		            should: [], 
                  		    }
                  		},
                  		size: 0 
                  }
              })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });  
      res.json().then(body => { 
        let agg = body?.aggregations;
        let arr = agg["2"] ? agg["2"].buckets : []; 
        let temp_arr = [];
        let id_count = 0;
        arr.forEach((item) => {
          let sender_id = item.key; 
          let metadata = item["3"].buckets["0"];
          id_count++;
          temp_arr.push({
            id: id_count,
            sender_id: sender_id,
            name: metadata.key,
            forward_count: metadata["1"].value, 
            unit_count: metadata["2"].value,
            last_active: new Date(metadata["3"].value) 
          })
        })    
        setRows(temp_arr);
        setLoading(false);
      }); 
  }   
    if (props.expanded) {
      getContent();
    } 
  }, [props.filters, props.selected, props.expanded])    
      
  if (loading) {
    return <div> Loading... </div>
  }   
  
  return (
    <> 
      <div style={{ height: '100%', width: "300"}}>
        <DataGrid   
          autoHeight 
          initialState={{ 
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 30]}
          disableRowSelectionOnClick 
          disableColumnResize 
          disableColumnMenu
          slots={{ toolbar: CustomToolbar }}
          rows={rows} 
          columns={columns}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
               outline: "none !important",
            }, 
            ".MuiButton-root": {
              color: "#605958"
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "rgba(218,218,218,0)", 
            }
          }}
        />
      </div>  
    </>
  );
}  