import './styles.css'; 
import { DateRangePicker } from 'rsuite';
import { useState, useEffect } from 'react';  

export default function DateHeader(props) {  
  let today = new Date(); 
  today.setHours(23, 59, 59, 59);  
  let last_7_days = new Date();
  last_7_days.setDate(today.getDate() - 6); 
  last_7_days.setHours(0, 0, 0, 0); 
  
  /** DateRangePicker from rsuite **/
  const [value, setValue] = useState([last_7_days, today]);  
  const handleChange = (value) => {  
    setValue(value);
    if (value !== null) {
      let gte = value[0];
      let lte = value[1]; 
      props.callback({gte: Date.parse(gte), lte: Date.parse(lte)}); 
    } else { 
      setValue([last_7_days, today]) 
      props.callback({gte: Date.parse(last_7_days), lte: Date.parse(today)}); 
    }
  }
  
  const handleClean = () => {
    setValue([last_7_days, today]) 
  } 
  
  useEffect(() => { 
    if (props.value) {
      const current = props.value;
      var three_hours_later = new Date(props.value);
      three_hours_later.setHours(three_hours_later.getHours() + 3); 
      setValue([current, three_hours_later]); 
      props.callback({gte: Date.parse(current), lte: Date.parse(three_hours_later)});
    }
  }, [props.value]) 
   
  return ( 
    <DateRangePicker value={value} onClean={handleClean} format="dd MMM yyyy hh:mm:ss aa" defaultValue={value} showMeridiem onChange={handleChange}/>
  );
} 