import { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress';   
import { format } from 'date-fns';  
import fetch from "node-fetch"; 
import "./multimediaPropagationTrend.css";
import * as endpoints from '../../../endpoints.js';

const colors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
    '#7fc97f',
    '#beaed4',
    '#fdc086',
    '#ffff99',
    '#386cb0',
    '#f0027f',
    '#bf5b17',
    '#666666',
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
  ]; 

export default function ActivitiesTrend(props) {   
  const [messages, setMessages] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [urls, setUrls] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [senders, setSenders] = useState([]);
  const [multimedias, setMultimedias] = useState([]); 
  const [datetime, setDatetime] = useState([]);
  const [loading, setLoading] = useState(true); 
  
  let highlight = 'series';
  let faded = 'global';
  
  const formatter = (date) => { 
    return format(date, 'yyyy-MM-dd kk:mm');
  }  
  
  useEffect(() => {     
    let filters = props.filters;
    const range = filters.filter(x => x.hasOwnProperty('range'));
    let gte = new Date(range[0].range.messageDatetime.gte);
    let lte = new Date(range[0].range.messageDatetime.lte);
    var hours_diff = Math.abs(lte - gte) / 36e5;
    let fixedInterval = "";
    if (hours_diff < 3 || hours_diff === 3) {
      fixedInterval = "5m"
    } else {
      fixedInterval = "3h"
    } 
    let selected = props.selected; 
    let selected_commsGuid = [];
    let selected_commsName = [];
    selected.forEach((group) => { 
      selected_commsGuid.push({
        match_phrase: {
          commsGuid: group.commsGuid
        }
      });
      selected_commsName.push({
        match_phrase: {
          commsName: group.commsName
        }
      }) 
    })
    let temp = [];
    if (selected.length > 0) {
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsGuid  
        }
      })
      temp.push({
        bool: {
          minimum_should_match: 1,
          should: selected_commsName
        }
      })
    }
    
    const content_filters = filters.concat(temp);
       
    const getContent = async () => {
      const res = await fetch(endpoints.ELASTIC, { 
          headers: {
              authorizationToken: endpoints.AUTHORIZATION_TOKEN
          },
          method: 'POST',
          body: JSON.stringify({
            es_query: {
              _source: [],
              stored_fields: ["*"],
          		aggs: {
                2: {
                  date_histogram: {
                    field: "messageDatetime",
                    fixed_interval: fixedInterval,
                    time_zone: "Asia/Singapore",
                    min_doc_count: 1
                  },
                  aggs: {
                    3: {
                      sum: {
                        field: "numberOfKeywordsHit_master"
                      }
                    },
                    4: {
                      sum: {
                        field: "numberOfHashtags"
                      }
                    },
                    5: {
                      sum: {
                        field: "numberOfUrlLinks"
                      }
                    },
                    6: {
                      sum: {
                        field: "numberOfMentions"
                      }
                    },
                    7: {
                      cardinality: {
                        field: "senderSelectorAccountId"
                      }
                    },
                    8: {
                      cardinality: {
                        field: "mediaId"
                      }
                    }
                  }
                } 
          		},
          		index: "messages.*",
          		query: {
          		    bool: {
          		        filter: content_filters,
          		        must: [],
      		            must_not: [],
      		            should: [], 
          		    }
          		},
            	size: 0 
            }
          })   
      }).catch((err) => {
        console.log('POST call failed: ', err.message);
      });  
      res.json().then(body => {
        var datetime = []; 
        let messages = [];
        let keywords = [];
        let hashtags = [];
        let urls = [];
        let mentions = [];
        let senders = [];
        let multimedias = [];
        body.aggregations["2"].buckets.forEach((x) => { 
          datetime.push(formatter(new Date(x.key)));
          messages.push((x.doc_count));
          keywords.push(x["3"].value);
          hashtags.push(x["4"].value);
          urls.push(x["5"].value);
          mentions.push(x["6"].value);
          senders.push(x["7"].value);
          multimedias.push(x["8"].value); 
        })
        setDatetime(datetime);
        setMessages(messages);
        setKeywords(keywords);
        setHashtags(hashtags);
        setUrls(urls);
        setMentions(mentions);
        setSenders(senders);
        setMultimedias(multimedias);
        setLoading(false);  
      }); 
  }  
    getContent();
  }, [props.filters, props.selected])   
  
  const handleChangeDate = (event, d) => {
    props.callback(new Date(d.axisValue));
  }
  
  if (loading) {
    return <div className="loader">
      <CircularProgress color="inherit"/>
    </div> 
  }  
  
  return (
      <Box className="custom-y-padding-bottom" style={{ height: "35vh", flexGrow: 1}}>  
          <LineChart  
            series={[
              { curve: "linear", data: messages, label: '# Messages', highlightScope: { highlight, faded }},
              { curve: "linear", data: keywords, label: '# Keywords', highlightScope: { highlight, faded }},
              { curve: "linear", data: hashtags, label: '# Hashtags', highlightScope: { highlight, faded }},
              { curve: "linear", data: urls, label: '# URLs', highlightScope: { highlight, faded }},
              { curve: "linear", data: mentions, label: '# Mentions', highlightScope: { highlight, faded }},
              { curve: "linear", data: senders, label: '# Senders', highlightScope: { highlight, faded }},
              { curve: "linear", data: multimedias, label: '# Unique Multimedia', highlightScope: { highlight, faded }}
            ]} 
            onAxisClick={handleChangeDate}
            xAxis={[{ scaleType: 'point', data: datetime, label: "Message Time", labelStyle: { fontSize: "16px", fontWeight: "bold" }}]}
            yAxis={[{ label: "Message Count", labelStyle: { fontSize: "16px", fontWeight: "bold" }}]} 
            margin={{
              left: 60, 
              top: 80, 
            }}
            colors={colors} 
          />
      </Box> 
  );
} 