import * as React from 'react';
import { useNavigate  } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar'; 
import Grid from '@mui/material/Grid';
// import CaseMenu from './caseMenu.js';
// import SettingMenu from './settingMenu.js';
// import KeywordMenu from './keywordMenu.js';
// import GroupTagMenu from './groupTagMenu.js';
// import TaskingMenu from './taskingMenu.js';
// import FaceprintMenu from './faceprintMenu.js';
// import ConfigurationMenu from './configurationMenu.js';
import ConfigureMenu from './configureMenu.js'; 
import CasesMenu from './casesMenu.js';
import DiscoveryMenu from './discoveryMenu.js';
import SignOutMenu from './signOutMenu.js';
import {ReactComponent as ReactLogo} from './hunnycloud.svg'; 
import './navigationBar.css';

export default function NavigationBar(props) {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="static" sx={{backgroundColor: '#605958'}}>
        <Toolbar>
            <Grid container className='position'>
                <Grid item className='menu-header'>   
                    <ReactLogo onClick={() => navigate('/home')} className='logo'/>   
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '40px'}}>
                        <ConfigureMenu className='menu'/>   
                      </Box> 
                </Grid> 
                <Grid item>
                  <SignOutMenu className='menu' signOut={props.signOut}/> 
                </Grid>
            </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
// <CaseMenu className='menu'/>  
//                         <DiscoveryMenu className='menu'/>   
                        // <CasesMenu className='menu'/>