import './App.css';
import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './aws-exports.js'; 
import { get } from 'aws-amplify/api'; 
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import AddAdhocTaskingPage from './social-media-tasking/pages/addAdhocTaskingPage.js';
import AddFaceprintPage from './faceprint-mgt/pages/addFaceprintPage.js';
import AddGroupTagPage from './group-tagging/pages/addGroupTagPage.js';
import AddKeywordPage from './keyword-mgt/pages/addKeywordPage.js';
import AddTaskingPage from './social-media-tasking/pages/addTaskingPage.js';
import DashboardPage from './dashboard/pages/dashboardPage.js';
import DownloadPage from './file-transfer-host/pages/downloadPage.js';
import HomePage from './home/homePage.js';
import ModifyFaceprintPage from './faceprint-mgt/pages/modifyFaceprintPage.js';
import ModifyGroupTagPage from './group-tagging/pages/modifyGroupTagPage.js';
import ModifyKeywordPage from './keyword-mgt/pages/modifyKeywordPage.js';
import ModifyTaskingPage from './social-media-tasking/pages/modifyTaskingPage.js';
import ManageTaskingPage from './social-media-tasking/pages/manageTaskingPage.js';
import NavigationBar from './shared/navigationBar.js';
import ViewAdhocTaskingPage from './social-media-tasking/pages/viewAdhocTaskingPage.js';
import ViewAllAdhocTaskingPage from './social-media-tasking/pages/viewAllAdhocTaskingPage.js';
import ViewAllFaceprintPage from './faceprint-mgt/pages/viewAllFaceprintPage.js';
import ViewAllGroupTagPage from './group-tagging/pages/viewAllGroupTagPage.js';
import ViewAllKeywordPage from './keyword-mgt/pages/viewAllKeywordPage.js';
import ViewAllTaskingPage from './social-media-tasking/pages/viewAllTaskingPage.js';
import ViewTaskingPage from './social-media-tasking/pages/viewTaskingPage.js';
import styled from 'styled-components'; 
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('0c26f9199b0974af9c69c4c6a9cd04ccTz05ODcyMixFPTE3NTg3Nzk5NzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');
let theme = responsiveFontSizes(createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Inter Tight',
      textTransform: 'none', 
    },
  }, 
}));
Amplify.configure(config);

const Box = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

function App({user, signOut}) {
    
  useEffect(() => {  
    const getTeam = async () => {
      try {
        const getOperation = get({ 
        apiName: 'gccusermapapi',
        path: '/gccusermap' 
        });
        const { body } = await getOperation.response;
        const json = await body.json(); 
        const username = user.username; 
        let team = ""; // Only to check if user-to-team mapping exists for current user
        json.forEach((obj) => { 
          if (obj.username === username) { 
            team = obj.team;   
            user.team = team;
            return;
          }});
        if (team.length === 0) { 
          console.log("User-to-team mapping for this user does not exist.");
        } 
        // console.log('GET call succeeded: ', json); 
      } catch (error) {
        console.log('GET call failed: ', error);
      }
    } 
    getTeam();    
  }, [user]);
  
  return (
      <ThemeProvider theme={theme}> 
        <Box className="App">  
          <BrowserRouter>  
              <NavigationBar signOut={signOut}/>
              <Routes>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route index path="/home" element={<HomePage user={user}/>} /> 
                <Route exact path="/keyword-mgt/add" element={<AddKeywordPage user={user}/>} />
                <Route exact path="/keyword-mgt/view" element={<ViewAllKeywordPage user={user}/>} /> 
                <Route exact path="/keyword-mgt/view/:pk" element={<ModifyKeywordPage user={user}/>} /> 
                <Route exact path="/id-tagging/add" element={<AddGroupTagPage user={user}/>} />
                <Route exact path="/id-tagging/view" element={<ViewAllGroupTagPage user={user}/>} /> 
                <Route exact path="/id-tagging/view/:pk" element={<ModifyGroupTagPage user={user}/>} />
                <Route exact path="/faceprint-mgt/add" element={<AddFaceprintPage user={user}/>} /> 
                <Route exact path="/faceprint-mgt/view" element={<ViewAllFaceprintPage user={user}/>} />
                <Route exact path="/faceprint-mgt/view/:pk" element={<ModifyFaceprintPage user={user}/>} />
                <Route exact path="/social-media-tasking/add" element={<AddTaskingPage user={user}/>} /> 
                <Route exact path="/social-media-tasking/manage-team" element={<ManageTaskingPage user={user}/>} />
                <Route exact path="/social-media-tasking/manage-team/:pk" element={<ModifyTaskingPage user={user}/>} />
                <Route exact path="/social-media-tasking/view" element={<ViewAllTaskingPage user={user}/>} /> 
                <Route exact path="/social-media-tasking/view/:pk" element={<ViewTaskingPage user={user}/>} />
                <Route exact path="/social-media-urgent-tasking/add" element={<AddAdhocTaskingPage user={user}/>} /> 
                <Route exact path="/social-media-urgent-tasking/view" element={<ViewAllAdhocTaskingPage user={user}/>} /> 
                <Route exact path="/social-media-urgent-tasking/view/:pk" element={<ViewAdhocTaskingPage user={user}/>} />
                <Route path="/media-download" element={<DownloadPage/>} /> 
                <Route exact path="/dashboard" element={<DashboardPage user={user}/>} />
              </Routes> 
          </BrowserRouter>
        </Box>
      </ThemeProvider>
  );
}

export default withAuthenticator(App, { hideSignUp: true }); 
