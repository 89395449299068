import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'aws-amplify/api'; 
import { Link } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types'; 
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

const columns = [
    {   id: 'pk', 
        label: 'Ref. #', 
        align: 'left', 
        sortable: true,
    }, 
    {   id: 'tag', 
        label: 'Folder Tag', 
        align: 'left', 
        sortable: true,
    },
    {   id: 'platform', 
        label: 'Platform', 
        align: 'left', 
        sortable: true,
    },
    {   id: 'group_ids', 
        label: 'IDs', 
        align: 'left', 
        sortable: false,
    },
    {   id: 'creator', 
        label: 'Creator', 
        align: 'left', 
        sortable: true,
    }, 
    {   id: 'enable', 
        label: 'Enabled', 
        align: 'left', 
        sortable: true,
    },
];

function createData(pk, tag, platform, group_ids, creator, enable) {
    return { pk, tag, platform, group_ids, creator, enable };
}  
 
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow> 
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sx={{ fontSize: '16px'}} 
            align={column.align}
            padding='normal'
            sortDirection={orderBy === column.id ? order : false}
          > 
          {column.sortable ? 
            <TableSortLabel 
              active={orderBy === column.id} 
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)} 
            >
              <div className='table-cell'>
                {column.label}
              </div>
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> :  
            <div className='table-cell'>
              {column.label}
            </div>
          } 
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = { 
  onRequestSort: PropTypes.func.isRequired, 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};  

export default function GroupTagTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [searchQuery, setSearchQuery] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('tag');
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
  
    useEffect(() => {
      const getGroupTags = async () => {
        try {
          const getOperation = get({ 
          apiName: 'gccgrouptagapi',
          path: '/gccgrouptag' 
        });
          const { body } = await getOperation.response;
          const json = await body.json();
          const temp = [];
          json.forEach(x => x.delete ? console.log('') : temp.push(createData(x.PK, x.tag, x.platform, x.group_ids.toString(), x.creator, x.enable))); 
          setRows(temp); 
          setData(temp);
          // console.log('GET call succeeded: ', json);
          setLoading(false);
        } catch (error) {
          console.log('GET call failed: ', error);
        }
      }
      getGroupTags();  
    }, []) 
    
    useEffect(() => {  
      var temp = [];
      if (searchQuery === "") {
        temp = data;
      } else { 
        temp = data.filter((obj) => JSON.stringify(obj).toLowerCase().includes(searchQuery.toLowerCase()));
      } 
      setRows(temp); 
    }, [searchQuery, data]);
    
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    
    const visibleRows = useMemo(() =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [rows, order, orderBy, page, rowsPerPage],
    );
    
    if (loading) {
      return <div className="loader"><CircularProgress color="inherit"/></div>
    } 
    
    return ( 
      <>
        <div className='search-bar-position'>
          <Box sx={{width: "30rem"}}> 
            <TextField 
              fullWidth 
              size="small"  
              className='search-bar'
              placeholder="Search..."
              autoComplete='off'
              onInput={(e) => { setSearchQuery(e.target.value); }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button className='form-button' variant="contained" component={Link} to="/id-tagging/add" startIcon={<AddIcon />}>
              <span className='button-text'>
                ADD ENTRY
              </span>  
          </Button>
        </div>
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 'auto' }}>
            <Table sx={{ tableLayout: 'fixed'}} stickyHeader aria-label="sticky table"> 
              <colgroup>
                  <col width="10%" />
                  <col width="13%" />
                  <col width="10%" />
                  <col width="47%" />
                  <col width="10%" /> 
                  <col width="10%" />
              </colgroup>
              <EnhancedTableHead 
                order={order}
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              /> 
              <TableBody>
                {visibleRows 
                  .map((row, i) => {
                    return (
                      <Tooltip 
                        placement="bottom"
                        key={i}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}
                        title="Click to view">
                      <TableRow className='table-cell' hover component={Link} to={`/id-tagging/view/${row.pk}/`} role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          const value = row[column.id].toString();
                          return (
                            <TableCell sx={{ fontSize: '14px'}} key={column.id} align={column.align}> 
                              <div className='table-cell'>
                               {value}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow> 
                      </Tooltip>
                    );
                  })}
              </TableBody> 
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {(searchQuery !== "") && 
        <div className='search-results'>
          {rows.length} record(s) found.
        </div>}
      </>
  );
} 