import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import GenerateDisplay from './generateDisplay.js'; 
import Tab from '@mui/material/Tab';   
import TabContext from '@mui/lab/TabContext';   
import Tabs from '@mui/material/Tabs'; 

const GenerateColumn = (props) => {    
    const [value, setValue] = useState('');  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
 
    return (
        <Box sx={{ height: '100%', marginLeft: '1rem', marginTop: '1rem' }}>    
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="inherit"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#FFB100", 
                      }
                    }} 
                >
                    <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="one" label="Content" disabled />
                    <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="two" label="Information" disabled />
                    <Tab sx={{ "&.Mui-selected": { color: "#FFB100" }}} value="three" label="Analytics" disabled /> 
                </Tabs> 
                <Box sx={{ padding: "0 1rem 1rem 0" }} >
                    <GenerateDisplay selected={props.selected} callback={props.callback} deselect={props.deselect}/>
                </Box> 
            </TabContext>  
        </Box>
    );
};

export default GenerateColumn; 