import React, { useState, useEffect } from 'react';
// import { StorageImage, StorageManager } from '@aws-amplify/ui-react-storage'; 
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuItem from '@mui/material/MenuItem';  
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import FileUpload from "react-mui-fileuploader"
import { put } from 'aws-amplify/api';  
import { uploadData } from 'aws-amplify/storage'; 
import { v4 as uuid } from "uuid";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import '@aws-amplify/ui-react/styles.css'; 
import './addFaceprintForm.css';
// import fetch from 'node-fetch'; 
import { get } from 'aws-amplify/api';  
import * as endpoints from '../../endpoints.js';
 
const ACCESS_LEVEL = "public"; // Note: If protected, then users can only view their OWN submissions.
const PATH = "faceprint/";
const ERROR_MESSAGE = "Name, categories and/or faceprint fields are empty!";
const SUCCESS_MESSAGE = "Successfully submitted entry."; 
const fetch = require("node-fetch");

export default function AddFaceprintForm(props) { 
    const [name, setName] = useState('');
    const [categories, setCategories] = useState([]);
    const [existingCategories, setExistingCategories] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);
    const [status, setStatus] = useState(null); 
    const [filesToUpload, setFilesToUpload] = useState([]); 
    const [restricted, setRestricted] = useState('everyone');
     
    useEffect(() => {
        const getExistingCategories = async () => {
            try {
                const getOperation = get({
                    apiName: 'gccfaceprintapi',
                    path: '/gccfaceprint'
                });
                const { body } = await getOperation.response; 
                const json = await body.json();
                // const temp = new Set();
                let temp = [];
                json.forEach(x => x.delete ? console.log('') : (x.access !== 'everyone' && x.creator_team !== props.user.team) ? console.log('') : temp = [...temp, ...x.categories]); 
                let existing = [...new Set(temp)]
                // console.log(existing);
                setExistingCategories(existing); 
                // console.log('GET call succeeded: ', json);
            } catch (error) {
                console.log('GET call failed: ', error);
            }    
        }    
        getExistingCategories();
    }, [props.user.team]) 
    
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
    }
    
    const addFaceprint = async (pk, paths) => {  
        var s3_hash_map = {};
        var s3_status_map = {};
        paths.forEach((path) => { 
            s3_hash_map[path] = ""; 
            s3_status_map[path] = "Pending";
        }); 
        await fetch(endpoints.FACEPRINT, { 
            headers: {
                authorizationToken: endpoints.AUTHORIZATION_TOKEN
            },
            method: 'POST',
            body: JSON.stringify({
               record_id: pk 
            })}) 
            .catch((err) => {
               console.log('POST call failed: ', err.message);
        });    
        try {
            const putOperation = put({
                apiName: 'gccfaceprintapi',
                path: '/gccfaceprint',
                options: {
                    body: {
                        PK: pk,
                        name: name,
                        categories: categories, 
                        s3_hash_map: s3_hash_map,   
                        s3_status_map: s3_status_map,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        hit_type: 'faceprint',
                        delete: false,
                        enable: true, 
                        status: 'pending',
                        creator_team: props.user.team,
                        restricted: restricted
                        
                    }
                }
            })
            // const response = await putOperation.response;
            await putOperation.response;
            // console.log('PUT call succeeded: ', response); 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    } 
   
    const handleReset = () => {
        setCategories([]);
        setName('');
        setStatus(null);
        setOpenAlert(false);
    }      
     
    const handleFilesChange = (files) => {  
        setFilesToUpload([ ...files ])  
    }; 
    
    const uploadToS3 = async (file, path) => {  
        try {
            await uploadData({
                key: path,
                data: file,
                options: {
                    accessLevel: ACCESS_LEVEL
                }
            }).result; 
            // console.log('Succeeded: ', result);
        } catch (error) {
            console.log('Error : ', error); 
        } 
    }  
    
    const handleConfirm = () => { 
        if (name.trim() === '' || categories.length === 0 || filesToUpload.length === 0) { 
            setStatus(false); 
            setOpenAlert(true);
        } else { 
            const pk = uuid().slice(0, 12);
            let paths = [];
            filesToUpload.forEach((file) => { 
                const file_pk = uuid().slice(0, 10); 
                paths.push(PATH + file_pk + '_' + file.name); 
            });  
            addFaceprint(pk, paths); // Update DynamoDB table
            for (let i = 0; i < filesToUpload.length; i++) {
                const file = filesToUpload[i];
                const path = paths[i];
                // console.log(path);
                uploadToS3(file, path);
            }
            setStatus(true);
            setOpenAlert(true);
        } 
        setOpenDialog(false);    
    }
    
    const theme = createTheme({
        typography: {
            allVariants: {
              fontFamily: 'Inter Tight',
              textTransform: 'none', 
              fontSize: '18px'
            },
          },
      components: { 
        MuiButton: {
          styleOverrides: { 
            root: { 
              backgroundColor: '#605958', 
              color: 'white',
              fontSize: '14px',
              fontWeight: '600',
              padding: '6px 16px',  
              "&:hover": {
                  backgroundColor: 'rgb(96,89,88,0.8)', 
              }
            },            
          },
        },
      },
    });

    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    return (
        <Box className='table-position'> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to submit record?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment> 
            <Paper elevation={0} sx={{minHeight: "10vh"}}className='table'>  
                <div className='table-header'>
                    Name
                </div> 
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                    <FormControl fullWidth> 
                        <TextField 
                            value={name}
                            id="outlined-basic" 
                            size="small" 
                            variant="outlined"  
                            onChange={(event) => setName(event.target.value)}
                            sx={{ fontSize: '8px'}}
                            label="Insert name of target"
                        />     
                    </FormControl>
                </Box>  
                <div className='table-header'>
                    Categories
                </div> 
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>    
                    <Autocomplete
                        value={categories}
                        multiple
                        clearIcon={false}
                        size="small" 
                        options={existingCategories} 
                        freeSolo
                        onChange={(event, value) => setCategories(value)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                            <TextField size="small" {...params} sx={{ fontSize: '8px'}} label="Press 'Enter' to add category"/>
                        )}
                    /> 
                </Box>   
                <div className='table-header'>
                    Restrict Access 
                    <Tooltip title="Restrict access to view/edit this entry. Only the creator's team can manage this.">
                        <HelpOutlineIcon sx={{fontSize: 'small'}}/> 
                    </Tooltip> 
                </div>  
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}> 
                    <FormControl sx={{ minWidth: 120 }}> 
                        <Select  
                            value={restricted} 
                            onChange={(event) => setRestricted(event.target.value)}
                            size="small"
                            displayEmpty 
                        >
                            <MenuItem value={"everyone"}>Everyone can view and edit</MenuItem>
                            <MenuItem value={"group"}>Only my team can view and edit</MenuItem> 
                        </Select>
                    </FormControl>
                </Box>  
                <div className='table-header'>
                        Faceprint
                </div>
                <Box sx={{padding: '0% 1% 3% 1%'}}> 
                    <ThemeProvider theme={theme}>
                        <FileUpload
                          getBase64={false}
                          multiFile={true}
                          disabled={false}
                          title=""
                          header="Drag to drop here or"
                          leftLabel=""
                          rightLabel=""
                          buttonLabel="Browse Files"
                          buttonRemoveLabel="Clear All"  
                          maxFileSize={10}
                          maxUploadFiles={0}
                          maxFilesContainerHeight={357}
                          acceptedType={'image/*'}
                          errorSizeMessage={'fill it or remove it to use the default error message'}
                          allowedExtensions={['jpg', 'jpeg', 'png', 'gif']}
                          onFilesChange={handleFilesChange} 
                        //   imageSrc={'../../public_cloud.png'}
                          BannerProps={{ elevation: 0,   sx: { backgroundColor: "rgb(0,0,0,0)", padding: '3%', border: '1px dashed', borderColor: "rgb(0,0,0,0.3)", }}}
                          ContainerProps={{ elevation: 0, sx: { backgroundColor: "rgb(255,255,255,0)", border: 'none'}}}
                          showPlaceholderImage={false}
                          PlaceholderImageDimension={{
                            xs: { width: 128, height: 128 },
                            sm: { width: 128, height: 128 },
                            md: { width: 164, height: 164 },
                            lg: { width: 256, height: 256 }
                          }}
                          PlaceholderGridProps={{ md: 4 }}
                          LabelsGridProps={{ md: 8 }}
                        />
                    </ThemeProvider>
                </Box> 
                <Box className='button-box-position'>
                    <div className='button-position'>
                        <Button className='form-button' onClick={handleOpen} variant="contained">
                            <span className='button-text'>
                                SUBMIT
                            </span> 
                        </Button>
                        <Button className='form-button' onClick={handleReset} variant="contained">
                            <span className='button-text'>
                                RESET
                            </span>  
                        </Button>
                    </div>
                </Box> 
            </Paper>     
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>
        </Box>    
    );
};   